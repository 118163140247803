import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { DeselectionService } from '../../services/deselection.service';
import { Deselection } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class DeselectionListResolverService {

    constructor(
        private deselectionService: DeselectionService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        const deselections: Deselection[] = [];
        const period = route.paramMap.get('period');
        return new Promise<Deselection[]>((resolve, reject) => {
            this.deselectionService.list(period).subscribe(
                deselection => deselections.push(deselection),
                error => reject(error),
                () => resolve(deselections)
            );
        });
    }
}
