import { NgModule } from '@angular/core';
import { HotkeyModule } from 'angular2-hotkeys';

import { SharedModule } from '@app/shared';
import { PeriodListComponent } from './period-list/period-list.component';
import { PeriodComponent } from './period/period.component';
import { SchedulerRoutingModule } from './scheduler-routing.module';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { SelectionBarComponent } from './selection-bar/selection-bar.component';
import { SchedulingTableComponent } from './scheduling-table/scheduling-table.component';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { UserSelectionComponent } from './user-selection/user-selection.component';
import { ScheduleCreateComponent } from './schedule-create/schedule-create.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { PublishComponent } from './publish/publish.component';
import { ScheduleStatsComponent } from './schedule-stats/schedule-stats.component';


@NgModule({
    imports: [
        HotkeyModule,
        SharedModule,
        SchedulerRoutingModule
    ],
    declarations: [
        PeriodListComponent,
        PeriodComponent,
        SchedulerComponent,
        SelectionBarComponent,
        SchedulingTableComponent,
        ScheduleListComponent,
        UserSelectionComponent,
        ScheduleCreateComponent,
        UserInfoComponent,
        UserSearchComponent,
        PublishComponent,
        ScheduleStatsComponent
    ]
})
export class SchedulerModule { }
