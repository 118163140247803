import { DateHelper } from './date-helper';
import { Schedule, Shift, Shifttype } from '../models';
import { DatePick } from '../interfaces';


export class ShiftHelper {
    static generateMonths(months: DatePick[], schedule: Schedule, shifttypes: Shifttype[]) {
        let map = new Map<string, Shift[]>();
        months.forEach(month => {
            const key = DateHelper.getDatePickKey(month);
            const monthMap = ShiftHelper.generateMonth(month, schedule, shifttypes);
            const merge = [
                ...Array.from(map.entries()),
                ...Array.from(monthMap.entries())
            ];
            map = new Map<string, Shift[]>(merge);
        });
        return map;
    }

    private static generateMonth(month: DatePick, schedule: Schedule, shifttypes: Shifttype[]) {
        const map = new Map<string, Shift[]>();
        const daysInMonth = DateHelper.getDaysInMonth(month);
        for (let i = 0; i < daysInMonth; i++) {
            const date = new Date(month.year, month.month, i + 1);
            const dateString = DateHelper.getDateString(date);
            const shifts = ShiftHelper.generateDay(date, dateString, schedule, shifttypes);
            if (shifts) {
                map.set(dateString, shifts);
            }
        }
        return map;
    }

    private static generateDay(date: Date, dateString: string, schedule: Schedule, shifttypes: Shifttype[]) {
        const shifts: Shift[] = [];
        let hasShift = false;
        shifttypes.forEach(shifttype => {
            if (DateHelper.includeDay(date, shifttype.daytype)) {
                const shift = new Shift();
                shift.schedule = schedule.id;
                shift.shifttype = shifttype.id;
                shift.date = dateString;
                shifts.push(shift);
                hasShift = true;
            } else {
                shifts.push(null);
            }
        });
        return hasShift ? shifts : null;
    }
}
