import {
    AfterViewChecked,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { formatDate } from '@angular/common';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

import { ShiftService, ShiftHistoryService } from '@app/core';
import {
    DateHelper,
    Deselection,
    HistoryHandler,
    Schedule,
    SchedulingGroup,
    Shift,
    ShiftHistory,
    Shifttype,
    UIState,
    UserHandler,
    UserState
} from '@app/shared';


@Component({
    selector: 'app-scheduling-table',
    templateUrl: './scheduling-table.component.html',
    styleUrls: ['./scheduling-table.component.css']
})
export class SchedulingTableComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

    @Input() set historyEnabled(value: boolean) {
        this.history.enabled = value;
    }

    @Input() cursorMode: 'add' | 'lock' | 'remove';
    @Input() dates: Map<string, Shift[]>;
    @Input() groups: SchedulingGroup[];
    @Input() holidays: string[];
    @Input() isLoading = false;
    @Input() imposedEnabled = false;
    @Input() mandatoryEnabled = false;
    @Input() voluntaryEnabled = false;
    @Input() schedule: Schedule;
    @Input() shifttypes: Shifttype[];
    @Input() user: UserState;
    @Input() users: UserHandler;
    @Input() lockHeader = true;
    @Input() lockOffset = 0;
    @Input() lockDistance = 0;
    @ViewChild('body') body: ElementRef;
    @ViewChild('header') header: ElementRef;
    history: HistoryHandler;
    private _days: UIState<string>[] = [];
    private _dateElements: ElementRef[];
    private _deselections: Map<string, Deselection>;
    private _shortcuts: (Hotkey | Hotkey[])[] = [];
    private _states: Map<string, UIState<Shift>[]>;
    private _tableUpdated = false;
    private _initialPageXOffset = window.pageXOffset;
    private _lastPageXOffset = 0;
    private _shifttypeCss: Map<number, string[]>;
    private _columnSplits: Map<number, boolean>;
    private _formattedDates = new Map<string, string>();

    constructor(
        private shiftService: ShiftService,
        private hotkeysService: HotkeysService,
        private renderer: Renderer2,
        shiftHistoryService: ShiftHistoryService
    ) {
        this.history = new HistoryHandler(shiftHistoryService, false, false, true);
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.destroyShortcuts();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dates) {
            this.buildDays();
            this._states = this.buildState();
        }

        if (changes.shifttypes && changes.shifttypes.currentValue) {
            this._columnSplits = this.getColumnSplits();
            this._shifttypeCss = this.getShifttypeStates();
            this._states = this.buildState();
        }

        if (changes.user) {
            this._deselections = this.prepareDeselections();
            this.updatePossibleConflicts();
            this.updateStates();
        }

        if (changes.lockHeader) {
            this.updateDateElementsLeft();
        }
    }

    ngAfterViewChecked() {
        this.updateTable();
        if (this._tableUpdated) {
            this._tableUpdated = false;
        }
    }

    get days() {
        return this._days;
    }

    get hasGroups() {
        return this.groups && this.groups.length;
    }

    get isReady() {
        return !this.isLoading && this._days.length;
    }

    shifts(date: string) {
        return this._states.get(date);
    }

    formatDate(date: string) {
        if (!this._formattedDates.has(date)) {
            let str = formatDate(date, 'EEEEEE d/M', 'da');
            const week = DateHelper.getWeek(date);
            str += ' (' + week + ')';
            this._formattedDates.set(date, str);
        }
        return this._formattedDates.get(date);
    }

    shifttypeTime(shifttype: Shifttype) {
        return [this.getTimeHours(shifttype.start),
            this.getTimeHours(shifttype.end)].join('-');
    }

    shifttypeComment(shifttype: Shifttype) {
        // Tooltip only added to dom if filled out
        if (!!shifttype.comment.length) {
            return shifttype.comment;
        }
        return null;
    }

    shifttypeClasses(shifttype: Shifttype) {
        if (this._shifttypeCss.has(shifttype.id)) {
            return this._shifttypeCss.get(shifttype.id);
        }
        return null;
    }

    setShift(state: UIState<Shift>) {
        // TODO: Could unwrap shift from uistate  and save a bunch of lines
        // in the following code and called methods
        if (!this.canSetShift(state)) {
            return;
        }
        if (this.cursorMode === 'add') {
            this.updateShift(state);
        } else if (this.cursorMode === 'remove') {
            this.clearShift(state);
        } else if (this.cursorMode === 'lock') {
            this.lockShift(state);
        }
        if (this.user) {
            // const shiftIds = this.user.shifts.map(s => s.id);
            // if (!shiftIds.includes(state.data.id)) {
            //     shiftIds.push(state.data.id);
            // }
            // this.updateFilteredStates(shiftIds);

            // So this is "a lot" slower than above, but we're speaking 3ms
            // Above took 0.12ms while testing
            // But since possible conflicts doesn't know shifts it
            // requires a bigger rewrite of this module
            this.updatePossibleConflicts();
            this.updateStates();
        } else {
            this.updateFilteredStates([state.data.id]);
        }
    }

    setShifttype(shifttype: Shifttype) {
        if (!this.canSetShifttype(shifttype)) {
            return;
        }
        if (this.cursorMode === 'lock') {
            this.lockShifttype(shifttype);
        }
        const shiftIds = this.getShiftIdsByShifttype(shifttype);
        this.updateFilteredStates(shiftIds);
    }

    private canSetShift(state: UIState<Shift>) {
        return this.canAddShift(state) ||
            this.canLockShift(state) ||
            this.canRemoveShift(state);
    }

    private canSetShifttype(shifttype: Shifttype) {
        return this.canLockShifttype(shifttype);
    }

    private canAddShift(state: UIState<Shift>) {
        // Can only update blank shifts
        return this.cursorMode === 'add' && this.user &&
            state.data && state.data.user === null;
    }

    private canLockShift(state: UIState<Shift>) {
        return this.cursorMode === 'lock' && state.data;
    }

    private canLockShifttype(shifttype: Shifttype) {
        return this.cursorMode === 'lock' && shifttype;
    }

    private canRemoveShift(state: UIState<Shift>) {
        // Can only remove shifts for current user
        return this.cursorMode === 'remove' && state.data &&
            ((state.data.is_locked) ||
            (this.user && state.data.user === this.user.data.id));
    }

    private updateShift(state: UIState<Shift>) {
        const shift = state.data;
        const removeFrom = shift.user;
        shift.user = this.user.data.id;
        shift.employee_no = this.user.data.profile.employee_no;
        shift.is_locked = false;
        shift.is_for_sale = false;
        shift.is_bonus = false;
        shift.is_temporary = false;
        shift.is_imposed = this.imposedEnabled;
        shift.is_mandatory = this.mandatoryEnabled;
        shift.is_voluntary = this.voluntaryEnabled;
        this.history.clear(shift);
        this.save(shift, removeFrom);
    }

    private clearShift(state: UIState<Shift>) {
        const shift = state.data;
        const removeFrom = shift.user;
        shift.user = null;
        shift.employee_no = null;
        shift.is_locked = false;
        shift.is_for_sale = false;
        shift.is_bonus = false;
        shift.is_imposed = false;
        shift.is_mandatory = false;
        shift.is_temporary = false;
        shift.is_voluntary = false;
        this.save(shift, removeFrom);
    }

    private lockShift(state: UIState<Shift>) {
        const shift = state.data;
        const removeFrom = shift.user;
        // TODO: Move to service method
        shift.user = null;
        shift.employee_no = null;
        shift.is_locked = true;
        shift.is_for_sale = false;
        shift.is_bonus = false;
        shift.is_imposed = false;
        shift.is_mandatory = false;
        shift.is_temporary = false;
        shift.is_voluntary = false;
        this.save(shift, removeFrom);
    }

    private lockShifttype(shifttype: Shifttype) {
        const shifts = this.getShiftsByShifttype(shifttype);
        shifts.forEach(s => {
            s.user = null;
            s.employee_no = null;
            s.is_locked = true;
            s.is_for_sale = false;
            s.is_bonus = false;
            s.is_imposed = false;
            s.is_mandatory = false;
            s.is_temporary = false;
            s.is_voluntary = false;
        });
        this.shiftService.lockShifttype(this.schedule, shifttype).subscribe(
            result => { }
        );
    }

    private save(shift: Shift, removeFrom: number) {
        if (removeFrom !== null) {
            this.users.removeShift(removeFrom, shift);
        }
        if (shift.user) {
            this.user.addShift(shift);
        }
        this.shiftService.update(shift).subscribe(
            result => { },
            error => {
                // Roll back shift in interface
                this.user.removeShift(shift);
                shift.user = removeFrom;
                this.users.addShift(removeFrom, shift);
                // TODO: Needs to update interface and states too
            }
        );
    }

    private buildDays() {
        if (this.dates) {
            this._days = Array.from(this.dates.keys())
                .map(day => new UIState<string>(day, this.dayCssState(day)));
        } else {
            this._days = [];
        }
        this.setupShortcuts();
        // Used for checking height of header, but only needed when there is content
        this._tableUpdated = this._days.length > 0;
    }

    private dayCssState(date: string) {
        const css: string[] = [];
        if (this.holidays.includes(date)) {
            css.push('has-text-danger');
        }
        if (DateHelper.isWeekend(DateHelper.getDateFromString(date))) {
            css.push('has-background-weekend');
        }
        return css;
    }

    private buildState() {
        const map = new Map<string, UIState<Shift>[]>();
        if (this.dates) {
            const keys = this.dates.keys();
            let result = keys.next();
            while (!result.done) {
                map.set(result.value, this.stateArray(this.dates.get(result.value)));
                result = keys.next();
            }
        }
        return map;
    }

    private stateArray(shifts: Shift[]) {
        return shifts.map((shift, i) => new UIState<Shift>(shift, this.cssState(shift, i)));
    }

    private updateStates() {
        const values = this._states.values();
        let result = values.next();
        while (!result.done) {
            result.value.forEach(
                (state, i) => state.update(this.cssState(state.data, i)));
            result = values.next();
        }
    }

    private updateFilteredStates(shiftIds: number[]) {
        // TODO: If a map of all shifts existed this could use
        // that instead of looping. But need to check memory consumption
        const values = this._states.values();
        let result = values.next();
        while (!result.done) {
            result.value.forEach((state, i) => {
                if (state.data && shiftIds.includes(state.data.id)) {
                    state.update(this.cssState(state.data, i));
                }
            });
            result = values.next();
        }
    }

    private updatePossibleConflicts() {
        if (this.user) {
            this.user.updatePossibleConflicts();
        }
    }

    private prepareDeselections() {
        // Map deselections for easy lookup
        const map = new Map<string, Deselection>();
        if (this.user) {
            this.user.data.deselections.forEach(
                deselection => map.set(deselection.date, deselection));
        }
        return map;
    }

    private cssState(shift: Shift, columnIndex: number) {
        const css: string[] = [];
        if (this._columnSplits.get(columnIndex)) {
            css.push('has-split');
        }
        if (!shift) {
            css.push('has-background-grey-light');
            return css;
        }
        if (shift.is_locked) {
            css.push('is-locked');
        } else {
            if (shift.is_imposed) {
                css.push('is-imposed-shift');
            }
            if (shift.is_mandatory) {
                css.push('is-mandatory-shift');
            }
            if (shift.is_voluntary) {
                css.push('is-voluntary-shift');
            }
            if (this.user) {
                if (this.isDeselected(shift)) {
                    css.push('has-background-danger');
                    if (shift.user === this.user.data.id) {
                        css.push('has-background-warning');
                    }
                } else if (this.user.hasShiftConflict(shift)) {
                    css.push('has-background-warning');
                } else if (this.user.hasPossibleConflict(shift)) {
                    css.push('has-possible-conflict');
                } else if (shift.user === this.user.data.id) {
                    css.push('has-background-primary');
                }
            }
        }
        return css;
    }

    private getDeselection(date: string) {
        return this._deselections.get(date);
    }

    private getDayPeriod(shifttypeId: number) {
        return this.shifttypes.find(s => s.id === shifttypeId).timeperiod;
    }

    private isDeselected(shift: Shift) {
        const deselection = this.getDeselection(shift.date);
        if (!deselection) {
            return false;
        } else if (deselection.day && deselection.evening && deselection.night) {
            return true;
        } else {
            return deselection[this.getDayPeriod(shift.shifttype)];
        }
    }

    private getTimeHours(time: string) {
        return time.substr(0, 2);
    }

    private setupShortcuts() {
        this._days.forEach(d => {
            if (d.data.substr(8, 2) === '01') {
                const nextkey = 'ctrl+' + (this._shortcuts.length + 1);
                this._shortcuts.push(this.hotkeysService.add(new Hotkey(nextkey, (event: KeyboardEvent) => {
                    const el = document.getElementById(d.data);
                    let top = el.getBoundingClientRect().top + window.scrollY;
                    if (!this.lockHeader) {
                        top += this.lockOffset + this.lockDistance - 1;
                    }
                    const header = this.header.nativeElement as HTMLElement;
                    const bottom = header.getBoundingClientRect().bottom;
                    // Add 2 to account for borders
                    window.scrollTo(window.scrollX, top - bottom + 2);
                    return false;
                })));
            }
        });
    }

    private destroyShortcuts() {
        this._shortcuts.forEach(s => this.hotkeysService.remove(s));
    }

    @HostListener('window:scroll')
    onScroll() {
        // Updates header position on horizontal scrolling
        if (!this.header || this._lastPageXOffset === window.pageXOffset) {
            return;
        }
        this._lastPageXOffset = window.pageXOffset;
        this.renderer.setStyle(this.header.nativeElement, 'left', 'calc(25% - '  + window.pageXOffset + 'px)');
        this.updateDateElementsLeft();
    }

    @HostListener('window:resize')
    onResize() {
        this.adjustColumnWidths();
        this.setBodyMargin();
    }

    private updateDateElementsLeft() {
        if (!this.header) {
            return;
        }
        const dates = this.getDateElements();
        let left = window.pageXOffset + 'px';
        if (!this.lockHeader) {
            left = 'calc(25% + ' + left + ')';
        }
        for (let i = 0; i < dates.length; i++) {
            this.renderer.setStyle(dates[i], 'left', left);
        }
    }

    private getDateElements() {
        if (!this._dateElements) {
            this._dateElements = [this.header.nativeElement.children[0].children[0]];
            const bodyColumns = this.body.nativeElement.children;
            for (let i = 0; i < bodyColumns.length; i++) {
                this._dateElements.push(bodyColumns[i].children[0]);
            }
        }
        return this._dateElements;
    }

    private updateTable() {
        if (!this._tableUpdated) {
            return;
        }
        this.resetScroll();
        this.adjustColumnWidths();
        this.setBodyMargin();
        // Date elements are cached when first gotten, so needs a reset
        this._dateElements = null;
        this.updateDateElementsLeft();
    }

    private resetScroll() {
        window.scrollTo(0, 0);
        this._lastPageXOffset = 0;
    }

    private setBodyMargin() {
        const header = this.header.nativeElement as HTMLElement;
        const height = header.getBoundingClientRect().height;
        // Subtracts 1 to account for border
        this.renderer.setStyle(this.body.nativeElement, 'marginTop', (height - 1) + 'px');
    }

    private adjustColumnWidths() {
        const header = this.header.nativeElement as HTMLElement;
        const body = this.body.nativeElement as HTMLElement;

        if (header.children.length === 0 || body.children.length === 0) {
            return;
        }

        // Columns are always in last child; if first is set it's districts
        const columns = header.children[header.children.length - 1].children;
        const bodyColumns = body.children[0];

        // Skip first column which is date
        for (let i = 1; i < bodyColumns.children.length; i++) {
            let headerWidth = columns[i].getBoundingClientRect().width;
            let bodyWidth = bodyColumns.children[i].getBoundingClientRect().width;

            if (i === 1) {
                const styles = window.getComputedStyle(columns[i]);
                const leftBorderWidth = parseInt(styles.getPropertyValue('border-left-width'), 10) - 1;
                headerWidth -= leftBorderWidth;
                bodyWidth -= leftBorderWidth;
            }

            // Checking which is widest is needed because of the wide column splits for timeperiods
            // minWidth is needed as browser ignores width
            if (bodyWidth > headerWidth) {
                this.renderer.setStyle(columns[i], 'minWidth', bodyWidth + 'px');
            } else {
                this.renderer.setStyle(bodyColumns.children[i], 'minWidth', headerWidth + 'px');
            }
        }
    }

    private getColumnSplits() {
        const map = new Map<number, boolean>();
        let lastPeriod: 'day' | 'evening' | 'night' = null;
        let lastType = 0;
        this.shifttypes.forEach((s, i) => {
            const periodChanged = !!lastPeriod && s.timeperiod !== lastPeriod;
            const typeChanged = !!lastType && s.type !== lastType;
            map.set(i, periodChanged || typeChanged);
            lastPeriod = s.timeperiod;
            lastType = s.type;
        });
        return map;
    }

    private getShifttypeStates() {
        const css = new Map<number, string[]>();
        this.shifttypes.forEach((s, i) => {
            css.set(s.id, this.shifttypeCss(s, i));
        });
        return css;
    }

    private shifttypeCss(shifttype: Shifttype, columnIndex: number) {
        const css: string[] = [];
        if (!!shifttype.comment.length) {
            css.push('tooltip', 'is-tooltip-bottom');
        }
        if (this._columnSplits.get(columnIndex)) {
            css.push('has-split');
        }
        return css;
    }

    private getShiftsByShifttype(shifttype: Shifttype) {
        const shifts: Shift[] = [];
        this.dates.forEach(d => {
            d.forEach(shift => {
                if (shift && shift.shifttype === shifttype.id) {
                    shifts.push(shift);
                }
            });
        });
        return shifts;
    }

    private getShiftIdsByShifttype(shifttype: Shifttype) {
        return this.getShiftsByShifttype(shifttype).map(s => s.id);
    }

}
