import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Period } from '@app/shared';


@Component({
    selector: 'app-deselection-list',
    templateUrl: './deselection-list.component.html',
    styleUrls: ['./deselection-list.component.css']
})
export class DeselectionListComponent implements OnDestroy, OnInit {

    activePeriods: Period[];
    lockedPeriods: Period[];
    private submitted = new Map<number, boolean>();
    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.subscriptions.add(this.route.data.subscribe(
            data => {
                this.activePeriods = data.activePeriods || [];
                this.prepare(data.lockedPeriods || []);
            }
        ));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    goToDeselection(period: Period) {
        this.router.navigate(['deselection', period.id]);
    }

    isSubmitted(period: Period) {
        return this.submitted.get(period.id);
    }

    private prepare(lockedPeriods: Period[]) {
        this.activePeriods.forEach(p => {
            this.submitted.set(p.id, lockedPeriods.some(l => l.id === p.id));
        });

        this.lockedPeriods = lockedPeriods.filter(
            p => !this.activePeriods.some(a => a.id === p.id));
    }

}
