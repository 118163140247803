import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { DistrictGroup } from '@app/shared';


@Component({
    selector: 'app-district-group-list',
    templateUrl: './district-group-list.component.html',
    styleUrls: ['./district-group-list.component.css']
})
export class DistrictGroupListComponent implements OnInit, OnDestroy {

    private _groups: DistrictGroup[] = [];
    private _routeSub: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) { }

    get groups() {
        return this._groups;
    }

    ngOnInit() {
        this._routeSub = this.route.data.subscribe(
            data => {
                this._groups = data.groups;
            },
            error => console.error(error)
        );
    }

    ngOnDestroy() {
        if (this._routeSub) {
            this._routeSub.unsubscribe();
        }
    }

    edit(group: DistrictGroup) {
        this.router.navigate(['/admin/district-group', group.id]);
    }

}
