import { Hotkey, HotkeysService } from 'angular2-hotkeys';

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { DistrictService, DistrictGroupService, ShifttypeTypeService } from '@app/core';
import { District, DistrictGroup, Emit, Prepare, Selection, ShifttypeType } from '@app/shared';


@Component({
    selector: 'app-selection-bar',
    templateUrl: './selection-bar.component.html',
    styleUrls: ['./selection-bar.component.css']
})
export class SelectionBarComponent implements OnInit, OnDestroy {

    @Output() event = new EventEmitter<Emit>();

    private _cursorMode: 'add' | 'lock' | 'remove' = 'add';
    private _districts: District[] = [];
    private _districtGroups: DistrictGroup[] = [];
    private _districtSelections: Selection[] = [];
    private _allUsersEnabled = false;
    private _historyEnabled = false;
    private _imposedEnabled = false;
    private _imposedWishesEnabled = false;
    private _mandatoryEnabled = false;
    private _mandatoryWishesEnabled = false;
    private _prepare: Prepare;
    private _selectedDistrict: District;
    private _selectedDistrictGroup: DistrictGroup;
    private _selectedDoctorType = 1;
    private _selectedType: ShifttypeType;
    private _shortcuts: (Hotkey | Hotkey[])[] = [];
    private _types: ShifttypeType[] = [];
    private _voluntaryEnabled = false;
    private _voluntaryWishesEnabled = false;

    constructor(
        private districtService: DistrictService,
        private districtGroupService: DistrictGroupService,
        private shifttypeTypeService: ShifttypeTypeService,
        private hotkeysService: HotkeysService
    ) {
        this._prepare = new Prepare(
            ['districts', 'districtGroups', 'types'],
            () => {
                this.setDistrictSelections();
                this.event.emit({ type: 'loaded' });
            }
        );
    }

    ngOnInit() {
        this.prepare();
        this.setupShortcuts();
    }

    ngOnDestroy() {
        this.destroyShortcuts();
    }

    listener(type: string, event: any) {
        if (type === 'district') {
            this.selectDistrict(event);
        } else if (type === 'type') {
            this.selectType(event);
        }
    }

    get districts() {
        return this._districts;
    }

    get districtSelections() {
        return this._districtSelections;
    }

    get types() {
        return this._types;
    }

    get hasDistrict() {
        return !!this._selectedDistrict || !!this._selectedDistrictGroup;
    }

    get hasType() {
        return !!this._selectedType;
    }

    get isAllUsersEnabled() {
        return this._allUsersEnabled;
    }

    get isHistoryEnabled() {
        return this._historyEnabled;
    }

    get isImposedEnabled() {
        return this._imposedEnabled;
    }

    get isImposedWishesEnabled() {
        return this._imposedWishesEnabled;
    }

    get isMandatoryEnabled() {
        return this._mandatoryEnabled;
    }

    get isMandatoryWishesEnabled() {
        return this._mandatoryWishesEnabled;
    }

    get isVoluntaryEnabled() {
        return this._voluntaryEnabled;
    }

    get isVoluntaryWishesEnabled() {
        return this._voluntaryWishesEnabled;
    }

    get isLoading() {
        return this._prepare.isReady;
    }

    get isAddMode() {
        return this._cursorMode === 'add';
    }

    get isLockMode() {
        return this._cursorMode === 'lock';
    }

    get isRemoveMode() {
        return this._cursorMode === 'remove';
    }

    get selectedDoctorType() {
        return this._selectedDoctorType;
    }

    changeDoctorType(doctorType: number) {
        this._selectedDoctorType = doctorType;
        this.event.emit({ type: 'doctor-type', data: doctorType });
    }

    setCursorMode(mode: 'add' | 'lock' | 'remove') {
        this._cursorMode = mode;
        this.event.emit({ type: 'cursor-mode', data: mode });
    }

    toggleAllUsers() {
        this._allUsersEnabled = !this._allUsersEnabled;
        this.event.emit({ type: 'all-users', data: this._allUsersEnabled });
    }

    toggleHistory() {
        this._historyEnabled = !this._historyEnabled;
        this.event.emit({ type: 'history', data: this._historyEnabled });
    }

    toggleImposed() {
        this._mandatoryEnabled = false;
        this._voluntaryEnabled = false;
        this._imposedEnabled = !this._imposedEnabled;
        this.event.emit( { type: 'imposed', data: this._imposedEnabled });
    }

    toggleImposedWishes() {
        this._mandatoryWishesEnabled = false;
        this._voluntaryWishesEnabled = false;
        this._imposedWishesEnabled = !this._imposedWishesEnabled;
        this.event.emit( { type: 'imposed-wishes', data: this._imposedWishesEnabled });
    }

    toggleMandatory() {
        this._imposedEnabled = false;
        this._voluntaryEnabled = false;
        this._mandatoryEnabled = !this._mandatoryEnabled;
        this.event.emit({ type: 'mandatory', data: this._mandatoryEnabled });
    }

    toggleMandatoryWishes() {
        this._imposedWishesEnabled = false;
        this._voluntaryWishesEnabled = false;
        this._mandatoryWishesEnabled = !this._mandatoryWishesEnabled;
        this.event.emit({ type: 'mandatory-wishes', data: this._mandatoryWishesEnabled });
    }

    toggleVoluntary() {
        this._imposedEnabled = false;
        this._mandatoryEnabled = false;
        this._voluntaryEnabled = !this._voluntaryEnabled;
        this.event.emit({ type: 'voluntary', data: this._voluntaryEnabled });
    }

    toggleVoluntaryWishes() {
        this._imposedWishesEnabled = false;
        this._mandatoryWishesEnabled = false;
        this._voluntaryWishesEnabled = !this._voluntaryWishesEnabled;
        this.event.emit({ type: 'voluntary-wishes', data: this._voluntaryWishesEnabled });
    }

    private prepare() {
        this.getDistricts();
        this.getDistrictGroups();
        this.getTypes();
    }

    private getDistricts() {
        this.districtService.list().subscribe(
            district => this.addDistrict(district),
            error => console.error('Error loading districts', error),
            () => this._prepare.finish('districts')
        );
    }

    private getDistrictGroups() {
        this.districtGroupService.list().subscribe(
            districtGroup => this.addDistrictGroup(districtGroup),
            error => console.error('Error loading district groups', error),
            () => this._prepare.finish('districtGroups')
        );
    }

    private getTypes() {
        this._types.push({ id: -1, name: 'Alle' });
        this.shifttypeTypeService.list().subscribe(
            type => this.addType(type),
            error => console.error('Error getting type list'),
            () => this._prepare.finish('types')
        );
    }

    private addDistrict(district: District) {
        this._districts.push(district);
    }

    private addDistrictGroup(districtGroup: DistrictGroup) {
        this._districtGroups.push(districtGroup);
    }

    private addType(type: ShifttypeType) {
        this._types.push(type);
    }

    private selectDistrict(selection: Selection) {
        const split = selection.id.split('-');
        const type = split[0];
        const id = +split[1];
        if (type === 'district') {
            this._selectedDistrict = this._districts.find(d => d.id === id);
            this._selectedDistrictGroup = null;
        } else if (type === 'group') {
            this._selectedDistrict = null;
            this._selectedDistrictGroup = this._districtGroups.find(g => g.id === id);
        }
        this.emitSelection();
    }

    private selectType(type: ShifttypeType) {
        this._selectedType = type;
        this.emitSelection();
    }

    private emitSelection() {
        if ((!!this._selectedDistrict || !!this._selectedDistrictGroup) && !!this._selectedType) {
            this.event.emit({
                type: 'selection',
                data: {
                    district: this._selectedDistrict,
                    group: this._selectedDistrictGroup,
                    type: this._selectedType
                }
            });
        }
    }

    private setDistrictSelections() {
        const districts = this._districts.map(d => {
            return {
                id: 'district-' + d.id,
                name: d.name
            };
        });
        const groups = this._districtGroups.map(g => {
            return {
                id: 'group-' + g.id,
                name: g.name
            };
        });
        this._districtSelections = districts.concat(groups);
    }

    private setupShortcuts() {
        this._shortcuts.push(this.hotkeysService.add(new Hotkey('tab', (event: KeyboardEvent) => {
            if (this._cursorMode === 'add') {
                this.setCursorMode('remove');
            } else if (this._cursorMode === 'remove') {
                this.setCursorMode('lock');
            } else if (this._cursorMode === 'lock') {
                this.setCursorMode('add');
            }
            return false;
        })));

        this._shortcuts.push(this.hotkeysService.add(new Hotkey('a', (event: KeyboardEvent) => {
            this.toggleAllUsers();
            return false;
        })));

        this._shortcuts.push(this.hotkeysService.add(new Hotkey('b', (event: KeyboardEvent) => {
            this.toggleImposed();
            return false;
        })));

        this._shortcuts.push(this.hotkeysService.add(new Hotkey('v', (event: KeyboardEvent) => {
            this.toggleVoluntary();
            return false;
        })));

        this._shortcuts.push(this.hotkeysService.add(new Hotkey('o', (event: KeyboardEvent) => {
            this.toggleMandatory();
            return false;
        })));

        this._shortcuts.push(this.hotkeysService.add(new Hotkey('q', (event: KeyboardEvent) => {
            this.toggleHistory();
            return false;
        })));

        this._shortcuts.push(this.hotkeysService.add(new Hotkey('e', (event: KeyboardEvent) => {
            this.toggleImposedWishes();
            return false;
        })));

        this._shortcuts.push(this.hotkeysService.add(new Hotkey('r', (event: KeyboardEvent) => {
            this.toggleVoluntaryWishes();
            return false;
        })));

        this._shortcuts.push(this.hotkeysService.add(new Hotkey('t', (event: KeyboardEvent) => {
            this.toggleMandatoryWishes();
            return false;
        })));
    }

    private destroyShortcuts() {
        this._shortcuts.forEach(s => this.hotkeysService.remove(s));
    }

}
