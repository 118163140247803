import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PlanningService, ToastService } from '@app/core';
import { Email, Period } from '@app/shared';


@Component({
    selector: 'app-no-submission-warning',
    templateUrl: './no-submission-warning.component.html',
    styleUrls: ['./no-submission-warning.component.css']
})
export class NoSubmissionWarningComponent implements OnInit {

    email: Email = new Email();
    period: Period;
    sending = false;

    constructor(
        private planningService: PlanningService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService,
    ) { }

    ngOnInit() {
        this.route.data.subscribe(
            data => {
                this.period = data.period;
            }
        );
    }

    get wishListLink() {
        return '/planning/' + this.period.id + '/wish-check-list';
    }

    get noShiftsListLink() {
        return '/planning/' + this.period.id + '/no-shifts-list';
    }

    get noWishesListLink() {
        return '/planning/' + this.period.id + '/no-wishes-list';
    }

    sendWarning() {
        this.sending = true;
        this.planningService.warning(this.period.id, this.email).subscribe(
            () => {
                this.toast.success('Email sendt');
            },
            error => {
                this.sending = false;
                console.error('Error sending warning email', error);
                this.toast.error('Fejl: Email ikke sendt');
            },
            () => {
                this.router.navigate(['planning', this.period.id, 'wish-check-list']);
            }
        );
    }

}
