import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { DashboardService } from '../services/dashboard.service';
import { DashboardStats } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class DashboardStatsResolverService implements Resolve<DashboardStats> {

    constructor(
        private dashboardService: DashboardService
    ) { }

    resolve() {
        return this.dashboardService.stats();
    }
}
