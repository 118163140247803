import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import {
    District,
    DistrictGroup,
    Onboarding,
    PasswordReset,
    SetPassword,
    Shift,
    ShiftMonth,
    ShifttypeType,
    User,
    UserStats,
} from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    list(ordering?: string, query?: string) {
        const qs: string[] = [];
        if (query) {
            qs.push('search=' + query);
        }
        if (ordering) {
            qs.push('ordering=' + ordering);
        }
        let url = '/api/scheduling/user/';
        if (qs.length) {
            url += '?' + qs.join('&');
        }
        return this.getList(url);
    }

    publicList(ordering?: string, query?: string) {
        const qs: string[] = [];
        if (query) {
            qs.push('search=' + query);
        }
        if (ordering) {
            qs.push('ordering=' + ordering);
        }
        let url = '/api/scheduling/user/public/';
        if (qs.length) {
            url += '?' + qs.join('&');
        }
        return this.getList(url);
    }

    users(
        periodId?: number,
        doctorTypeId?: number,
        imposedWishes?: boolean,
        mandatoryWishes?: boolean,
        voluntaryWishes?: boolean,
        district?: District,
        districtGroup?: DistrictGroup,
        shifttype?: ShifttypeType
    ) {
        let url = '/api/scheduling/user/';
        const qs: string[] = [];
        if (periodId) {
            qs.push('period=' + periodId);
        }
        if (doctorTypeId) {
            qs.push('doctortype=' + doctorTypeId);
        }
        if (imposedWishes) {
            qs.push('imposedwishes=true');
        }
        if (mandatoryWishes) {
            qs.push('mandatorywishes=true');
        }
        if (voluntaryWishes) {
            qs.push('voluntarywishes=true');
        }
        if (district) {
            qs.push('district=' + district.id);
        } else if (districtGroup) {
            qs.push('districtgroup=' + districtGroup.id);
        }
        // -1 is used for all shifttypes in UI
        if (shifttype && shifttype.id !== -1) {
            qs.push('shifttype=' + shifttype.id);
        }
        if (qs.length) {
            url += '?' + qs.join('&');
        }
        return this.getList(url);
    }

    search(query: string, periodId?: number) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/user/?search=' + query;
        if (periodId) {
            url += '&period=' + periodId;
        }
        return this.http.get<User[]>(url, { headers })
            .pipe(
                switchMap(response => response)
            );
    }

    get(id: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<User>('/api/scheduling/user/' + id + '/', { headers });
    }

    create(user: User) {
        const headers = this.authService.authHeaders();
        return this.http.post<User>('/api/user/create/', user, { headers });
    }

    update(user: User) {
        const headers = this.authService.authHeaders();
        return this.http.put<User>('/api/scheduling/user/' + user.id + '/', user, { headers });
    }

    delete(user: User) {
        const headers = this.authService.authHeaders();
        return this.http.delete('/api/user/' + user.id + '/', { headers });
    }

    availableEmployeeNumbers() {
        const headers = this.authService.authHeaders();
        return this.http.get<number[]>('/api/scheduling/available_employee_no/', { headers });
    }

    lookupEmployeeNo(employeeNo: number) {
        const headers = this.authService.authHeaders();
        return this.http.get('/api/scheduling/lookup_employee_no/' + employeeNo + '/', { headers });
    }

    passwordReset(passwordReset: PasswordReset) {
        const headers = this.authService.authHeaders();
        return this.http.post('/api/user/password/reset/', passwordReset, { headers });
    }

    onboard(onboarding: Onboarding) {
        const headers = this.authService.authHeaders();
        return this.http.post('/api/scheduling/onboard/', onboarding, { headers });
    }

    setPassword(user: number, setPassword: SetPassword) {
        const headers = this.authService.authHeaders();
        return this.http.post(
            '/api/scheduling/user/' + user + '/setpassword/',
            setPassword,
            { headers }
        );
    }

    stats() {
        const headers = this.authService.authHeaders();
        const url = '/api/scheduling/user/stats/';
        return this.http.get<UserStats>(url, { headers });
    }

    shifts(user: number) {
        const headers = this.authService.authHeaders();
        const url = '/api/scheduling/user/' + user + '/shifts/';
        const qs: string[] = [];
        return this.http.get<Shift[]>(url, { headers }).pipe(
            switchMap(response => response)
        );
    }

    shiftsByMonth(user: number, month?: number, year?: number) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/user/' + user + '/shifts/';
        const qs: string[] = ['list=month'];
        if (month) {
            qs.push('month=' + month);
        }
        if (year) {
            qs.push('year=' + year);
        }
        if (qs.length) {
            url += '?' + qs.join('&');
        }
        return this.http.get<Shift[]>(url, { headers }).pipe(
            switchMap(response => response)
        );
    }

    upcomingShifts(user: number) {
        const headers = this.authService.authHeaders();
        const url = '/api/scheduling/user/' + user + '/shifts/?list=upcoming';
        const qs: string[] = [];
        return this.http.get<Shift[]>(url, { headers }).pipe(
            switchMap(response => response)
        );
    }

    months(user: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<ShiftMonth[]>(
            '/api/scheduling/user/' + user + '/months/',
            { headers }
        ).pipe(
            switchMap(response => response)
        );
    }

    private getList(url: string) {
        const headers = this.authService.authHeaders();
        return this.http.get<User[]>(url, { headers }).pipe(
            switchMap(response => response));
    }
}
