import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { ShifttypeTypeService } from '../services/shifttype-type.service';
import { ShifttypeType } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ShifttypeTypesResolverService implements Resolve<ShifttypeType[]> {

    constructor(private service: ShifttypeTypeService) { }

    resolve() {
        const types: ShifttypeType[] = [];
        return new Promise<ShifttypeType[]>((resolve, reject) => {
            this.service.list().subscribe(
                type => types.push(type),
                error => reject(error),
                () => resolve(types)
            );
        });
    }
}
