import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { PlanningService } from '@app/core';
import { Period, PlanningShiftStats } from '@app/shared';


@Component({
    selector: 'app-shift-stats',
    templateUrl: './shift-stats.component.html',
    styleUrls: ['./shift-stats.component.css']
})
export class ShiftStatsComponent implements OnDestroy, OnInit {

    period: Period;
    query = new FormControl();
    private loading = false;
    private subscriptions = new Subscription();
    private stats: PlanningShiftStats[] = [];

    constructor(
        private planningService: PlanningService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.subscriptions.add(this.route.data.subscribe(
            data => {
                this.period = data.period;
                this.stats = data.stats;
            }
        ));
        this.subscribe();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get isLoading() {
        return this.loading;
    }

    get statsList() {
        return this.stats;
    }

    private addStat(stat: PlanningShiftStats) {
        this.stats.push(stat);
    }

    private getStats() {
        this.loading = true;
        this.clear();
        this.planningService.shiftList(this.period.id).subscribe(
            stat => this.addStat(stat),
            error => console.error('Error getting shift stats', error),
            () => {
                this.loading = false;
            }
        );
    }

    private search(query: string) {
        this.loading = true;
        this.clear();
        this.planningService.shiftList(this.period.id, query).subscribe(
            stat => this.addStat(stat),
            error => console.log('Error searching shift stats', error),
            () => {
                this.loading = false;
            }
        );
    }

    private subscribe() {
        this.subscriptions.add(this.query.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        ).subscribe(query => {
            if (query) {
                this.search(query);
            } else {
                this.getStats();
            }
        }));
    }

    private clear() {
        this.stats.length = 0;
    }

}
