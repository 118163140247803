import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { WishService } from '../../services/wish.service';
import { Wish } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class WishListResolverService implements Resolve<Wish[]> {

    constructor(
        private wishService: WishService
    ) { }

    resolve() {
        const wishes: Wish[] = [];
        return new Promise<Wish[]>((resolve, reject) => {
            this.wishService.list().subscribe(
                wish => wishes.push(wish),
                error => reject(error),
                () => resolve(wishes)
            );
        });
    }
}
