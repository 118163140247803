import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlanningService } from '../services/planning.service';
import { PlanningWish } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class PlanningUserWishResolverService implements Resolve<PlanningWish> {

    constructor(
        private planningService: PlanningService,
        private router: Router,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        const period = route.paramMap.get('period');
        const user = route.paramMap.get('user');

        if (period === null || user === null) {
            return of(new PlanningWish());
        }

        return this.planningService.wishByUser(+period, +user).pipe(
            map(wish => {
                if (wish) {
                    return wish;
                }
                this.router.navigate(['/planning/wish-period-list']);
                return null;
            })
        );
    }
}
