import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    AdminGuard,
    DistrictListResolverService,
    DistrictGroupResolverService,
    DistrictGroupListResolverService,
    HolidayResolverService,
    HolidayListResolverService,
    PageListResolverService,
    PageResolverService,
    SettingsResolverService,
    ShifttypeTypesResolverService,
    UserResolverService,
    UserShiftUpcomingListResolverService
} from '@app/core';

import { DistrictListComponent } from './district-list/district-list.component';
import { DistrictComponent } from './district/district.component';
import { DistrictResolverService } from './district/district-resolver.service';
import { DistrictGroupListComponent } from './district-group-list/district-group-list.component';
import { DistrictGroupComponent } from './district-group/district-group.component';
import { HolidayListComponent } from './holiday-list/holiday-list.component';
import { HolidayComponent } from './holiday/holiday.component';
import { PageListComponent } from './page-list/page-list.component';
import { PageComponent } from './page/page.component';
import { SettingsComponent } from './settings/settings.component';
import { ShifttypeComponent } from './shifttype/shifttype.component';
import { ShifttypeListComponent } from './shifttype-list/shifttype-list.component';
import { ShifttypeResolverService } from './shifttype/shifttype-resolver.service';
import { ShifttypeTypeComponent } from './shifttype-type/shifttype-type.component';
import { ShifttypeTypeListComponent } from './shifttype-type-list/shifttype-type-list.component';
import { ShifttypeTypeResolverService } from './shifttype-type/shifttype-type-resolver.service';
import { UserComponent } from './user/user.component';
import { UserDeleteComponent } from './user-delete/user-delete.component';
import { UserSetPasswordComponent } from './user-set-password/user-set-password.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserStatsComponent } from './user-stats/user-stats.component';


const routes: Routes = [
    {
        path: 'admin',
        canActivate: [AdminGuard],
        children: [
            {
                path: '',
                canActivateChild: [AdminGuard],
                children: [
                    {
                        path: 'user-list',
                        component: UserListComponent
                    },
                    {
                        path: 'user-stats',
                        component: UserStatsComponent
                    },
                    {
                        path: 'user/:id',
                        component: UserComponent,
                        resolve: {
                            user: UserResolverService,
                            districts: DistrictListResolverService
                        }
                    },
                    {
                        path: 'user-delete/:id',
                        component: UserDeleteComponent,
                        resolve: {
                            user: UserResolverService,
                            shifts: UserShiftUpcomingListResolverService,
                        }
                    },
                    {
                        path: 'user-set-password/:id',
                        component: UserSetPasswordComponent,
                        resolve: {
                            user: UserResolverService,
                        }
                    },
                    {
                        path: 'user',
                        component: UserComponent,
                        resolve: {
                            user: UserResolverService,
                            districts: DistrictListResolverService
                        }
                    },
                    {
                        path: 'district-list',
                        component: DistrictListComponent,
                    },
                    {
                        path: 'district/:id',
                        component: DistrictComponent,
                        resolve: {
                            district: DistrictResolverService
                        }
                    },
                    {
                        path: 'district',
                        component: DistrictComponent,
                        resolve: {
                            district: DistrictResolverService
                        }
                    },
                    {
                        path: 'district-group-list',
                        component: DistrictGroupListComponent,
                        resolve: {
                            groups: DistrictGroupListResolverService
                        }
                    },
                    {
                        path: 'district-group/:id',
                        component: DistrictGroupComponent,
                        resolve: {
                            group: DistrictGroupResolverService,
                            districts: DistrictListResolverService
                        }
                    },
                    {
                        path: 'district-group',
                        component: DistrictGroupComponent,
                        resolve: {
                            group: DistrictGroupResolverService,
                            districts: DistrictListResolverService
                        }
                    },
                    {
                        path: 'holiday-list',
                        component: HolidayListComponent,
                        resolve: {
                            holidays: HolidayListResolverService
                        }
                    },
                    {
                        path: 'holiday/:id',
                        component: HolidayComponent,
                        resolve: {
                            holiday: HolidayResolverService
                        }
                    },
                    {
                        path: 'holiday',
                        component: HolidayComponent,
                        resolve: {
                            holiday: HolidayResolverService
                        }
                    },
                    {
                        path: 'page-list',
                        component: PageListComponent,
                        resolve: {
                            pages: PageListResolverService
                        }
                    },
                    {
                        path: 'page/:id',
                        component: PageComponent,
                        resolve: {
                            page: PageResolverService
                        }
                    },
                    {
                        path: 'page',
                        component: PageComponent,
                        resolve: {
                            page: PageResolverService
                        }
                    },
                    {
                        path: 'settings',
                        component: SettingsComponent,
                        resolve: {
                            settings: SettingsResolverService
                        }
                    },
                    {
                        path: 'shifttype-list',
                        component: ShifttypeListComponent,
                        resolve: {
                            districts: DistrictListResolverService,
                            types: ShifttypeTypesResolverService
                        }
                    },
                    {
                        path: 'shifttype/:id',
                        component: ShifttypeComponent,
                        resolve: {
                            districts: DistrictListResolverService,
                            shifttype: ShifttypeResolverService,
                            types: ShifttypeTypesResolverService
                        }
                    },
                    {
                        path: 'shifttype',
                        component: ShifttypeComponent,
                        resolve: {
                            districts: DistrictListResolverService,
                            shifttype: ShifttypeResolverService,
                            types: ShifttypeTypesResolverService
                        }
                    },
                    {
                        path: 'shifttype-type-list',
                        component: ShifttypeTypeListComponent
                    },
                    {
                        path: 'shifttype-type/:id',
                        component: ShifttypeTypeComponent,
                        resolve: {
                            shifttypeType: ShifttypeTypeResolverService
                        }
                    },
                    {
                        path: 'shifttype-type',
                        component: ShifttypeTypeComponent,
                        resolve: {
                            shifttypeType: ShifttypeTypeResolverService
                        }
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [UserResolverService]
})
export class AdminRoutingModule { }
