import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Period, ScheduleStats } from '@app/shared';


@Component({
    selector: 'app-schedule-stats',
    templateUrl: './schedule-stats.component.html',
    styleUrls: ['./schedule-stats.component.css']
})
export class ScheduleStatsComponent implements OnDestroy, OnInit {

    period: Period;
    stats: ScheduleStats[];
    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.subscriptions.add(this.route.data.subscribe(
            data => {
                this.period = data.schedule.period_object;
                this.stats = data.stats;
            }
        ));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
