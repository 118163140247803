import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService, ToastService, UserService } from '@app/core';
import { District, Onboarding } from '@app/shared';


@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnDestroy, OnInit {

    districts: District[];
    onboarding = new Onboarding();
    private _subscriptions = new Subscription();

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private toastService: ToastService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(
            data => {
                this.districts = data.districts;
            }
        ));
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    save() {
        this.userService.onboard(this.onboarding).subscribe(
            () => {
                this.updateAndRedirect();
            },
            error => {
                console.error('Error during onboarding', error);
                this.toastService.error('Distrikt ikke gemt');
            }
        );
    }

    private updateAndRedirect() {
        this.authService.updateProfile().subscribe(
            () => {
                let url = '/dashboard';
                if (this.authService.isFirstLogin) {
                    url = '/set-password';
                }
                this.router.navigate([url]);
            }
        );
    }

}
