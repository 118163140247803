import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { of } from 'rxjs';

import { ShiftHistoryService } from '../services/shift-history.service';
import { ShiftHistory } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ShiftHistoryResolverService {

    constructor(private shiftHistoryService: ShiftHistoryService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const id = route.paramMap.get('id');

        if (id === null) {
            return of(new ShiftHistory());
        }

        const history: ShiftHistory[] = [];
        return new Promise<ShiftHistory[]>((resolve, reject) => {
            this.shiftHistoryService.listById(+id).subscribe(
                item => history.push(item),
                error => reject(error),
                () => resolve(history)
            );
        });
    }

}
