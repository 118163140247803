import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/core';
import { Credential } from '@app/shared';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    credential = new Credential();
    private _error = false;
    private _errorMessage = '';

    constructor(private authService: AuthService, private router: Router) { }

    ngOnInit() {
    }

    get hasError() {
        return this._error;
    }

    get errorMessage() {
        return this._errorMessage;
    }

    login() {
        this._error = false;
        this.authService
            .login(this.credential)
            .subscribe(
                () => this.success(),
                error => this.error(error)
            );
    }

    private success() {
        const url = this.authService.redirectUrl || '/dashboard';
        this.router.navigate([url]);
    }

    private error(message: string) {
        this._error = true;
        this._errorMessage = message;
    }

}
