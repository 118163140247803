import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { UserService } from '@app/core';
import { User } from '@app/shared';


@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnDestroy, OnInit {

    ordering = 'profile__employee_no';
    orderAsc = true;
    query = new FormControl();
    title: string;
    type: 'admin' | 'phone';
    private _loading = false;
    private _subscriptions = new Subscription();
    private _users: User[] = [];

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    get users() {
        return this._users;
    }

    get isLoading() {
        return this._loading;
    }

    get isPhoneList() {
        return this.type === 'phone';
    }

    get isAdminList() {
        return this.type === 'admin';
    }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(
            data => {
                this.title = data.title || 'Brugere';
                this.type = data.type || 'admin';
            }
        ));
        this.getUsers();
        this.subscribe();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    editUser(user: User) {
        if (this.isAdminList) {
            this.router.navigate(['/admin/user', user.id]);
        }
    }

    goToShifts(user: User, event: Event) {
        if (this.isAdminList) {
            event.stopPropagation();
            this.router.navigate(['/shift/user', user.id]);
        }
    }

    updateOrdering(ordering: string) {
        if (ordering === this.ordering) {
            this.orderAsc = !this.orderAsc;
        } else {
            this.orderAsc = true;
        }
        this.ordering = ordering;
        this.updateList();
    }

    private updateList() {
        if (this.query.value) {
            this.search(this.query.value);
        } else {
            this.getUsers();
        }
    }

    private getUsers() {
        this._loading = true;
        this.clear();
        this.getUserList().subscribe(
            user => this.addUser(user),
            error => console.error('Error getting user list', error),
            () => {
                this._loading = false;
            }
        );
    }

    private getUserList(query?: string) {
        if (this.isAdminList) {
            return this.userService.list(this.getOrdering(), query);
        } else if (this.isPhoneList) {
            return this.userService.publicList(this.getOrdering(), query);
        }
    }

    private addUser(user: User) {
        this._users.push(user);
    }

    private subscribe() {
        this._subscriptions.add(this.query.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        ).subscribe(query => {
            if (query) {
                this.search(query);
            } else {
                this.getUsers();
            }
        }));
    }

    private search(query: string) {
        this._loading = true;
        this.clear();
        this.getUserList(query).subscribe(
            user => this.addUser(user),
            error => console.log('Error searching for user', error),
            () => {
                this._loading = false;
            }
        );
    }

    private clear() {
        this._users.length = 0;
    }

    private getOrdering() {
        return this.orderAsc ? this.ordering : '-' + this.ordering;
    }

}
