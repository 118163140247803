import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Period, Wish } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class WishService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    list() {
        const headers = this.authService.authHeaders();
        const url = '/api/scheduling/wish/';
        return this.http.get<Wish[]>(url, { headers }).pipe(
                switchMap(response => response)
            );
    }

    getByPeriod(period: number | string) {
        const headers = this.authService.authHeaders();
        const url = '/api/scheduling/period/' + period + '/wish/';
        return this.http.get<Wish[]>(url, { headers }).pipe(
            switchMap(response => response)
        );
    }

    create(wish: Wish) {
        const headers = this.authService.authHeaders();
        return this.http.post<Wish>(
            '/api/scheduling/period/' + wish.period + '/wish/',
            wish,
            { headers }
        );
    }

    update(wish: Wish) {
        const headers = this.authService.authHeaders();
        return this.http.put<Wish>(
            '/api/scheduling/wish/' + wish.id + '/',
            wish,
            { headers }
        );
    }
}
