import { formatDate } from '@angular/common';

import { ConflictHandler } from './conflict-handler';
import { ShiftCount } from './shift-count';
import { UIState } from '../generics';
import { Shift, Shifttype, ShifttypeConstraint, User } from '../models';


export class UserState extends UIState<User> {

    private conflictTypes = {
        'time_overlap_sameday': 'Vagt overlapper',
        'time_overlap_nextday': 'Vagt overlapper',
        'day_after_night_sameday': 'Dagsvagt efter nattevagt',
        'day_after_night_nextday': 'Dagsvagt efter nattevagt',
        'visit_after_visit_sameday': 'Vis efter vis',
        'visit_after_visit_nextday': 'Vis efter vis'
    };

    private _conflictHandler: ConflictHandler;
    private _shiftsGiven = new ShiftCount();

    constructor(
        data: User,
        private shifttypes: Shifttype[],
        private constraints: Map<number, Map<number, string[]>>,
        css?: string[]
    ) {
        super(data, css);
        this._conflictHandler = new ConflictHandler(this.constraints, data.shifts);
        this.calculateGiven();
        this.updateState();
    }

    get shifts() {
        return this.data.shifts;
    }

    get shiftsGiven() {
        return this._shiftsGiven;
    }

    get isDone() {
        return this.shiftsFinished || (this.eveningDone);
    }

    get shiftsFinished() {
        return this.data.wish.admin_shifts_finished;
    }

    set shiftsFinished(value: boolean) {
        this.data.wish.admin_shifts_finished = value;
        this.updateState();
    }

    get eveningDriveDone() {
        return this.shiftsGiven.evening_drive >= this.data.wish.evening_drive;
    }

    get eveningVisitationDone() {
        return this.shiftsGiven.evening_visitation >= this.data.wish.evening_visitation;
    }

    get eveningDone() {
        return this.shiftsGiven.evening_total >= this.eveningTotal;
    }

    get eveningTotal() {
        return this.data.wish.evening_drive + this.data.wish.evening_visitation;
    }

    addShift(shift: Shift) {
        // Called when user adds shift from scheduling table
        // Initial array of shifts are loaded from backend when users are loaded
        this.data.shifts.splice(this.getShiftPosition(shift), 0, shift);
        this.updateGiven(shift);
        this._conflictHandler.addShift(shift);
        this.updateState();
    }

    removeShift(shift: Shift) {
        const index = this.data.shifts.findIndex(i => i.id === shift.id);
        this.data.shifts.splice(index, 1);
        this.updateGiven(shift, -1);
        this._conflictHandler.removeShift(shift);
        this.updateState();
    }

    getShiftConflicts(shift: Shift) {
        const conflicts = this._conflictHandler.getConlicts(shift);
        const text: string[] = [];
        if (conflicts.size) {
            const keys = conflicts.keys();
            let result = keys.next();
            while (!result.done) {
                const types = conflicts.get(result.value);
                const conflictTexts = types.map(t => this.conflictTypes[t]).join(', ');
                const conflictingShift = this.data.shifts.find(s => s.id === result.value);
                const conflictingShifttype = this.shifttypes.find(t => t.id === conflictingShift.shifttype);
                text.push(conflictingShifttype.name + ' ' + formatDate(conflictingShift.date, 'd/M', 'da') + ': ' + conflictTexts);
                result = keys.next();
            }
        }
        return text;
    }

    hasShiftConflict(shift: Shift) {
        return this._conflictHandler.hasShiftConflict(shift);
    }

    hasPossibleConflict(shift: Shift) {
        return this._conflictHandler.hasPossibleConflict(shift);
    }

    updatePossibleConflicts() {
        this._conflictHandler.updatePossibleConflicts();
    }

    private calculateGiven() {
        const shifts = this.data.shifts;
        shifts.forEach(shift =>
            this.updateGiven(shift));
    }

    private getType(shift: Shift) {
        const shifttype = this.shifttypes.find(s => s.id === shift.shifttype);
        return [
            shifttype.timeperiod === 'night' ? 'night' : 'evening',  // day and evening is combined for wishes
            shifttype.drive ? 'drive' : 'visitation'
        ].join('_');
    }

    private updateGiven(shift: Shift, value = 1) {
        const type = this.getType(shift);
        this._shiftsGiven[type] = this._shiftsGiven[type] + value;
    }

    private updateState() {
        if (this._conflictHandler.hasConflict()) {
            this.addClass('has-background-warning');
        } else if (this.isDone) {
            this.removeClass('has-background-warning');
            this.addClass('has-background-success');
        } else {
            this.removeClass('has-background-warning');
            this.removeClass('has-background-success');
        }

        if (this.data.wish.temporary) {
            this.addClass('has-temporary');
        } else {
            this.removeClass('has-temporary');
        }

        if (this.data.wish.mandatory) {
            this.addClass('has-mandatory');
        } else {
            this.removeClass('has-mandatory');
        }

        if (this.data.wish.no_shifts) {
            this.addClass('has-no-shifts-wish');
        } else if (this.data.wish.imposed) {
            this.addClass('has-imposed-wishes');
        } else {
            this.removeClass('has-no-shifts-wish');
            this.removeClass('has-imposed-wishes');
        }
    }

    private getShiftPosition(shift: Shift) {
        let found = false;
        const shiftDate = this.getDateAsNumber(shift.date);
        for (let i = 0; i < this.shifts.length; i++) {
            const date = this.getDateAsNumber(this.shifts[i].date);
            found = shiftDate < date;
            if (found) {
                return i;
            }
        }
        return this.shifts.length;
    }

    private getDateAsNumber(date: string) {
        return +date.replace(/-/g, '');
    }

}
