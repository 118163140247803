import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.checkLogin(state.url).pipe(
            switchMap(
                loggedIn => {
                    if (this.authService.isLoggedIn && !this.authService.isOnboarded) {
                        let url = '/onboarding';
                        if (this.authService.hasDistrict) {
                            url = '/set-password';
                        }
                        this.router.navigate([url]);
                        return of(false);
                    }
                    return of(loggedIn);
                }));
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(next, state);
    }
}
