import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Deselection, Period, User } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class DeselectionService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    periods(period?: Period) {
        const headers = this.authService.authHeaders();
        const url = '/api/scheduling/deselection/periods/';
        return this.http.get<Period[]>(url, { headers }).pipe(
            switchMap(response => response)
        );
    }

    list(period: number | string, user?: User) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/period/' + period + '/deselection/';
        if (user) {
            url += '?user=' + user.id;
        }
        return this.http.get<Deselection[]>(url, { headers }).pipe(
            switchMap(response => response)
        );
    }

    save(period: Period, deselections: Deselection[]) {
        const requests: Observable<Deselection[]>[] = [];
        const creates = deselections.filter(d => !d.id);
        const updates = deselections.filter(d => !!d.id);
        if (creates.length) {
            requests.push(this.createList(period, creates));
        }

        if (updates.length) {
            requests.push(this.updateList(period, updates));
        }
        return forkJoin(requests);
    }

    createList(period: Period, deselections: Deselection[]) {
        const headers = this.authService.authHeaders();
        return this.http.post<Deselection[]>(
            '/api/scheduling/period/' + period.id + '/deselection/create/',
            deselections,
            { headers }
        );
    }

    updateList(period: Period, deselections: Deselection[]) {
        const headers = this.authService.authHeaders();
        return this.http.put<Deselection[]>(
            '/api/scheduling/period/' + period.id + '/deselection/update/',
            deselections,
            { headers }
        );
    }


}
