export class PlanningWish {
    id: number;
    user: number;
    period: number;
    evening_visitation = 0;
    evening_drive = 0;
    comment: string;
    no_shifts: boolean;
    mandatory: boolean;
    employee_no: number;
    evening_visitation_override: number;
    evening_drive_override: number;
    admin_comment: string;
    is_approved: boolean;
    has_deselections: boolean;
    district: number;
}
