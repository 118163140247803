export class UIState<T> {

    private _css: string[];
    private _data: T;

    constructor(data: T, css?: string[]) {
        this._data = data;
        this.update(css);
    }

    get css() {
        return this._css;
    }

    get data() {
        return this._data;
    }

    set data(value: T) {
        this._data = value;
    }

    addClass(css: string) {
        if (this._css.indexOf(css) === -1) {
            this._css.push(css);
        }
    }

    removeClass(css: string) {
        const index = this._css.indexOf(css);
        if (index > -1) {
            this._css.splice(index, 1);
        }
    }

    update(css: string[]) {
        this._css = css ? css : [];
    }

}
