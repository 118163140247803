import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { ShiftModule } from '../shift';


@NgModule({
    imports: [
        SharedModule,
        ShiftModule,
        DashboardRoutingModule
    ],
    declarations: [DashboardComponent]
})
export class DashboardModule { }
