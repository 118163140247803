import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { District, Settings, Shifttype, ShifttypeType } from '@app/shared';


@Component({
    selector: 'app-my-shifts',
    templateUrl: './my-shifts.component.html',
    styleUrls: ['./my-shifts.component.css']
})
export class MyShiftsComponent implements OnInit, OnDestroy {

    districts: District[];
    settings: Settings;
    shifttypes: Shifttype[];
    shifttypeTypes: ShifttypeType[];
    type = 'upcoming';
    years: number[];
    private _routeSub: Subscription;

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        this._routeSub = this.route.data.subscribe(
            data => {
                this.districts = data.districts;
                this.shifttypes = data.shifttypes;
                this.shifttypeTypes = data.shifttypeTypes;
                this.settings = data.settings;
                this.years = this.getYears();
            },
            error => console.error(error)
        );
    }

    ngOnDestroy() {
        this._routeSub.unsubscribe();
    }

    private getYears() {
        const currentYear = new Date().getFullYear();
        const years: number[] = [];
        for (let i = 2020; i < currentYear + 1; i++) {
            years.push(i);
        }
        return years;
    }

}
