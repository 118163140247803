import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    AuthGuard,
    DashboardStatsResolverService,
    DistrictListResolverService,
    ShifttypesResolverService,
    ShifttypeTypesResolverService
} from '@app/core';
import { DashboardComponent } from './dashboard.component';


const routes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        resolve: {
            districts: DistrictListResolverService,
            shifttypes: ShifttypesResolverService,
            shifttypeTypes: ShifttypeTypesResolverService,
            stats: DashboardStatsResolverService
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }
