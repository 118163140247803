import { Period } from './period';


export class Schedule {
    id: number;
    period: number;
    is_published: boolean;
    published: Date;

    period_object?: Period;
}
