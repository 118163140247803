import { Shift, Shifttype } from '../models';

export class ShiftHandler {
    private _shifts = new Map<string, Shift[]>();

    get shifts() {
        return this._shifts;
    }

    add(shift: Shift) {
        this.getShifts(shift.date).push(shift);
    }

    reorder(types: Shifttype[]) {
        const order = types.map(type => type.id);
        const keys = this._shifts.keys();
        let result = keys.next();
        while (!result.done) {
            const array = this.reorderArray(order, this._shifts.get(result.value));
            this._shifts.set(result.value, array);
            result = keys.next();
        }
        return this._shifts;
    }

    private getShifts(date: string) {
        let shifts = this._shifts.get(date);
        if (!shifts) {
            shifts = [];
            this._shifts.set(date, shifts);
        }
        return shifts;
    }

    private reorderArray(order: number[], shifts: Shift[]) {
        const result: Shift[] = [];
        order.forEach(type => {
            const shift = shifts.find(s => s.shifttype === type);
            result.push(shift ? shift : null);
        });
        return result;
    }
}
