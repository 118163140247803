// TODO: Could all these be interfaces?
export class PlanningActiveDoctorType {
    doctor_type: number;
    count: number;
}

export class PlanningActiveDoctor {
    district: string;
    doctor_types: PlanningActiveDoctorType[];
    total: number;
    percentage: number;
}

export class PlanningAllShifts {
    district: string;
    evening_visitation: number;
    evening_drive: number;
    total: number;
    percentage: number;
}

export class PlanningStats {
    active_doctors: PlanningActiveDoctor[];
    all_shifts: PlanningAllShifts[];
}
