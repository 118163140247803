import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ProfileService } from '@app/core';
import { Profile } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ProfileResolverService implements Resolve<Profile> {

    constructor(
        private profileService: ProfileService,
        private router: Router
    ) { }

    resolve(): Observable<Profile> {
        return this.profileService.get();
    }

}
