import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { DashboardStats } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    stats() {
        const headers = this.authService.authHeaders();
        return this.http.get<DashboardStats>(
            '/api/scheduling/dashboard_stats/', { headers });
    }
}
