import { Period } from './period';


export class Wish {
    id: number;
    period: number;
    evening_visitation = 0;
    evening_drive = 0;
    comment: string;
    no_shifts: boolean;
    temporary: boolean = false;
    mandatory: boolean = false;
    voluntary: boolean = false;
    imposed: boolean = false;
    admin_comment: string;
    admin_shifts_finished: boolean;

    period_object?: Period;
}
