import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Period, PlanningStats } from '@app/shared';


@Component({
    selector: 'app-planning-period',
    templateUrl: './planning-period.component.html',
    styleUrls: ['./planning-period.component.css']
})
export class PlanningPeriodComponent implements OnInit {

    period: Period;
    stats: PlanningStats;

    activeDoctorsHeaders = [
        'PLO',
        'Vagtlæger',
        'Udd. læger',
        'Deltagende læger',
        'Procentvis'
    ];
    activeDoctorsRows = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit() {
        this.route.data.subscribe(
            data => {
                this.period = data.period;
                this.stats = data.stats;
                this.prepareActiveDoctors();
            }
        );
    }

    get wishListLink() {
        return '/planning/' + this.period.id + '/wish-check-list';
    }

    get noShiftsListLink() {
        return '/planning/' + this.period.id + '/no-shifts-list';
    }

    get noWishesListLink() {
        return '/planning/' + this.period.id + '/no-wishes-list';
    }

    private prepareActiveDoctors() {
        this.stats.active_doctors.forEach(stat => {
            const row = [stat.district];
            for (let i = 1; i < 4; i++) {
                const type = stat.doctor_types.find(dt => dt.doctor_type === i);
                row.push(type ? '' + type.count : '0');
            }
            row.push('' + stat.total);
            row.push('' + stat.percentage);
            this.activeDoctorsRows.push(row);
        });
    }

}
