import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ScheduleService } from '@app/core';
import { Schedule, ScheduleValidation } from '@app/shared';


@Component({
    selector: 'app-publish',
    templateUrl: './publish.component.html',
    styleUrls: ['./publish.component.css']
})
export class PublishComponent implements OnDestroy, OnInit {

    schedule: Schedule;
    validation: ScheduleValidation;
    private _done = false;
    private _valid = false;
    private _loading = false;
    private _subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private scheduleService: ScheduleService
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(
            data => {
                this.schedule = data.schedule;
                this.validate();
            },
            error => console.error(error)
        ));
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    get isDone() {
        return this._done;
    }

    get isLoading() {
        return this._loading;
    }

    get isValid() {
        return this._valid;
    }

    publish() {
        // if (!this._valid) {
        //     return;
        // }
        this.scheduleService.publish(this.schedule).subscribe(
            () => {},
            error => console.error('Error during publishing', error),
            () => {
                this.router.navigate(['/scheduler/schedule-list']);
            }
        );
    }

    private validate() {
        this._loading = true;
        this.scheduleService.validate(this.schedule).subscribe(
            validation => {
                this.validation = validation;
                this._valid = true;
            },
            error => {
                this.validation = error.error;
                this._valid = false;
                this._done = true;
            },
            () => {
                this._loading = false;
                this._done = true;
            }
        );
    }

}
