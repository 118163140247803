import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '@app/core';
import { PasswordResetConfirm } from '@app/shared';


@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit, OnDestroy {

    passwordReset = new PasswordResetConfirm();
    errorNew = false;
    errorRetype = false;
    errorToken = false;
    errorNewMessage: string;
    errorRetypeMessage: string;
    resetSuccess = false;
    private _subscriptions = new Subscription();

    private passwordMessages = {
        'This password is too short. It must contain at least 8 characters.': 'Adgangskode skal være mindst 8 tegn',
        'This password is entirely numeric.': 'Adgangskode skal indeholde andet end tal',
        'This password is too common.': 'Denne adgangskode er for almindelig',
        'The password is too similar to the email address.': 'Adgangskoden ligner for meget email adressen',
        'The password is too similar to the first name.': 'Adgangskoden ligner for meget fornavn',
        'The password is too similar to the last name.': 'Adgangskoden ligner for meget efternavn',
        'Invalid password.': 'Forkert adgangskode',
        'The two password fields didn\'t match.': 'Adgangskoder skal være ens'
    };

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.params.subscribe(
            params => {
                console.log(params);
                this.passwordReset.token = params.token;
                this.passwordReset.uid = params.uid;
            }
        ));
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    reset() {
        this.errorNew = false;
        this.errorRetype = false;
        this.resetSuccess = false;
        this.authService.passwordResetConfirm(this.passwordReset).subscribe(
            () => this.success(),
            error => this.fail(error)
        );
    }

    private success() {
        this.resetSuccess = true;
    }

    private fail(error: any) {
        const msg = error.error;
        if (msg && msg.new_password) {
            this.errorNew = true;
            this.errorNewMessage = this.passwordMessages[msg.new_password[0]];
        }
        if (msg && msg.non_field_errors) {
            if (msg.non_field_errors.includes('Invalid token for given user.')) {
                this.errorToken = true;
            } else {
                this.errorNew = true;
                this.errorRetype = true;
                this.errorNewMessage = this.passwordMessages[msg.non_field_errors[0]];
                this.errorRetypeMessage = this.passwordMessages[msg.non_field_errors[0]];
            }
        }
    }

}
