import { UserState } from './user-state';
import { Shift, Shifttype, ShifttypeConstraint, User } from '../models';
import { Observable } from 'rxjs';

import { Wish } from '../models/wish';


export class UserHandler {
    private _states: UserState[] = [];
    private _users = new Map<number, UserState>();

    constructor(
        private shifttypes: Shifttype[],
        private constraints: Map<number, Map<number, string[]>>
    ) { }

    get states() {
        return this._states;
    }

    get ids() {
        return Array.from(this._users.keys()) || [];
    }

    addUser(user: User) {
        const state = this.getUser(user);
        this._states.push(state);
        this._users.set(user.id, state);
    }

    private getUser(user: User) {
        if (!user.wish) {
            user.wish = new Wish();
        }
        return new UserState(user, this.shifttypes, this.constraints);
    }

    clear() {
        this._states.length = 0;
        this._users.clear();
    }

    addShift(userId: number, shift: Shift) {
        const user = this._users.get(userId);
        if (user) {
            user.addShift(shift);
        }
    }

    removeShift(userId: number, shift: Shift) {
        const user = this._users.get(userId);
        if (user) {
            user.removeShift(shift);
        }
    }

    loadUsers(observable: Observable<User>) {
        this.clear();
        const states: UserState[] = [];
        const userMap = new Map<number, UserState>();
        observable.subscribe(
            user => {
                const state = this.getUser(user);
                states.push(state);
                userMap.set(user.id, state);
            },
            error => console.error('Error loading users', error),
            () => {
                this._states = states;
                this._users = userMap;
            }
        );
    }
}
