import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    AuthService,
    ToastService,
    WishService
} from '@app/core';
import { Period, Settings, Wish } from '@app/shared';


@Component({
    selector: 'app-wish',
    templateUrl: './wish.component.html',
    styleUrls: ['./wish.component.css']
})
export class WishComponent implements OnInit {

    evening_visitation_values: number[] = [];
    evening_drive_values: number[] = [];
    period: Period;
    wish: Wish = null;
    private _mode: 'create' | 'update';
    private _remainingChars = 300;
    private _settings: Settings;

    constructor(
        private authService: AuthService,
        private wishService: WishService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService
    ) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this._settings = data.settings;
            this.period = data.period;
            this.wish = data.wish;
            this.prepareSettings(this._settings);
            this.prepare();
        });
    }

    get isStudent() {
        const type = this.authService.user.profile.doctor_type;
        return type === 4 || type === 5 || type === 6;
    }

    get remainingChars() {
        return this._remainingChars;
    }

    get commentLength() {
        return this._settings.comment_length;
    }

    save() {
        if (this.wish.no_shifts) {
            // Resets other selected values when no shifts is selected
            this.noShifts(this.wish);
        }
        if (this._mode === 'create') {
            this.create();
        } else if (this._mode === 'update') {
            this.update();
        }
    }

    updateComment(comment: string) {
        this.calculateRemainingChars(comment);
        this.wish.comment = comment;
    }

    private prepare() {
        if (!this.wish) {
            this.wish = new Wish();
            this.wish.period = this.period.id;
            this.wish.evening_visitation = Math.min(...this.evening_visitation_values);
            this.wish.evening_drive = Math.min(...this.evening_drive_values);

        }
        this._mode = !!this.wish.id ? 'update' : 'create';
        this.calculateRemainingChars(this.wish.comment || '');
    }

    private prepareSettings(settings: Settings) {
        this.evening_visitation_values = this.getRange(settings.evening_visitation_min, settings.evening_visitation_max);
        this.evening_drive_values = this.getRange(settings.evening_drive_min, settings.evening_drive_max);
    }

    private calculateRemainingChars(text: string) {
        this._remainingChars = this._settings.comment_length - text.length;
    }

    private getRange(start: number, end: number) {
        const list: number[] = [];
        for (let i = start; i < end + 1; i++) {
            list.push(i);
        }
        return list;
    }

    private noShifts(wish: Wish) {
        wish.evening_visitation = 0;
        wish.evening_drive = 0;
        wish.comment = '';
        wish.no_shifts = true;
    }

    private create() {
        this.wishService.create(this.wish).subscribe(
            wish => this.success(wish),
            error => this.fail(error)
        );
    }

    private update() {
        this.wishService.update(this.wish).subscribe(
            wish => this.success(wish),
            error => this.fail(error)
        );
    }

    private success(wish: Wish) {
        this.toast.success('Ønsker gemt');
        this.router.navigate(['wish-list']);
    }

    private fail(error: any) {
        const msg = error.error;
        this.toast.error('Fejl: Ønsker ikke gemt');
    }

}
