import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlanningService } from '../services/planning.service';
import { PlanningDeselection } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class PlanningUserDeselectionResolverService {

    constructor(
        private planningService: PlanningService,
        private router: Router,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        const period = route.paramMap.get('period');
        const user = route.paramMap.get('user');

        if (period === null || user === null) {
            const list: PlanningDeselection[] = [];
            return of(list);
        }

        return this.planningService.deselectionByUser(+period, +user).pipe(
            map(deselection => {
                if (deselection) {
                    return deselection;
                }
                this.router.navigate(['planning', period, 'wish-check', user]);
                return null;
            })
        );
    }
}
