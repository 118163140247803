import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { DashboardStats, District, Shifttype, ShifttypeType } from '@app/shared';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

    districts: District[];
    shifttypes: Shifttype[];
    shifttypeTypes: ShifttypeType[];
    stats: DashboardStats;
    type = 'upcoming';
    private _routeSub: Subscription;

    availableMapping: {[k: string]: string} = {
        '=1': 'ledig vagt',
        'other': 'ledige vagter'
    };

    warningMapping: {[k: string]: string} = {
        '=1': 'akutvagt',
        'other': 'akutvagter'
    };

    constructor(
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this._routeSub = this.route.data.subscribe(
            data => {
                this.districts = data.districts;
                this.shifttypes = data.shifttypes;
                this.shifttypeTypes = data.shifttypeTypes;
                this.stats = data.stats;
            },
            error => console.error(error)
        );
    }

    ngOnDestroy() {
        this._routeSub.unsubscribe();
    }

    get hasShiftWarnings() {
        return this.stats.shift_warnings > 0;
    }

    get hasAvailableShifts() {
        return this.stats.available_shifts > 0;
    }

}
