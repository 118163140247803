import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { PlanningRoutingModule } from './planning-routing.module';
import { WishCheckListComponent } from './wish-check-list/wish-check-list.component';
import { WishPeriodListComponent } from './wish-period-list/wish-period-list.component';
import { WishCheckComponent } from './wish-check/wish-check.component';
import { DeselectionCheckComponent } from './deselection-check/deselection-check.component';
import { NoShiftsListComponent } from './no-shifts-list/no-shifts-list.component';
import { NoWishesListComponent } from './no-wishes-list/no-wishes-list.component';
import { NoSubmissionWarningComponent } from './no-submission-warning/no-submission-warning.component';
import { PlanningPeriodComponent } from './planning-period/planning-period.component';
import { ShiftStatsComponent } from './shift-stats/shift-stats.component';
import { ShiftPeriodListComponent } from './shift-period-list/shift-period-list.component';

@NgModule({
  imports: [
    SharedModule,
    PlanningRoutingModule
  ],
  declarations: [
      WishCheckListComponent,
      WishPeriodListComponent,
      WishCheckComponent,
      DeselectionCheckComponent,
      NoShiftsListComponent,
      NoWishesListComponent,
      NoSubmissionWarningComponent,
      PlanningPeriodComponent,
      ShiftStatsComponent,
      ShiftPeriodListComponent,
  ]
})
export class PlanningModule { }
