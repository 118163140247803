import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { WishService } from '../../services/wish.service';
import { Wish } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class WishResolverService implements Resolve<Wish> {

    constructor(
        private wishService: WishService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        const period = route.paramMap.get('period');

        if (period === null) {
            return of(new Wish());
        }

        return this.wishService.getByPeriod(period).pipe(
            map(item => {
                if (item) {
                    return item;
                }
                this.router.navigate(['/wish-list']);
                return null;
            })
        );
    }

}
