import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ScheduleService } from '@app/core';
import { ScheduleStats } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ScheduleStatsResolverService implements Resolve<ScheduleStats> {

    constructor(
        private scheduleService: ScheduleService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<ScheduleStats> {
        const id = route.paramMap.get('id');

        if (id === null) {
            return of (new ScheduleStats());
        }

        return this.scheduleService.stats(+id).pipe(
            map(stats => {
                if (stats) {
                    return stats;
                }
                this.router.navigate(['../schedule-list']);
                return null;
            })
        );
    }
}
