import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { OnboardingRoutingModule } from './onboarding-routing.module';
import { OnboardingComponent } from './onboarding/onboarding.component';

@NgModule({
    imports: [
        SharedModule,
        OnboardingRoutingModule
    ],
    declarations: [OnboardingComponent]
})
export class OnboardingModule { }
