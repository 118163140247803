import {
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import {
    DeselectionService,
    ShiftService,
    ShifttypeService,
    UserService
} from '@app/core';
import {
    DateHelper,
    DatePick,
    Deselection,
    District,
    DistrictGroup,
    Emit,
    Holiday,
    Period,
    Prepare,
    Schedule,
    SchedulingGroup,
    Shift,
    ShiftHandler,
    ShiftHelper,
    Shifttype,
    ShifttypeConstraint,
    ShifttypeType,
    User,
    UserHandler,
    UserState,
    Wish
} from '@app/shared';


@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.css']
})
export class SchedulerComponent implements OnInit, OnDestroy {

    @ViewChild('columns') columns: ElementRef;
    @ViewChild('userColumn') userColumn: ElementRef;

    lockDistance = 0;
    lockOffset = 0;

    private _activeShifttypes: Shifttype[];
    private _holidays: string[];
    private _prepare: Prepare;
    private _shifttypes: Shifttype[] = [];

    private _constraints = new Map<number, Map<number, string[]>>();
    private _cursorMode: 'add' | 'lock' | 'remove' = 'add';
    private _dates: Map<string, Shift[]>;
    private _deselections = new Map<number, Deselection[]>();
    private _district: District;
    private _districtGroup: DistrictGroup;
    private _doctorType = 1;
    private _groups: SchedulingGroup[] = null;
    private _allUsersEnabled = false;
    private _historyEnabled = false;
    private _lastPageXOffset = 0;
    private _loading = false;
    private _userSelectionExpanded = false;
    private _imposedEnabled = false;
    private _imposedWishesEnabled = false;
    private _mandatoryEnabled = false;
    private _mandatoryWishesEnabled = false;
    private _voluntaryEnabled = false;
    private _voluntaryWishesEnabled = false;
    private _period: Period;
    private _routeSub: Subscription;
    private _schedule: Schedule;
    private _shifttypeType: ShifttypeType;
    private _user: UserState;
    private _userHandler: UserHandler;

    constructor(
        private deselectionService: DeselectionService,
        private renderer: Renderer2,
        private route: ActivatedRoute,
        private shiftService: ShiftService,
        private shifttypeService: ShifttypeService,
        private userService: UserService
    ) {
        this._prepare = new Prepare(
            ['selection-bar']
        );
    }

    ngOnInit() {
        this._routeSub = this.route.data.subscribe(
            data => {
                this._schedule = data.schedule;
                this._period = data.schedule.period_object;
                this._shifttypes = data.shifttypes;
                if (data.holidays) {
                    this._holidays = data.holidays.map(h => h.date);
                }
                if (data.constraints) {
                    this.prepareConstraints(data.constraints);
                }
                this._userHandler = new UserHandler(this._shifttypes, this._constraints);
            },
            error => console.error(error)
        );
    }

    ngOnDestroy() {
        if (this._routeSub) {
            this._routeSub.unsubscribe();
        }
    }

    listener(type: string, event: Emit) {
        if (!event) {
            return;
        }
        if (event.type === 'loaded') {
            this._prepare.finish(type);
        } else if (type === 'selection-bar' && event.type === 'selection') {
            this.selection(event.data.district, event.data.group, event.data.type);
        } else if (type === 'selection-bar' && event.type === 'cursor-mode') {
            this.setCursorMode(event.data);
        } else if (type === 'selection-bar' && event.type === 'all-users') {
            this.setAllUsersEnabled(event.data);
        } else if (type === 'selection-bar' && event.type === 'mandatory') {
            this.setMandatoryEnabled(event.data);
        } else if (type === 'selection-bar' && event.type === 'history') {
            this.setHistoryEnabled(event.data);
        } else if (type === 'selection-bar' && event.type === 'mandatory-wishes') {
            this.setMandatoryWishesEnabled(event.data);
        } else if (type === 'selection-bar' && event.type === 'doctor-type') {
            this.setDoctorType(event.data);
        } else if (type === 'selection-bar' && event.type === 'imposed') {
            this.setImposedEnabled(event.data);
        } else if (type === 'selection-bar' && event.type === 'imposed-wishes') {
            this.setImposedWishesEnabled(event.data);
        } else if (type === 'selection-bar' && event.type === 'voluntary') {
            this.setVoluntaryEnabled(event.data);
        } else if (type === 'selection-bar' && event.type === 'voluntary-wishes') {
            this.setVoluntaryWishesEnabled(event.data);
        } else if (type === 'user-selection' && event.type === 'selection') {
            this.selectUser(event.data);
        } else if (type === 'user-selection' && event.type === 'lock') {
            this.setColumnsMargin(event.data.offset, event.data.distance);
        } else if (type === 'user-selection' && event.type === 'expand') {
            this.isUserSelectionExpanded = event.data;
        } else if (type === 'user-search' && event.type === 'add-user') {
            this.addUser(event.data);
        }
    }

    get cursorMode() {
        return this._cursorMode;
    }

    get dates() {
        return this._dates;
    }

    get groups() {
        return this._groups;
    }

    get holidays() {
        return this._holidays;
    }

    get period() {
        return this._period;
    }

    get schedule() {
        return this._schedule;
    }

    get activeShifttypes() {
        return this._activeShifttypes;
    }

    get shifttypes() {
        return this._shifttypes;
    }

    get user() {
        return this._user;
    }

    get users() {
        return this._userHandler;
    }

    get hasDistrict() {
        return !!this._district;
    }

    get hasType() {
        return !!this._shifttypeType;
    }

    get isHistoryEnabled() {
        return this._historyEnabled;
    }

    get isImposedEnabled() {
        return this._imposedEnabled;
    }

    get isMandatoryEnabled() {
        return this._mandatoryEnabled;
    }

    get isVoluntaryEnabled() {
        return this._voluntaryEnabled;
    }

    get isLoading() {
        return this._loading;
    }

    set isUserSelectionExpanded(value: boolean) {
        this._userSelectionExpanded = value;
        const offset = value ? window.pageXOffset : 0;
        this.setUserColumnLeft(offset);
    }

    get isUserSelectionExpanded() {
        return this._userSelectionExpanded;
    }

    @HostListener('window:scroll', [])
    onScroll() {
        // Updates header position on horizontal scrolling
        if (!this.isUserSelectionExpanded || !this.userColumn || this._lastPageXOffset === window.pageXOffset) {
            return;
        }
        this._lastPageXOffset = window.pageXOffset;
        this.setUserColumnLeft(this._lastPageXOffset);
    }

    private setCursorMode(mode: 'add' | 'lock' | 'remove') {
        this._cursorMode = mode;
    }

    private setDoctorType(doctorType: number) {
        this._doctorType = doctorType;
        this.getUsers();
    }

    private setAllUsersEnabled(enabled: boolean) {
        this._allUsersEnabled = enabled;
        this.getUsers();
    }

    private setHistoryEnabled(enabled: boolean) {
        this._historyEnabled = enabled;
    }

    private setImposedEnabled(enabled: boolean) {
        this._mandatoryEnabled = false;
        this._voluntaryEnabled = false;
        this._imposedEnabled = enabled;
    }

    private setImposedWishesEnabled(enabled: boolean) {
        this._mandatoryWishesEnabled = false;
        this._voluntaryWishesEnabled = false;
        this._imposedWishesEnabled = enabled;
        this.getUsers();
    }

    private setMandatoryEnabled(enabled: boolean) {
        this._imposedEnabled = false;
        this._voluntaryEnabled = false;
        this._mandatoryEnabled = enabled;
    }

    private setMandatoryWishesEnabled(enabled: boolean) {
        this._imposedWishesEnabled = false;
        this._voluntaryWishesEnabled = false;
        this._mandatoryWishesEnabled = enabled;
        this.getUsers();
    }

    private setVoluntaryEnabled(enabled: boolean) {
        this._imposedEnabled = false;
        this._mandatoryEnabled = false;
        this._voluntaryEnabled = enabled;
    }

    private setVoluntaryWishesEnabled(enabled: boolean) {
        this._imposedWishesEnabled = false;
        this._mandatoryWishesEnabled = false;
        this._voluntaryWishesEnabled = enabled;
        this.getUsers();
    }

    private getUsers() {
        if (!(this._district || this._districtGroup) && !this._shifttypeType) {
            return;
        }
        this._deselections.clear();
        this._user = null;
        let usersObs: Observable<User>;
        if (this._allUsersEnabled) {
            usersObs = this.userService.users(
                this._period.id,
                this._doctorType,
                this._imposedWishesEnabled,
                this._mandatoryWishesEnabled,
                this._voluntaryWishesEnabled,
            );
        } else {
            usersObs = this.userService.users(
                this._period.id,
                this._doctorType,
                this._imposedWishesEnabled,
                this._mandatoryWishesEnabled,
                this._voluntaryWishesEnabled,
                this._district,
                this._districtGroup,
                this._shifttypeType
            );
        }
        this._userHandler.loadUsers(usersObs);
    }

    private getShifts() {
        const handler = new ShiftHandler();
        return new Promise<Map<string, Shift[]>>((resolve, reject) => {
            this.shiftService.list(this._schedule, this._district, this._districtGroup, this._shifttypeType)
                .subscribe(
                    shift => handler.add(shift),
                    error => reject(error),
                    () => resolve(handler.reorder(this._activeShifttypes))
                );
        });
    }

    private getActiveShifttypes() {
        if ((!this._district && !this._districtGroup) || !this._shifttypeType) {
            return [];
        }
        if (this._district) {
            this._groups = null;
            return this.getDistrictShifttypes([this._district], this._shifttypeType);
        } else if (this._districtGroup) {
            const shifttypes = this.getDistrictShifttypes(this._districtGroup.districts, this._shifttypeType);
            this._groups = this.getShifttypeGroups(shifttypes, this._districtGroup.districts);
            return shifttypes;
        }
    }

    private getShifttypeGroups(shifttypes: Shifttype[], districts: District[]) {
        const groups: SchedulingGroup[] = [];
        let lastDistrict = null;
        shifttypes.forEach(s => {
            const district = districts.find(d => d.id === s.district);
            if (!lastDistrict || s.district !== lastDistrict) {
                groups.push(new SchedulingGroup(district.name, 0));
            }
            groups[groups.length - 1].size++;
            lastDistrict = s.district;
        });
        return groups;
    }

    private getDistrictShifttypes(districts: District[], shifttypeType: ShifttypeType) {
        const districtIds = districts.map(d => d.id);
        return this._shifttypes.filter(s => {
            return districtIds.includes(s.district) &&
                (shifttypeType.id === -1 || s.type === shifttypeType.id);
        });
    }

    private selection(district: District, districtGroup: DistrictGroup, type: ShifttypeType) {
        this._district = district;
        this._districtGroup = districtGroup;
        this._shifttypeType = type;
        if (!this._allUsersEnabled) {
            this.getUsers();
        }
        this._activeShifttypes = this.getActiveShifttypes();
        if (this._activeShifttypes.length > 0) {
            this._loading = true;
            this.getShifts().then(dates => {
                this._dates = dates;
                this._loading = false;
            });
        } else {
            this._dates = null;
        }
    }

    private selectUser(user: UserState) {
        this.prepareUser(user)
            .then(preparedUser => this._user = preparedUser);
    }

    private prepareUser(user: UserState) {
        return new Promise<UserState>((resolve, reject) => {
            this.getDeselections(user.data).then(deselections => {
                user.data.deselections = deselections;
                resolve(user);
            });
        });
    }

    private prepareConstraints(constraints: ShifttypeConstraint[]) {
        constraints.forEach(c => {
            let map = this._constraints.get(c.shifttype1);
            if (!map) {
                map = new Map<number, string[]>();
                this._constraints.set(c.shifttype1, map);
            }
            const types = map.get(c.shifttype2) || [];
            types.push(c.type);
            map.set(c.shifttype2, types);
        });
    }

    private getDeselections(user: User) {
        return new Promise<Deselection[]>((resolve, reject) => {
            const cached = this._deselections.get(user.id);
            if (cached) {
                resolve(cached);
            } else {
                const deselections: Deselection[] = [];
                this.deselectionService.list(this._period.id, user).subscribe(
                    deselection => deselections.push(deselection),
                    error => reject(error),
                    () => {
                        this._deselections.set(user.id, deselections);
                        resolve(deselections);
                    }
                );
            }
        });
    }

    private addUser(user: User) {
        this._userHandler.addUser(user);
    }

    private setColumnsMargin(offset: number, distance: number) {
        this.lockOffset = offset;
        this.lockDistance = distance;
        const columns = this.columns.nativeElement as HTMLElement;
        this.renderer.setStyle(columns, 'marginTop', 'calc(-0.75rem + ' + offset + 'px');
    }

    private setUserColumnLeft(left: number) {
        this.renderer.setStyle(this.userColumn.nativeElement, 'left', left + 'px');
    }

}
