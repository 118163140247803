import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { PeriodService, ScheduleService } from '@app/core';
import { DateHelper, DatePick, Period } from '@app/shared';


@Component({
    selector: 'app-period',
    templateUrl: './period.component.html',
    styleUrls: ['./period.component.css']
})
export class PeriodComponent implements OnDestroy, OnInit {

    end: DatePick;
    period: Period;
    start: DatePick;
    dateError = false;
    deleteError = false;
    lockError = false;
    private _mode: 'create' | 'update';
    private _subscriptions = new Subscription();

    constructor(
        private periodService: PeriodService,
        private scheduleService: ScheduleService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(data => {
            this._mode = !!data.period.id ? 'update' : 'create';
            this.period = data.period;
            this.prepareDates();
        }));
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    get mode() {
        return this._mode;
    }

    get isCreateMode() {
        return this._mode === 'create';
    }

    get isEditMode() {
        return this._mode === 'update';
    }

    get canActivate() {
        return !this.period.is_active;
    }

    get canDelete() {
        return !this.period.is_active;
    }

    get canLock() {
        return this.period.is_active && !this.period.is_locked;
    }

    get canUnlock() {
        return this.period.is_active && this.period.is_locked && !this.period.schedule_id;
    }

    get hasError() {
        return this.dateError || this.deleteError || this.lockError;
    }

    save() {
        this.dateError = false;
        this.deleteError = false;
        this.lockError = false;
        this.setDates();
        if (this._mode === 'create') {
            this.create();
        } else if (this._mode === 'update') {
            this.update();
        }
    }

    delete() {
        this.periodService.delete(this.period).subscribe(
            _ => {},
            error => this.fail(error),
            () => this.router.navigate(['/scheduler/period-list'])
        );
    }

    open() {
        this.period.is_active = true;
        this.update();
    }

    lock() {
        this.period.is_locked = true;
        this.update();
    }

    unlock() {
        this.period.is_locked = false;
        this.update();
    }

    private setDates() {
        this.period.start = this.getDateFromPick(this.start);
        this.period.end = this.getDateFromPick(this.end);
    }

    private prepareDates() {
        if (this.period) {
            this.start = DateHelper.getDatePick(this.period.start);
            this.end = DateHelper.getDatePick(this.period.end);
        }
    }

    private getDateFromPick(pick: DatePick) {
        return DateHelper.getDateString(new Date(pick.year, pick.month, pick.day));
    }

    private create() {
        this.period.is_active = false;
        this.periodService.create(this.period).subscribe(
            period => this.success(period),
            error => this.fail(error),
            () => this.router.navigate(['/scheduler/period-list'])
        );
    }

    private update() {
        this.periodService.update(this.period).subscribe(
            period => this.success(period),
            error => this.fail(error)
        );
    }

    private success(period: Period) {
        this.period = period;
    }

    private fail(error: HttpErrorResponse) {
        console.error('Error during period save', error.error);
        const msg = error.error;
        if (msg && msg.non_field_errors) {
            if (msg.non_field_errors[0] === 'Period end must occur after start') {
                this.dateError = true;
            }
        }
        if (msg && msg.is_locked) {
            if (msg.is_locked[0] === 'Period cannot be unlocked after creating schedule.') {
                this.lockError = true;
                this.period.is_locked = true;
            }
        }
        if (Array.isArray(msg) && msg[0] === 'Cannot delete an open period.') {
            this.deleteError = true;
        }
    }

}
