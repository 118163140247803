import { UIState } from '../generics';
import { Deselection, Holiday } from '../models';
import { DateHelper } from './date-helper';


export class DeselectionState extends UIState<Deselection> {

    private _holidays: string[];
    private _fullDay = false;

    constructor(data: Deselection, holidays: Holiday[], css?: string[]) {
        super(data, css);
        this._holidays = holidays.map(h => h.date);
        this._fullDay = this.isWeekend() || this.isHoliday();
        this.updateState();
    }

    get isFullDay() {
        return this._fullDay;
    }

    private isHoliday() {
        return this._holidays.includes(this.data.date);
    }

    private isWeekend() {
        return [0, 6].includes(DateHelper.getDateFromString(this.data.date).getDay());
    }

    private updateState() {
        if (this.isHoliday()) {
            this.addClass('has-text-danger');
        }
    }

}
