import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { PageService, ToastService } from '@app/core';
import { Page } from '@app/shared';


@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit, OnDestroy {

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: false,
        height: '25rem',
        minHeight: '5rem',
        placeholder: 'Indsæt tekst',
        translate: 'no'
    };
    page: Page;
    private _mode: 'create' | 'update';
    private _routeSub: Subscription;

    constructor(
        private pageService: PageService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService
    ) { }

    ngOnInit() {
        this._routeSub = this.route.data.subscribe(
            data => {
                this._mode = !!data.page.id ? 'update' : 'create';
                this.page = data.page;
            }
        );
    }

    ngOnDestroy() {
        if (this._routeSub) {
            this._routeSub.unsubscribe();
        }
    }

    get isCreateMode() {
        return this._mode === 'create';
    }

    get isEditMode() {
        return this._mode === 'update';
    }

    save() {
        if (this._mode === 'create') {
            this.create();
        } else if (this._mode === 'update') {
            this.update();
        }
    }

    private create() {
        this.pageService.create(this.page).subscribe(
            holiday => this.success(holiday),
            error => this.fail(error),
            () => this.router.navigate(['/admin/page/', this.page.id])
        );
    }

    private update() {
        this.pageService.update(this.page).subscribe(
            page => this.success(page),
            error => this.fail(error)
        );
    }

    private success(page: Page) {
        this.page = page;
        this.toast.success('Side gemt');
    }

    private fail(error: any) {
        console.error('Error during holiday save', error);
        this.toast.error('Fejl: Opdatering ikke gemt');
    }

}
