import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { District } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class DistrictService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    list(active?: boolean) {
        const headers = this.authService.authHeaders();
        const qs: string[] = [];
        if (active !== undefined) {
            qs.push('active=' + active.toString());
        }
        let url = '/api/scheduling/district/';
        if (qs.length) {
            url += '?' + qs.join('&');
        }
        return this.http.get<District[]>(url, { headers }).pipe(
                switchMap(response => response)
            );
    }

    get(id: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<District>(
            '/api/scheduling/district/' + id + '/',
            { headers }
        );
    }

    create(district: District) {
        const headers = this.authService.authHeaders();
        return this.http.post<District>(
            '/api/scheduling/district/',
            district,
            { headers }
        );
    }

    update(district: District) {
        const headers = this.authService.authHeaders();
        return this.http.put<District>(
            '/api/scheduling/district/' + district.id + '/',
            district,
            { headers }
        );
    }

    activate(district: District) {
        const headers = this.authService.authHeaders();
        return this.http.put<District>(
            '/api/scheduling/district/' + district.id + '/activate/',
            null,
            { headers }
        );
    }

    deactivate(district: District) {
        const headers = this.authService.authHeaders();
        return this.http.put<District>(
            '/api/scheduling/district/' + district.id + '/deactivate/',
            null,
            { headers }
        );
    }
}
