import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';

import { Emit, UIState, User, UserHandler, UserState } from '@app/shared';


@Component({
    selector: 'app-user-selection',
    templateUrl: './user-selection.component.html',
    styleUrls: ['./user-selection.component.css']
})
export class UserSelectionComponent implements OnChanges, OnInit, AfterViewChecked {

    @Input() users: UserState[] = [];
    @Output() event = new EventEmitter<Emit>();
    @ViewChild('expander') expander: ElementRef;
    @ViewChild('wrapper') wrapper: ElementRef;
    isExpanded = false;
    private current: UserState;
    private _currentElement: HTMLElement;
    private _height = 0;
    private _lastPageYOffset = 0;
    private _locked = false;
    private _lockDistance = 0;
    private _lockOffset = 0;
    private _marginLeft = 0;
    private _marginRight = 0;
    private _scrollTimer: NodeJS.Timer;
    private _userUpdate = false;

    constructor(private renderer: Renderer2) { }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) {
        this.isExpanded = false;
        this._locked = false;
        this._lockOffset = 0;
        this.marginLeft = 0;
        this.emitLock(0);
        this.emitExpand(false);
        this.setWrapperTopOffset(0);
        this.setExpanderTopOffset();
        if (changes.users.currentValue.length) {
            this._userUpdate = true;
        }
    }

    ngAfterViewChecked() {
        this.checkIfExpandable();
    }

    get marginLeft() {
        return this._marginLeft;
    }

    set marginLeft(value: number) {
        this._marginLeft = value;
        this._marginRight = value * -1;
    }

    get marginRight() {
        return this._marginRight;
    }

    get isCollapsed() {
        return this._locked || !this.isExpanded;
    }

    toggleExpand() {
        this.isExpanded = !this.isExpanded;
        this.emitExpand(this.isExpanded);
        let wrapperOffset = 0;
        if (this.isExpanded) {
            this._lockOffset = this.getLockOffset();
            this.marginLeft = window.pageXOffset;
            window.scrollTo(window.scrollX, 0);
            this.onScroll();
        } else {
            wrapperOffset = this.getElementOffset(this._currentElement);
            const scrollY = this._locked ? window.scrollY - this._lockOffset + 1 : 0;
            window.scrollTo(window.scrollX, scrollY);
            this._lockDistance = 0;
            this.marginLeft = 0;
            this.emitLock(0);
        }
        this.setWrapperTopOffset(wrapperOffset);
        this.setExpanderTopOffset();
    }

    select(state: UserState, element: HTMLElement) {
        if (this.current && this.current.data === state.data) {
            return;
        }
        if (this.current) {
            this.current.removeClass('has-background-primary');
        }
        state.addClass('has-background-primary');
        this.current = state;
        this._currentElement = element;
        if (!this.isExpanded) {
            this.setWrapperTopOffset(this.getElementOffset(this._currentElement));
        }
        this.emitSelection(state);
    }

    @HostListener('window:scroll')
    onScroll() {
        if (!this.isExpanded) {
            return;
        }
        let wrapperOffset = 0;
        this._lastPageYOffset = window.pageYOffset;
        if (this._lastPageYOffset >= this._lockOffset) {
            wrapperOffset = this._lockOffset * -1;
            this._locked = true;
            this._lockDistance = 0;
            this.marginLeft = 0;
            this.emitLock(this._lockOffset);
            this.emitExpand(false);
        } else {
            this._locked = false;
            this._lockDistance = this._lockOffset - this._lastPageYOffset;
            this.marginLeft = window.pageXOffset;
            this.emitLock(0, this._lockDistance);
            this.emitExpand(true);
        }
        this.setWrapperTopOffset(wrapperOffset);
        this.setExpanderTopOffset();
        this._scrollTimer = null;
    }

    private emitSelection(state: UserState) {
        this.event.emit({ type: 'selection', data: state });
    }

    private emitLock(offset: number, distance = 0) {
        this.event.emit({ type: 'lock', data: { offset, distance }});
    }

    private emitExpand(expanded: boolean) {
        this.event.emit({ type: 'expand', data: expanded });
    }

    private getElementOffset(element: HTMLElement) {
        if (!element) {
            return 0;
        }
        const rowHeight = element.getBoundingClientRect().height;
        const parent = element.parentNode as HTMLElement;
        return (element.offsetTop - parent.offsetTop) * -1;
    }

    private setWrapperTopOffset(margin: number) {
        const wrapper = this.wrapper.nativeElement as HTMLElement;
        this.renderer.setStyle(this.wrapper.nativeElement, 'marginTop', margin + 'px');
    }

    private checkIfExpandable() {
        if (!this._userUpdate) {
            return;
        }
        const wrapper = this.wrapper.nativeElement as HTMLElement;
        const height = wrapper.getBoundingClientRect().height;
        const userElement = wrapper.children.item(0) as HTMLElement;
        if (userElement) {
            const rowHeight = userElement.getBoundingClientRect().height;
            const expandable = height > rowHeight + 1;
            this.renderer.setStyle(this.expander.nativeElement, 'visibility', expandable ? 'visible' : 'hidden');
            this._userUpdate = false;
            this.reselectUser();
        }
    }

    private getLockOffset() {
        const wrapper = this.wrapper.nativeElement as HTMLElement;
        const itemIndex = wrapper.children.length - 1;
        const element = wrapper.children.item(itemIndex) as HTMLElement;
        return element.offsetTop - wrapper.offsetTop;
    }

    private setExpanderTopOffset() {
        if (this.isExpanded && !this._locked) {
            this.renderer.setStyle(this.expander.nativeElement, 'top', 'calc(8.75rem + ' + this._lockOffset + 'px)');
            this.renderer.setStyle(this.expander.nativeElement, 'position', 'absolute');
        } else {
            this.renderer.removeStyle(this.expander.nativeElement, 'top');
            this.renderer.setStyle(this.expander.nativeElement, 'position', 'fixed');
        }
    }

    private reselectUser() {
        // Reselect current user when users reload if available
        // If not remove current reference
        // Should not be called before UI elements have been checked
        if (this._userUpdate || !this.current) {
            return;
        }
        const index = this.users.findIndex(u => u.data.id === this.current.data.id);
        if (index > -1) {
            const user = this.users[index];
            const element = (this.wrapper.nativeElement as HTMLElement).children[index];
            this.select(user, element as HTMLElement);
        } else {
            this.current = null;
            this._currentElement = null;
        }
    }

}
