import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { UserService } from '../services/user.service';
import { Shift } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class UserShiftUpcomingListResolverService {

    constructor(private userService: UserService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const user = route.paramMap.get('id');
        return new Promise<Shift[]>((resolve, reject) => {
            const shifts: Shift[] = [];
            this.userService.upcomingShifts(+user).subscribe(
                shift => shifts.push(shift),
                error => reject(error),
                () => resolve(shifts)
            );
        });
    }
}
