import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PageService } from '../services/page.service';
import { Page } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class PageResolverService {

    constructor(
        private pageService: PageService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const id = route.paramMap.get('id');

        if (id === null) {
            return of(new Page());
        }

        return this.pageService.get(+id).pipe(
            map(item => {
                if (item) {
                    return item;
                }
                this.router.navigate(['/admin/page-list']);
                return null;
            })
        );
    }

}
