import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ShiftService } from '@app/core';
import {
    DateHelper,
    District,
    Settings,
    Shift,
    Shifttype,
    ShifttypeType
} from '@app/shared';


@Component({
    selector: 'app-my-shift-list',
    templateUrl: './my-shift-list.component.html',
    styleUrls: ['./my-shift-list.component.css']
})
export class MyShiftListComponent implements OnInit, OnChanges {

    @Input() districts: District[];
    @Input() settings: Settings;
    @Input() shifttypes: Shifttype[];
    @Input() shifttypeTypes: ShifttypeType[];
    @Input() type = 'upcoming';
    isLoading = false;
    shifts: Shift[] = [];
    private dateLimit = new Date();
    private _districtsMap = new Map<number, District>();
    private _shifttypesMap = new Map<number, Shifttype>();
    private _shifttypeTypesMap = new Map<number, ShifttypeType>();
    private _cssMap = new Map<number, string[]>();

    constructor(private shiftService: ShiftService) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.districts) {
            this.buildDistrictMap();
        }

        if (changes.shifttypes) {
            this.buildShifttypeMap();
        }

        if (changes.shifttypeTypes) {
            this.buildShifttypeTypeMap();
        }

        if (changes.type) {
            this.getShifts(changes.type.currentValue);
        }

        if (changes.settings) {
            this.dateLimit.setDate(this.dateLimit.getDate() + this.settings.shift_available_warning);
        }
    }

    get hasShifts() {
        return this.shifts.length > 0;
    }

    getShiftCss(shift: Shift) {
        return this._cssMap.get(shift.id);
    }

    district(shifttypeId: number) {
        return this.getDistrict(shifttypeId).name;
    }

    shifttypeTitle(id: number) {
        return this.getShifttype(id).name;
    }

    shifttypeTypeTitle(id: number) {
        const type = this.getShifttype(id).type;
        return this.getShifttypeType(type).name;
    }

    shiftTime(shift: Shift) {
        const shifttype = this.getShifttype(shift.shifttype);
        const date = DateHelper.getDateFromString(shift.date);
        if (DateHelper.isWeekend(date) && this.hasWeekendTime(shifttype)) {
            return [this.getTimeHours(shifttype.weekend_start),
            this.getTimeHours(shifttype.weekend_end)].join('-');
        }
        return [this.getTimeHours(shifttype.start),
        this.getTimeHours(shifttype.end)].join('-');
    }

    shifttypeComment(id: number) {
        // Tooltip only added to dom if filled out
        const shifttype = this.getShifttype(id);
        if (!!shifttype.comment.length) {
            return shifttype.comment;
        }
        return null;
    }

    private hasWeekendTime(shifttype: Shifttype) {
        return !!shifttype.weekend_start && !!shifttype.weekend_end;
    }

    private getShifts(type: string) {
        this.isLoading = true;
        this.clearShifts();
        this.shiftService.myshifts(type).subscribe(
            shift => this.addShift(shift),
            error => console.log('Error loading shifts', error),
            () => this.isLoading = false
        );
    }

    private addShift(shift: Shift) {
        this._cssMap.set(shift.id, this.cssState(shift));
        this.shifts.push(shift);
    }

    private getTimeHours(time: string) {
        return time.substr(0, 2);
    }

    private getDistrict(shifttypeId: number) {
        return this._districtsMap.get(this.getShifttype(shifttypeId).district);
    }

    private getShifttype(id: number) {
        return this._shifttypesMap.get(id);
    }

    private getShifttypeType(id: number) {
        return this._shifttypeTypesMap.get(id);
    }

    private buildDistrictMap() {
        this.districts.forEach(d => this._districtsMap.set(d.id, d));
    }

    private buildShifttypeMap() {
        this.shifttypes.forEach(s => this._shifttypesMap.set(s.id, s));
    }

    private buildShifttypeTypeMap() {
        this.shifttypeTypes.forEach(s => this._shifttypeTypesMap.set(s.id, s));
    }

    private clearShifts() {
        this._cssMap.clear();
        this.shifts.length = 0;
    }

    private hasDateLimit(date: string) {
        return new Date(date).getTime() < this.dateLimit.getTime();
    }

    private cssState(shift: Shift) {
        if (shift.is_for_sale) {
            if (this.hasDateLimit(shift.date)) {
                return ['has-background-lightred'];
            } else {
                return ['has-background-success'];
            }
        } else if (shift.is_mandatory) {
            return ['is-mandatory'];
        }
        return [];
    }

}
