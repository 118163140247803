import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProfileService } from '@app/core';
import { Profile } from '@app/shared';


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

    profile: Profile;

    constructor(
        private profileService: ProfileService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.route.data.subscribe(
            (data: { profile: Profile }) => this.profile = data.profile
        );
    }

    save() {
        this.update();
    }

    private update() {
        this.profileService.update(this.profile).subscribe(
            profile => console.log('updated', profile)
        );
    }

}
