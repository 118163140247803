import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ShifttypeTypeService, ToastService } from '@app/core';
import { ShifttypeType } from '@app/shared';


@Component({
    selector: 'app-shifttype-type',
    templateUrl: './shifttype-type.component.html',
    styleUrls: ['./shifttype-type.component.css']
})
export class ShifttypeTypeComponent implements OnInit {

    shifttypeType: ShifttypeType;
    private _mode: 'create' | 'update';

    constructor(
        private shifttypeTypeService: ShifttypeTypeService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService
    ) { }

    ngOnInit() {
        this.route.data.subscribe(
            (data: { shifttypeType: ShifttypeType }) => {
                this._mode = !!data.shifttypeType.id ? 'update' : 'create';
                this.shifttypeType = data.shifttypeType;
            }
        );
    }

    get mode() {
        return this._mode;
    }

    get isCreateMode() {
        return this._mode === 'create';
    }

    get isEditMode() {
        return this._mode === 'update';
    }

    save() {
        if (this._mode === 'create') {
            this.create();
        } else if (this._mode === 'update') {
            this.update();
        }
    }

    private create() {
        this.shifttypeTypeService.create(this.shifttypeType).subscribe(
            shifttypeType => this.success(shifttypeType),
            error => this.fail(error),
            () => this.router.navigate(['/admin/shifttype-type/', this.shifttypeType.id])
        );
    }

    private update() {
        this.shifttypeTypeService.update(this.shifttypeType).subscribe(
            shifttypeType => this.success(shifttypeType),
            error => this.fail(error)
        );
    }

    private success(shifttypeType: ShifttypeType) {
        this.shifttypeType = shifttypeType;
        this.toast.success('Art gemt');
    }

    private fail(error: any) {
        console.error('Error during user save', error);
        this.toast.error('Fejl: Opdatering ikke gemt');
    }

}
