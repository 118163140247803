import { District } from './district';
import { ShifttypeType } from './shifttype-type';


export class Shifttype {
    id: number;
    name: string;
    start: string;
    end: string;
    weekend_start: string;
    weekend_end: string;
    drive: boolean;
    timeperiod: 'day' | 'evening' | 'night';
    daytype: 'fullweek' | 'weekend' | 'allday';
    type: number;
    district: number;
    comment: string;
    is_active: boolean;

    district_object?: District;
    type_object?: ShifttypeType;
    timeperiod_title?: string;
    daytype_title?: string;
    time?: string;
}
