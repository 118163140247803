import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    LoggedInGuard,
    DistrictListResolverService
} from '@app/core';
import { OnboardingComponent } from './onboarding/onboarding.component';


const routes: Routes = [
    {
        path: 'onboarding',
        component: OnboardingComponent,
        canActivate: [LoggedInGuard],
        resolve: {
            districts: DistrictListResolverService
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OnboardingRoutingModule { }
