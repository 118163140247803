import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { LogoutAllComponent } from './logout-all/logout-all.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';


const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'logoutall',
        component: LogoutAllComponent
    },
    {
        path: 'password-reset/:uid/:token',
        component: PasswordResetComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
