import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowsAltH,
    faAward,
    faCalendar,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarTimes,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronUp,
    faClock,
    faCog,
    faEraser,
    faExclamation,
    faExclamationCircle,
    faFile,
    faHistory,
    faInfoCircle,
    faKey,
    faLightbulb,
    faList,
    faLock,
    faMap,
    faMoon,
    faMousePointer,
    faPen,
    faPlus,
    faSearch,
    faSearchPlus,
    faSignOutAlt,
    faSpinner,
    faTimes,
    faTrashAlt,
    faUser,
    faUserCheck,
    faUserClock,
    faUserSlash,
    faUserPlus,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import {
    DeselectionMonthComponent,
    DropdownComponent,
    TitleComponent
} from './components';
import { LinebreaksPipe } from './pipes/linebreaks.pipe';

const icons = [
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowsAltH,
    faAward,
    faCalendar,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarTimes,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronUp,
    faClock,
    faCog,
    faEraser,
    faExclamation,
    faExclamationCircle,
    faFile,
    faHistory,
    faInfoCircle,
    faKey,
    faLightbulb,
    faList,
    faLock,
    faMap,
    faMoon,
    faMousePointer,
    faPen,
    faPlus,
    faSearch,
    faSearchPlus,
    faSignOutAlt,
    faSpinner,
    faTimes,
    faTrashAlt,
    faUser,
    faUserCheck,
    faUserClock,
    faUserSlash,
    faUserPlus,
    faUsers
];

library.add(...icons);


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        AngularEditorModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        DeselectionMonthComponent,
        DropdownComponent,
        LinebreaksPipe,
        TitleComponent,
        AngularEditorModule
    ],
    declarations: [
        DeselectionMonthComponent,
        DropdownComponent,
        LinebreaksPipe,
        TitleComponent
    ]
})
export class SharedModule { }
