import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';

import { PlanningService } from '@app/core';
import { DateHelper, Period, Shift, Shifttype, UserState } from '@app/shared';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit, OnChanges {

    @Input() period: Period;
    @Input() shifttypes: Shifttype[];
    @Input() user: UserState;
    private _commentEditMode = false;
    private _formattedDates = new Map<string, string>();
    private _showUserComment = false;
    private _shifttypesMap = new Map<number, Shifttype>();

    constructor(
        private planningService: PlanningService,
    ) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.user) {
            this._commentEditMode = false;
            this._showUserComment = false;
        }

        if (changes.shifttypes) {
            this.buildShifttypes();
        }
    }

    get commentEditMode() {
        return this._commentEditMode;
    }

    get showUserComment() {
        return this._showUserComment;
    }

    cancelEditComment() {
        this._commentEditMode = false;
    }

    editComment() {
        if (this.user.data.wish.admin_comment === '') {
            this.user.data.wish.admin_comment = this.user.data.wish.comment;
        }
        this._commentEditMode = true;
    }

    saveComment() {
        const adminComment = this.user.data.wish.admin_comment;
        this.planningService.updateComment(
            this.period.id, this.user.data.id, adminComment).subscribe(
                response => {
                    this.user.data.wish.admin_comment = response.admin_comment;
                },
                error => console.error('Error updating comment', error),
                () => this._commentEditMode = false
            );
    }

    toggleUserComment() {
        this._showUserComment = !this._showUserComment;
    }

    shifttypeTitle(id: number) {
        return this.getShifttype(id).name;
    }

    toggleShiftsFinished() {
        const finished = this.user.shiftsFinished;
        this.planningService.shiftsFinished(
            this.period.id, this.user.data.id, !finished).subscribe(
                response => {
                    this.user.shiftsFinished = response.admin_shifts_finished;
                },
                error => console.error('Error updating finished shifts', error)
            );
    }

    formatDate(date: string) {
        if (!this._formattedDates.has(date)) {
            let str = formatDate(date, 'EEEEEE d/M', 'da');
            const week = DateHelper.getWeek(date);
            str += ' (' + week + ')';
            this._formattedDates.set(date, str);
        }
        return this._formattedDates.get(date);
    }

    shiftCss(shift: Shift) {
        const css: string[] = [];
        if (this.user.hasShiftConflict(shift)) {
            css.push('has-background-warning');
        }
        if (shift.is_imposed) {
            css.push('is-imposed-shift');
        }
        if (shift.is_mandatory) {
            css.push('is-mandatory-shift');
        }
        if (shift.is_voluntary) {
            css.push('is-voluntary-shift');
        }
        return css;
    }

    shifttypeTime(id: number) {
        const shifttype = this.getShifttype(id);
        return [this.getTimeHours(shifttype.start),
            this.getTimeHours(shifttype.end)].join('-');
    }

    private getShifttype(id: number) {
        return this._shifttypesMap.get(id);
    }

    private getTimeHours(time: string) {
        return time.substr(0, 2);
    }

    private buildShifttypes() {
        this.shifttypes.forEach(s => this._shifttypesMap.set(s.id, s));
    }

}
