import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';

import { AuthService, ShiftService, ShiftHistoryService, UserService } from '@app/core';
import {
    DateHelper,
    District,
    HistoryHandler,
    Settings,
    Shift,
    ShiftHandler,
    ShiftHistory,
    Shifttype,
    ShifttypeType,
    UIState,
    User
} from '@app/shared';


@Component({
    selector: 'app-shift-schedule',
    templateUrl: './shift-schedule.component.html',
    styleUrls: ['./shift-schedule.component.css']
})
export class ShiftScheduleComponent implements OnInit, OnDestroy {

    districts: District[];
    history: HistoryHandler;
    holidays: string[];
    initDistrict: number;
    initType: number;
    month: number;
    shifttypeTypes: ShifttypeType[];
    year: number;
    years: number[];
    private _activeShifttypes: Shifttype[];
    private _dates: Map<string, Shift[]>;
    private dateLimit = new Date();
    private _days: UIState<string>[] = [];
    private _formattedDates = new Map<string, string>();
    private _isAdminUser = false;
    private _loading = false;
    private _routeSub: Subscription;
    private _selectedDistrict: District;
    private _selectedType: ShifttypeType;
    private _settings: Settings;
    private _shifttypes: Shifttype[];
    private _states: Map<string, UIState<Shift>[]>;

    constructor(
        private authService: AuthService,
        private shiftService: ShiftService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        shiftHistoryService: ShiftHistoryService
    ) {
        this.history = new HistoryHandler(shiftHistoryService, true, true, false);
    }

    ngOnInit() {
        this._routeSub = this.route.data.subscribe(
            data => {
                this._settings = data.settings;
                this.dateLimit.setDate(this.dateLimit.getDate() + this._settings.shift_available_warning);

                this._shifttypes = data.shifttypes;
                this.districts = data.districts;
                this.shifttypeTypes = data.shifttypeTypes;
                if (data.holidays) {
                    this.holidays = data.holidays.map(h => h.date);
                }

                this.districts.push({id: 10000, name: 'Alle'});

                this._isAdminUser = this.authService.user.is_staff;

                this.initDates();
                this.setNow();
                this.initSelection();
                this.update();
            },
            error => console.error(error)
        );
    }

    ngOnDestroy() {
        this._routeSub.unsubscribe();
    }

    get hasShifts() {
        return this._days.length;
    }

    get isLoading() {
        return this._loading;
    }

    get days() {
        return this._days;
    }

    get shifttypes() {
        return this._activeShifttypes;
    }

    listener(type: string, event: any) {
        if (type === 'district') {
            this.selectDistrict(event);
        } else if (type === 'type') {
            this.selectType(event);
        }
    }

    update() {
        if (!!this._selectedDistrict && !!this._selectedType) {
            this._activeShifttypes = this.getActiveShifttypes();
            this.updateNavigation();
            if (this._activeShifttypes.length > 0) {
                this._loading = true;
                this.getShifts()
                    .then(dates => {
                        this._dates = dates;
                        this._days = this.buildDays();
                        this._states = this.buildState();
                        this._loading = false;
                    });
            } else {
                this._dates = null;
                this._days = [];
                this._states = null;
            }
        }
    }

    shifttypeClasses(shifttype: Shifttype) {
        if (!!shifttype.comment.length) {
            return 'tooltip is-tooltip-bottom';
        }
        return null;
    }

    editShift(state: UIState<Shift>) {
        if (this._isAdminUser && state.data) {
            this.router.navigate(['/shift', state.data.id]);
        }
    }

    formatDate(date: string) {
        if (!this._formattedDates.has(date)) {
            let str = formatDate(date, 'EEEEEE d/M', 'da');
            const week = DateHelper.getWeek(date);
            str += ' (' + week + ')';
            this._formattedDates.set(date, str);
        }
        return this._formattedDates.get(date);
    }

    shifttypeTime(shifttype: Shifttype) {
        return [this.getTimeHours(shifttype.start),
            this.getTimeHours(shifttype.end)].join('-');
    }

    hasWeekendTime(shifttype: Shifttype) {
        return !!shifttype.weekend_start && !!shifttype.weekend_end;
    }

    shifttypeWeekendTime(shifttype: Shifttype) {
        return '(' + [this.getTimeHours(shifttype.weekend_start),
            this.getTimeHours(shifttype.weekend_end)].join('-') + ')';
    }

    shifttypeComment(shifttype: Shifttype) {
        // Tooltip only added to dom if filled out
        if (!!shifttype.comment.length) {
            return shifttype.comment;
        }
        return null;
    }

    shifts(date: string) {
        return this._states.get(date);
    }

    private updateNavigation() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                district: this._selectedDistrict.id,
                type: this._selectedType.id,
                month: this.month,
                year: this.year
            },
            queryParamsHandling: 'merge',
            skipLocationChange: false
        });
    }

    private initSelection() {
        this.route.queryParamMap.subscribe(params => {
            if (this.districts.length) {
                if (params.has('district')) {
                    this._selectedDistrict = this.districts.find(d => d.id === +params.get('district'));
                } else {
                    this._selectedDistrict = this.districts[0];
                }
                this.initDistrict = this._selectedDistrict.id;
            }

            if (this.shifttypeTypes.length) {
                if (params.has('type')) {
                    this._selectedType = this.shifttypeTypes.find(s => s.id === +params.get('type'));
                } else {
                    this._selectedType = this.shifttypeTypes[0];
                }
                this.initType = this._selectedType.id;
            }

            if (params.has('month')) {
                this.month = +params.get('month');
            }

            if (params.has('year')) {
                this.year = +params.get('year');
            }
        });
    }

    private selectDistrict(district: District) {
        this._selectedDistrict = district;
        this.update();
    }

    private selectType(type: ShifttypeType) {
        this._selectedType = type;
        this.update();
    }

    private initDates() {
        this.years = this.getYears();
    }

    private getYears() {
        const currentYear = new Date().getFullYear();
        const years: number[] = [];
        for (let i = 2020; i < currentYear + 2; i++) {
            years.push(i);
        }
        return years;
    }

    private setNow() {
        const date = new Date();
        this.month = date.getMonth() + 1;
        this.year = date.getFullYear();
    }

    private getActiveShifttypes() {
        if (!this._selectedDistrict || !this._selectedType) {
            return [];
        }
        return this._shifttypes.filter(s => {
            return (s.district === this._selectedDistrict.id ||
                this._selectedDistrict.id === 10000) &&
                s.type === this._selectedType.id;
        });
    }

    private getShifts() {
        const handler = new ShiftHandler();
        const availableShifttypes = new Map<number, boolean>();
        return new Promise<Map<string, Shift[]>>((resolve, reject) => {
            this.shiftService.schedule(this._selectedDistrict, this._selectedType, this.month, this.year)
                .subscribe(
                    shift => {
                        availableShifttypes.set(shift.shifttype, true);
                        handler.add(shift);
                    },
                    error => reject(error),
                    () => {
                        for (let i = this._activeShifttypes.length - 1; i >= 0; i--) {
                            if (!availableShifttypes.get(this._activeShifttypes[i].id)) {
                                this._activeShifttypes.splice(i, 1);
                            }
                        }
                        resolve(handler.reorder(this._activeShifttypes));
                    }
                );
        });
    }

    private getTimeHours(time: string) {
        return time.substr(0, 2);
    }

    private dayCssState(date: string) {
        return this.holidays.includes(date) ? ['has-text-danger'] : [];
    }

    private buildDays() {
        return Array.from(this._dates.keys())
            .map(day => new UIState<string>(day, this.dayCssState(day)));
    }

    private buildState() {
        const map = new Map<string, UIState<Shift>[]>();
        if (this._dates) {
            const keys = this._dates.keys();
            let result = keys.next();
            while (!result.done) {
                map.set(result.value, this.stateArray(this._dates.get(result.value)));
                result = keys.next();
            }
        }
        return map;
    }

    private stateArray(shifts: Shift[]) {
        return shifts.map(shift => new UIState<Shift>(shift, this.cssState(shift)));
    }

    private cssState(shift: Shift) {
        if (!shift) {
            return ['has-background-grey-light'];
        } else if (shift.is_locked) {
            return ['is-locked'];
        } else if (shift.is_for_sale) {
            if (this.hasDateLimit(shift.date)) {
                return ['has-background-lightred'];
            } else {
                return ['has-background-success'];
            }
        } else if (!shift.employee_no) {
            if (this.hasDateLimit(shift.date)) {
                return ['has-background-danger'];
            } else {
                return ['has-background-warning'];
            }
        } else if (shift.is_mandatory) {
            return ['is-mandatory-shift'];
        }
        return [];
    }

    private hasDateLimit(date: string) {
        return new Date(date).getTime() < this.dateLimit.getTime();
    }

}
