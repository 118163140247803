import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PlanningService, ToastService } from '@app/core';
import { Period, PlanningWish, User } from '@app/shared';


@Component({
    selector: 'app-wish-check',
    templateUrl: './wish-check.component.html',
    styleUrls: ['./wish-check.component.css']
})
export class WishCheckComponent implements OnInit {

    period: Period;
    user: User;
    wish: PlanningWish;

    constructor(
        private planningService: PlanningService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService,
    ) { }

    ngOnInit() {
        this.route.data.subscribe(
            data => {
                this.period = data.period;
                this.wish = data.wish;
                this.user = data.user;
            }
        );
    }

    get listLink() {
        return '/planning/' + this.period.id + '/wish-check-list';
    }

    get deselectionLink() {
        return '/planning/' + this.period.id + '/deselection-check/' + this.user.id;
    }

    get username() {
        return [this.user.first_name, this.user.last_name, '(' + this.wish.employee_no + ')'].join(' ');
    }

    get isStudent() {
        const type = this.user.profile.doctor_type;
        return type === 4 || type === 5 || type === 6;
    }

    save() {
        this.planningService.wishSave(this.wish).subscribe(
            wish => this.success(wish),
            error => this.fail(error)
        );
    }

    private success(wish: PlanningWish) {
        this.wish = wish;
        this.toast.success('Ønsker gemt');
        this.router.navigate(['planning', this.period.id, 'wish-check-list']);
    }

    private fail(error: any) {
        const msg = error.error;
        console.error('Error during save planning wish', error);
        this.toast.error('Fejl: Ønsker ikke gemt');
    }

}
