import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { Auth, Credential, PasswordResetConfirm, User } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _cookieName = 'token';
    private _loggedIn = false;
    private _redirectUrl: string;
    private _token: string = null;
    private _user: User;

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private router: Router
    ) { }

    get isLoggedIn() {
        return this._loggedIn;
    }

    get redirectUrl() {
        return this._redirectUrl;
    }

    get user() {
        return this._user;
    }

    get isOnboarded() {
        return !this.isFirstLogin && this.hasDistrict;
    }

    get isFirstLogin() {
        return this._user.profile.first_login;
    }

    get hasDistrict() {
        // District is only required for doctors
        return this._user && this.user.profile &&
        (this._user.profile.user_type !== 1 ||
        this._user.profile.district !== null);
    }

    isAdminUser() {
        if (this._loggedIn) {
            return of(this._user && this._user.is_staff);
        }
        return this.checkLogin()
            .pipe(map(loggedIn => {
                if (loggedIn) {
                    return this._user && this._user.is_staff;
                }
                return false;
            }));
    }

    checkLogin(url: string = null): Observable<boolean> {
        if (this.isLoggedIn) {
            return of(true);
        }
        this._redirectUrl = url;
        if (this.hasCookie()) {
            return this.cookieLogin();
        }
        this.router.navigate(['/login']);
        return of(false);
    }

    login(credential: Credential) {
        const headers = this.loginHeaders();
        return this.http.post<Auth>('/api/auth/login/', credential, { headers })
            .pipe(
                map(auth => this.handleLogin(auth, credential.save || false)),
                catchError(error => this.loginError(error))
            );
    }

    cookieLogin() {
        return this.profile(this.getCookie())
            .pipe(
                map(() => true),
                catchError(() => {
                    this.router.navigate(['/login']);
                    return of(false);
                })
            );
    }

    logout() {
        const headers = this.authHeaders();
        // Successful logout returns status 204
        // Failed returns status 401
        return this.http.post('/api/auth/logout/', null, { headers })
            .pipe(
                map(() => this.handleLogout()),
                catchError(error => this.logoutError(error))
            );
    }

    logoutAll() {
        const headers = this.authHeaders();
        return this.http.post('/api/auth/logoutall/', null, { headers});
    }

    passwordResetConfirm(passwordReset: PasswordResetConfirm) {
        const headers = this.loginHeaders();
        return this.http.post('/api/user/password/confirm/', passwordReset, { headers });
    }

    updateProfile() {
        return this.profile(this._token);
    }

    profile(token?: string) {
        const headers = this.authHeaders(token);
        return this.http.get<User>('/api/user/me/', { headers })
            .pipe(
                map(profile => this.handleLogin({ user: profile, token }))
            );
    }

    authHeaders(token?: string) {
        if (!token) {
            token = this._token;
        }
        return new HttpHeaders()
            .set('Authorization', 'Token ' + token);
    }

    private loginHeaders() {
        return new HttpHeaders()
            .set('Content-Type', 'application/json');
    }

    private handleLogin(auth: Auth, save = false) {
        this._token = auth.token;
        this._loggedIn = true;
        this._user = auth.user;
        if (save || this._user.is_staff) {
            let days = 5;
            if (this._user.is_staff) {
                days = 30;
            }
            this.setCookie(auth.token, days);
        }
        return auth.user;
    }

    private handleLogout() {
        this._loggedIn = false;
        this._user = null;
        this.deleteCookie();
        return true;
    }

    private loginError(error: HttpErrorResponse) {
        return throwError('Login fejlet');
    }

    private logoutError(error: HttpErrorResponse) {
        this._loggedIn = false;
        return throwError('Logout failed');
    }

    private hasCookie() {
        return this.cookieService.check(this._cookieName);
    }

    private getCookie() {
        return this.cookieService.get(this._cookieName);
    }

    private setCookie(token: string, days = 5) {
        this.cookieService.set(this._cookieName, token, days);
    }

    private deleteCookie() {
        this.cookieService.delete(this._cookieName);
    }

}
