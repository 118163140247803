import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { AuthService, ShiftService } from '@app/core';
import {
    DateHelper,
    District,
    Settings,
    Shift,
    ShiftTeamStats,
    Shifttype,
    ShifttypeType
} from '@app/shared';


@Component({
    selector: 'app-team-list',
    templateUrl: './team-list.component.html',
    styleUrls: ['./team-list.component.css']
})
export class TeamListComponent implements OnInit, OnChanges {

    @Input() date: string;
    @Input() districts: District[];
    @Input() expandedList: boolean;
    @Input() settings: Settings;
    @Input() shifttypes: Shifttype[];
    @Input() shifttypeType: ShifttypeType;
    hasShifts = false;
    isLoading = false;
    stats = new ShiftTeamStats();
    timePeriods = [
        { key: 'day', title: 'Dagsvagter' },
        { key: 'evening', title: 'Aftenvagter'},
        { key: 'night', title: 'Nattevagter'}
    ];
    timePeriodShifts = new Map<string, Shift[]>();
    private dateLimit = new Date();
    private isAdminUser = false;
    private _districtsMap = new Map<number, District>();
    private _shifttypesMap = new Map<number, Shifttype>();
    private _cssMap = new Map<number, string[]>();

    constructor(
        private authService: AuthService,
        private shiftService: ShiftService) {
            this.isAdminUser = this.authService.user.is_staff;
        }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.districts) {
            this.buildDistrictMap();
        }

        if (changes.shifttypes) {
            this.buildShifttypeMap();
        }

        if (changes.date || changes.expandedList || changes.shifttypeType) {
            this.getShifts(this.date, this.shifttypeType.id);
        }

        if (changes.settings) {
            this.dateLimit.setDate(this.dateLimit.getDate() + this.settings.shift_available_warning);
        }
    }

    hasTimePeriod(timePeriod: string) {
        return this.timePeriodShifts.has(timePeriod) &&
            this.timePeriodShifts.get(timePeriod).length > 0;
    }

    getTimePeriodShifts(timePeriod: string) {
        if (this.timePeriodShifts.has(timePeriod)) {
            return this.timePeriodShifts.get(timePeriod);
        }
    }

    getShiftCss(shift: Shift) {
        return this._cssMap.get(shift.id);
    }

    district(shifttypeId: number) {
        return this.getDistrict(shifttypeId).name;
    }

    shifttypeTitle(id: number) {
        return this.getShifttype(id).name;
    }

    shiftTime(shift: Shift) {
        const shifttype = this.getShifttype(shift.shifttype);
        const date = DateHelper.getDateFromString(shift.date);
        if (DateHelper.isWeekend(date) && this.hasWeekendTime(shifttype)) {
            return [this.getTimeHours(shifttype.weekend_start),
            this.getTimeHours(shifttype.weekend_end)].join('-');
        }
        return [this.getTimeHours(shifttype.start),
        this.getTimeHours(shifttype.end)].join('-');
    }

    private hasWeekendTime(shifttype: Shifttype) {
        return !!shifttype.weekend_start && !!shifttype.weekend_end;
    }

    private getShifts(date: string, type: number) {
        this.isLoading = true;
        this.hasShifts = false;
        const onlyTaxi = type === 1000;
        if (onlyTaxi) {
            type = null;
        }
        this.clearShifts();
        // TODO: Probably merge these calls and have one return
        // Could just be a refactor and merge on backend
        if (this.isAdmin) {
            this.shiftService.teamStats(date).subscribe(
                stats => this.stats = stats
            );
        }
        this.shiftService.team(date, type).subscribe(
            shift => {
                if (!onlyTaxi || (onlyTaxi && shift.taxi)) {
                    this.addShift(shift);
                }
            },
            error => console.log('Error loading shifts', error),
            () => {
                this.sortTimePeriods();
                this.isLoading = false;
            }
        );
    }

    get isAdmin() {
        return this.isAdminUser;
    }

    private addShift(shift: Shift) {
        this.hasShifts = true;
        const shifttype = this.getShifttype(shift.shifttype);
        if (!this.timePeriodShifts.has(shifttype.timeperiod)) {
            this.timePeriodShifts.set(shifttype.timeperiod, []);
        }
        const shifts = this.timePeriodShifts.get(shifttype.timeperiod);
        this._cssMap.set(shift.id, this.cssState(shift));
        shifts.push(shift);
    }

    private sortTimePeriods() {
        this.timePeriodShifts.forEach(t => {
            return t.sort((a, b) => {
                return this.shifttypeTitle(a.shifttype).localeCompare(
                    this.shifttypeTitle(b.shifttype),
                    undefined,
                    {numeric: true, sensitivity: 'base'}
                );
            });
        });
    }

    private getTimeHours(time: string) {
        return time.substr(0, 2);
    }

    private getDistrict(shifttypeId: number) {
        return this._districtsMap.get(this.getShifttype(shifttypeId).district);
    }

    private getShifttype(id: number) {
        return this._shifttypesMap.get(id);
    }

    private buildDistrictMap() {
        this.districts.forEach(d => this._districtsMap.set(d.id, d));
    }

    private buildShifttypeMap() {
        this.shifttypes.forEach(s => this._shifttypesMap.set(s.id, s));
    }

    private clearShifts() {
        this._cssMap.clear();
        Array.from(this.timePeriodShifts.values()).forEach(i => i.length = 0);
    }

    private hasDateLimit(date: string) {
        return new Date(date).getTime() < this.dateLimit.getTime();
    }

    private cssState(shift: Shift) {
        if (shift.is_for_sale) {
            if (this.hasDateLimit(shift.date)) {
                return ['has-background-lightred'];
            } else {
                return ['has-background-success'];
            }
        } else if (shift.is_mandatory) {
            return ['is-mandatory'];
        }
        return [];
    }

}
