import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Shifttype } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ShifttypeService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    list(ordering?: string, query?: string, active?: boolean) {
        const headers = this.authService.authHeaders();
        const qs: string[] = [];
        if (query) {
            qs.push('search=' + query);
        }
        if (ordering) {
            qs.push('ordering=' + ordering);
        }
        if (active !== undefined) {
            qs.push('active=' + active.toString());
        }
        let url = '/api/scheduling/shifttype/';
        if (qs.length) {
            url += '?' + qs.join('&');
        }
        return this.http.get<Shifttype[]>(url, { headers }).pipe(
                switchMap(response => response)
            );
    }

    get(id: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<Shifttype>(
            '/api/scheduling/shifttype/' + id + '/',
            { headers }
        );
    }

    create(shifttype: Shifttype) {
        const headers = this.authService.authHeaders();
        return this.http.post<Shifttype>(
            '/api/scheduling/shifttype/',
            shifttype,
            { headers }
        );
    }

    update(shifttype: Shifttype) {
        const headers = this.authService.authHeaders();
        return this.http.put<Shifttype>(
            '/api/scheduling/shifttype/' + shifttype.id + '/',
            shifttype,
            { headers }
        );
    }

    activate(shifttype: Shifttype) {
        const headers = this.authService.authHeaders();
        return this.http.put<Shifttype>(
            '/api/scheduling/shifttype/' + shifttype.id + '/activate/',
            null,
            { headers }
        );
    }

    deactivate(shifttype: Shifttype) {
        const headers = this.authService.authHeaders();
        return this.http.put<Shifttype>(
            '/api/scheduling/shifttype/' + shifttype.id + '/deactivate/',
            null,
            { headers }
        );
    }
}
