import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService, ShiftService } from '@app/core';
import {
    DateHelper,
    District,
    DistrictGroup,
    Selection,
    Settings,
    Shift,
    Shifttype,
    ShifttypeType
} from '@app/shared';

@Component({
    selector: 'app-warning',
    templateUrl: './warning.component.html',
    styleUrls: ['./warning.component.css']
})
export class WarningComponent implements OnInit, OnDestroy {

    districts: District[];
    districtSelections: Selection[] = [];
    selected: string;
    shifts: Shift[];
    private _cssMap = new Map<number, string[]>();
    private dateLimit = new Date();
    private districtGroups: DistrictGroup[];
    private _districtsMap = new Map<number, District>();
    private _hasShifts = false;
    private isAdminUser = false;
    private loading = false;
    private selectedDistrict: District;
    private selectedDistrictGroup: DistrictGroup;
    private _settings: Settings;
    private shifttypes: Shifttype[];
    private _shifttypesMap = new Map<number, Shifttype>();
    private _shifttypeTypes: ShifttypeType[];
    private _shifttypeTypesMap = new Map<number, ShifttypeType>();
    private _subscriptions = new Subscription();

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private shiftService: ShiftService
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(
            data => {
                this.districts = data.districts;
                this.districtGroups = data.districtGroups;
                this._settings = data.settings;
                this.shifttypes = data.shifttypes;
                this._shifttypeTypes = data.shifttypeTypes;

                this.dateLimit.setDate(this.dateLimit.getDate() + this._settings.shift_available_warning);
                this.isAdminUser = this.authService.user.is_staff;

                this.setDistrictSelections();
                this.initSelection();

                this.buildDistrictMap();
                this.buildShifttypeMap();
                this.buildShifttypeTypeMap();

                this.getShifts();
            }
        ));
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    shifttypeTitle(id: number) {
        return this.getShifttype(id).name;
    }

    shifttypeTypeTitle(id: number) {
        const type = this.getShifttype(id).type;
        return this.getShifttypeType(type).name;
    }

    shiftTime(shift: Shift) {
        const shifttype = this.getShifttype(shift.shifttype);
        const date = DateHelper.getDateFromString(shift.date);
        if (DateHelper.isWeekend(date) && this.hasWeekendTime(shifttype)) {
            return [this.getTimeHours(shifttype.weekend_start),
            this.getTimeHours(shifttype.weekend_end)].join('-');
        }
        return [this.getTimeHours(shifttype.start),
        this.getTimeHours(shifttype.end)].join('-');
    }

    getShiftCss(shift: Shift) {
        return this._cssMap.get(shift.id);
    }

    district(shifttypeId: number) {
        return this.getDistrict(shifttypeId).name;
    }

    goToShift(shift: Shift) {
        if (this.isAdmin) {
            this.router.navigate(
                ['shift', shift.id],
                { queryParams: {
                    prev: 'warning',
                    district: this.selectedDistrict ? this.selectedDistrict.id : null,
                    group: this.selectedDistrictGroup ? this.selectedDistrictGroup.id : null
                }
            });
        }
    }

    selectDistrict(selection: Selection) {
        this.selected = selection.id;
        const split = selection.id.split('-');
        const type = split[0];
        const id = +split[1];
        if (type === 'all') {
            this.selectedDistrict = null;
            this.selectedDistrictGroup = null;
        } else if (type === 'district') {
            this.selectedDistrict = this.districts.find(d => d.id === id);
            this.selectedDistrictGroup = null;
        } else if (type === 'group') {
            this.selectedDistrict = null;
            this.selectedDistrictGroup = this.districtGroups.find(g => g.id === id);
        }
        this.updateNavigation();
        this.getShifts();
    }

    get hasShifts() {
        return this._hasShifts;
    }

    get isAdmin() {
        return this.isAdminUser;
    }

    get isLoading() {
        return this.loading;
    }

    private hasWeekendTime(shifttype: Shifttype) {
        return !!shifttype.weekend_start && !!shifttype.weekend_end;
    }

    private getTimeHours(time: string) {
        return time.substr(0, 2);
    }

    private getDistrict(shifttypeId: number) {
        return this._districtsMap.get(this.getShifttype(shifttypeId).district);
    }

    private getShifttype(id: number) {
        return this._shifttypesMap.get(id);
    }

    private getShifttypeType(id: number) {
        return this._shifttypeTypesMap.get(id);
    }

    private buildDistrictMap() {
        this.districts.forEach(d => this._districtsMap.set(d.id, d));
    }

    private buildShifttypeMap() {
        this.shifttypes.forEach(s => this._shifttypesMap.set(s.id, s));
    }

    private buildShifttypeTypeMap() {
        this._shifttypeTypes.forEach(s => this._shifttypeTypesMap.set(s.id, s));
    }

    private setDistrictSelections() {
        const districts = this.districts.map(d => {
            return {
                id: 'district-' + d.id,
                name: d.name
            };
        });
        districts.unshift({
            id: 'all-0',
            name: 'Alle'
        });
        const groups = this.districtGroups.map(g => {
            return {
                id: 'group-' + g.id,
                name: g.name
            };
        });
        this.districtSelections = districts.concat(groups);
    }

    private getShifts() {
        this.loading = true;
        const shifts: Shift[] = [];
        this.shiftService.warningList(
            this.selectedDistrict,
            this.selectedDistrictGroup
        ).subscribe(
            shift => shifts.push(shift),
            error => console.log('Error updating warning shift list', error),
            () => {
                this.shifts = shifts;
                this._hasShifts = this.shifts.length > 0;
                this.buildCssMap();
                this.loading = false;
            }
        );
    }

    private initSelection() {
        this.route.queryParamMap.subscribe(params => {
            if (params.has('district')) {
                this.selected = 'district-' + params.get('district');
                this.selectedDistrict = this.districts.find(d => d.id === +params.get('district'));
            } else if (params.has('group')) {
                this.selected = 'group-' + params.get('group');
                this.selectedDistrictGroup = this.districtGroups.find(g => g.id === +params.get('group'));
            } else {
                this.selected = 'all-0';
            }
        });
    }

    private updateNavigation() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                district: this.selectedDistrict ? this.selectedDistrict.id : null,
                group: this.selectedDistrictGroup ? this.selectedDistrictGroup.id : null
            },
            queryParamsHandling: 'merge',
            skipLocationChange: false
        });
    }

    private buildCssMap() {
        this._cssMap.clear();
        this.shifts.forEach(s => this._cssMap.set(s.id, this.cssState(s)));
    }

    private hasDateLimit(date: string) {
        return new Date(date).getTime() < this.dateLimit.getTime();
    }

    private cssState(shift: Shift) {
        if (shift.is_for_sale && this.hasDateLimit(shift.date)) {
            return ['has-background-lightred'];
        }
        return ['has-background-danger'];
    }

}
