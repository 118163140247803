import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { ShifttypeConstraint } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ShifttypeConstraintService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    list() {
        const headers = this.authService.authHeaders();
        const url = '/api/scheduling/shifttypeconstraint/';
        return this.http.get<ShifttypeConstraint[]>(url, { headers }).pipe(
            switchMap(response => response)
        );
    }
}
