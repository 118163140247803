import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ShifttypeTypeService } from '@app/core';
import { ShifttypeType } from '@app/shared';


@Component({
    selector: 'app-shifttype-type-list',
    templateUrl: './shifttype-type-list.component.html',
    styleUrls: ['./shifttype-type-list.component.css']
})
export class ShifttypeTypeListComponent implements OnInit {

    private _loading = false;
    private _shifttypeTypes: ShifttypeType[] = [];

    constructor(
        private shifttypeTypeService: ShifttypeTypeService,
        private router: Router
    ) { }

    get shifttypeTypes() {
        return this._shifttypeTypes;
    }

    get isLoading() {
        return this._loading;
    }

    ngOnInit() {
        this.getUsers();
    }

    editShifttypeType(shifttypeType: ShifttypeType) {
        this.router.navigate(['/admin/shifttype-type', shifttypeType.id]);
    }

    private getUsers() {
        this._loading = true;
        this.shifttypeTypeService.list().subscribe(
            shifttypeType => this.addShifttypeType(shifttypeType),
            error => console.error('Error getting user list'),
            () => {
                this._loading = false;
            }
        );
    }

    private addShifttypeType(shifttypeType: ShifttypeType) {
        this._shifttypeTypes.push(shifttypeType);
    }

}
