import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { ShifttypeType } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ShifttypeTypeService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    list() {
        const headers = this.authService.authHeaders();
        return this.http.get<ShifttypeType[]>(
                '/api/scheduling/shifttype-type/',
                { headers }
            ).pipe(
                switchMap(response => response)
            );
    }

    get(id: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<ShifttypeType>(
            '/api/scheduling/shifttype-type/' + id + '/',
            { headers }
        );
    }

    create(shifttypeType: ShifttypeType) {
        const headers = this.authService.authHeaders();
        return this.http.post<ShifttypeType>(
            '/api/scheduling/shifttype-type/',
            shifttypeType,
            { headers }
        );
    }

    update(shifttypeType: ShifttypeType) {
        const headers = this.authService.authHeaders();
        return this.http.put<ShifttypeType>(
            '/api/scheduling/shifttype-type/' + shifttypeType.id + '/',
            shifttypeType,
            { headers }
        );
    }
}
