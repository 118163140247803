import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PeriodService } from '../services/period.service';
import { Period } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class PeriodResolverService implements Resolve<Period> {

    constructor(
        private periodService: PeriodService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Period> {
        const id = route.paramMap.get('id') || route.paramMap.get('period');

        if (id === null) {
            return of(new Period());
        }

        return this.periodService.get(+id).pipe(
            map(period => {
                if (period) {
                    return period;
                }
                this.router.navigate(['/scheduler/period-list']);
                return null;
            })
        );
    }
}
