import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DistrictService } from '@app/core';
import { District } from '@app/shared';


@Component({
    selector: 'app-district-list',
    templateUrl: './district-list.component.html',
    styleUrls: ['./district-list.component.css']
})
export class DistrictListComponent implements OnInit {

    private _showActive = true;
    private _loading = false;
    private _districts: District[] = [];

    constructor(
        private districtService: DistrictService,
        private router: Router
    ) { }

    get districts() {
        return this._districts;
    }

    get isLoading() {
        return this._loading;
    }

    get showActive() {
        return this._showActive;
    }

    ngOnInit() {
        this.getDistricts();
    }

    editDistrict(district: District) {
        this.router.navigate(['/admin/district', district.id]);
    }

    toggleActive() {
        this._showActive = !this._showActive;
        this.getDistricts();
    }

    private getDistricts() {
        this._loading = true;
        this.clear();
        this.districtService.list(this._showActive).subscribe(
            district => this.addDistrict(district),
            error => console.error('Error getting district list'),
            () => {
                this._loading = false;
            }
        );
    }

    private addDistrict(district: District) {
        this._districts.push(district);
    }

    private clear() {
        this._districts.length = 0;
    }

}
