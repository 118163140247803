import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Shift, ShiftHistory } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ShiftHistoryService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    listArray(shift: Shift, planning?: boolean) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/shifthistory/' + shift.id + '/';
        if (planning) {
            url += '?planning=1';
        }
        return this.http.get<ShiftHistory[]>(url, { headers });
    }

    list(shift: Shift, planning?: boolean) {
        return this.listArray(shift, planning).pipe(
            switchMap(response => response));
    }

    listById(shiftId: number, planning?: boolean) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/shifthistory/' + shiftId + '/';
        if (planning) {
            url += '&planning=1';
        }
        return this.http.get<ShiftHistory[]>(url, { headers }).pipe(
            switchMap(response => response)
        );
    }

}
