import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Page } from '@app/shared';

@Injectable({
    providedIn: 'root'
})
export class PageService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    list(onlyPublished = false) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/page/';
        if (onlyPublished) {
            url += 'published/';
        }
        return this.http.get<Page[]>(
                url,
                { headers }
            ).pipe(
                switchMap(response => response)
            );
    }

    get(id: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<Page>(
            '/api/scheduling/page/' + id + '/',
            { headers }
        );
    }

    create(page: Page) {
        const headers = this.authService.authHeaders();
        return this.http.post<Page>(
            '/api/scheduling/page/',
            page,
            { headers }
        );
    }

    update(page: Page) {
        const headers = this.authService.authHeaders();
        return this.http.put<Page>(
            '/api/scheduling/page/' + page.id + '/',
            page,
            { headers }
        );
    }

    destroy(page: Page) {
        const headers = this.authService.authHeaders();
        return this.http.delete<Page>(
            '/api/scheduling/page/' + page.id + '/',
            { headers }
        );
    }
}
