import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import {
    Email,
    Period,
    PlanningDeselection,
    PlanningShiftStats,
    PlanningShiftsFinished,
    PlanningStats,
    PlanningUpdateComment,
    PlanningWish,
    Profile
} from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class PlanningService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    deselectionByUser(period: number, user: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<PlanningDeselection[]>(
            '/api/scheduling/planning/' + period + '/deselection/' + user + '/',
            { headers }
        );
    }

    deselectionSave(period: number, user: number, deselections: PlanningDeselection[]) {
        const requests: Observable<PlanningDeselection[]>[] = [];
        const creates = deselections.filter(d => !d.id);
        const updates = deselections.filter(d => !!d.id);
        if (creates.length) {
            requests.push(this.deselectionCreateList(period, creates));
        }
        if (updates.length) {
            requests.push(this.deselectionUpdateList(period, user, updates));
        }
        return forkJoin(requests);
    }

    noShiftsList(period: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<Profile[]>(
            '/api/scheduling/planning/' + period + '/noshifts/',
            { headers }
        ).pipe(
            switchMap(response => response)
        );
    }

    noWishesList(period: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<Profile[]>(
            '/api/scheduling/planning/' + period + '/nowishes/',
            { headers }
        ).pipe(
            switchMap(response => response)
        );
    }

    stats(period: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<PlanningStats>(
            '/api/scheduling/planning/' + period + '/stats/',
            { headers }
        );
    }

    warning(period: number, email: Email) {
        const headers = this.authService.authHeaders();
        return this.http.post<Email>(
            '/api/scheduling/planning/' + period + '/warning/',
            email,
            { headers }
        );
    }

    wish(id: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<PlanningWish>(
            '/api/scheduling/planning/wish/' + id + '/',
            { headers }
        );
    }

    wishByUser(period: number, user: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<PlanningWish>(
            '/api/scheduling/planning/' + period + '/wish/' + user + '/',
            { headers }
        );
    }

    shiftList(period: number, query?: string) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/planning/' + period + '/shifts/';
        if (query) {
            url += '?search=' + query;
        }
        return this.http.get<PlanningShiftStats[]>(url, { headers }).pipe(
            switchMap(response => response)
        );
    }

    wishList(period: number, query?: string) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/planning/' + period + '/wishes/';
        if (query) {
            url += '?search=' + query;
        }
        return this.http.get<PlanningWish[]>(url, { headers }).pipe(
            switchMap(response => response),
            map(wish => {
                if (wish.evening_visitation_override !== null) {
                    wish.evening_visitation = wish.evening_visitation_override;
                }
                if (wish.evening_drive_override !== null) {
                    wish.evening_drive = wish.evening_drive_override;
                }
                return wish;
            })
        );
    }

    wishSave(wish: PlanningWish) {
        if ('' + wish.evening_visitation_override === '') {
            wish.evening_visitation_override = null;
        }
        if ('' + wish.evening_drive_override === '') {
            wish.evening_drive_override = null;
        }
        const headers = this.authService.authHeaders();
        return this.http.put<PlanningWish>(
            '/api/scheduling/planning/wish/' + wish.id + '/',
            wish,
            { headers }
        );
    }

    shiftsFinished(period: number, user: number, finished: boolean) {
        const shiftsFinished: PlanningShiftsFinished = {
            admin_shifts_finished: finished
        };
        const headers = this.authService.authHeaders();
        return this.http.put<PlanningShiftsFinished>(
            '/api/scheduling/planning/' + period + '/shiftsfinished/' + user + '/',
            shiftsFinished,
            { headers }
        );
    }

    updateComment(period: number, user: number, comment: string) {
        const updateComment: PlanningUpdateComment = {
            admin_comment: comment
        };
        const headers = this.authService.authHeaders();
        return this.http.put<PlanningUpdateComment>(
            '/api/scheduling/planning/' + period + '/updatecomment/' + user + '/',
            updateComment,
            { headers }
        );
    }

    private deselectionCreateList(period: number, deselections: PlanningDeselection[]) {
        const headers = this.authService.authHeaders();
        return this.http.post<PlanningDeselection[]>(
            '/api/scheduling/planning/' + period + '/deselection/create/',
            deselections,
            { headers }
        );
    }

    private deselectionUpdateList(period: number, user: number, deselections: PlanningDeselection[]) {
        const headers = this.authService.authHeaders();
        return this.http.put<PlanningDeselection[]>(
            '/api/scheduling/planning/' + period + '/deselection/' + user + '/update/',
            deselections,
            { headers }
        );
    }
}
