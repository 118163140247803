import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthService, PageService } from '@app/core';
import { Page, User } from '@app/shared';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked {

    menuIsActive = false;
    pages: Page[] = [];
    private pagesLoaded = false;
    private subscriptions = new Subscription();

    constructor(
        private authService: AuthService,
        private pageService: PageService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.subscriptions.add(
            this.router.events.pipe(
                filter(e => e instanceof NavigationEnd)
            ).subscribe(
                () => {
                    this.menuIsActive = false;
                }
            )
        );
    }

    ngAfterViewChecked() {
        if (!this.pagesLoaded && this.authService.isLoggedIn) {
            this.pagesLoaded = true;
            this.pageService.list(true).subscribe(
                page => this.pages.push(page)
            );
        }
    }

    get isFirstLogin() {
        return this.authService.isFirstLogin;
    }

    get isLoggedIn() {
        return this.authService.isLoggedIn;
    }

    get isAdminUser() {
        return this.authService.user && this.authService.user.is_staff;
    }

    get user() {
        return this.authService.user;
    }

    // This is for toggling menu on mobile
    toggleMenu() {
        this.menuIsActive = !this.menuIsActive;
    }
}
