import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Holiday } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class HolidayService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    list() {
        const headers = this.authService.authHeaders();
        return this.http.get<Holiday[]>(
            '/api/scheduling/holiday/',
            { headers }
        ).pipe(
            switchMap(response => response)
        );
    }

    get(id: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<Holiday>(
            '/api/scheduling/holiday/' + id + '/',
            { headers }
        );
    }

    create(holiday: Holiday) {
        const headers = this.authService.authHeaders();
        return this.http.post<Holiday>(
            '/api/scheduling/holiday/',
            holiday,
            { headers }
        );
    }

    update(holiday: Holiday) {
        const headers = this.authService.authHeaders();
        return this.http.put<Holiday>(
            '/api/scheduling/holiday/' + holiday.id + '/',
            holiday,
            { headers }
        );
    }

    delete(holiday: Holiday) {
        const headers = this.authService.authHeaders();
        return this.http.delete(
            '/api/scheduling/holiday/' + holiday.id + '/',
            { headers }
        );
    }
}
