import { ExtendedProfile } from './extended-profile';


export class Profile {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    profile: ExtendedProfile;
}
