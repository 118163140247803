import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { ShiftRoutingModule } from './shift-routing.module';
import { MyShiftListComponent } from './my-shift-list/my-shift-list.component';
import { MyShiftsComponent } from './my-shifts/my-shifts.component';
import { ShiftComponent } from './shift/shift.component';
import { ShiftScheduleComponent } from './shift-schedule/shift-schedule.component';
import { TeamListComponent } from './team-list/team-list.component';
import { TeamComponent } from './team/team.component';
import { ForSaleComponent } from './for-sale/for-sale.component';
import { WarningComponent } from './warning/warning.component';
import { UserShiftListComponent } from './user-shift-list/user-shift-list.component';


@NgModule({
    imports: [
        SharedModule,
        ShiftRoutingModule
    ],
    declarations: [
        TeamListComponent,
        TeamComponent,
        MyShiftListComponent,
        MyShiftsComponent,
        ShiftComponent,
        ShiftScheduleComponent,
        ForSaleComponent,
        WarningComponent,
        UserShiftListComponent
    ],
    exports: [
        MyShiftListComponent
    ]
})
export class ShiftModule { }
