import { User } from './user';


export class PasswordReset {
    email: string;

    constructor(user: User) {
        this.email = user.email;
    }
}
