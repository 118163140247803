import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { SettingsService } from '../services/settings.service';
import { Settings } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class SettingsResolverService {

    constructor(
        private settingsService: SettingsService
    ) { }

    resolve() {
        return this.settingsService.get();
    }
}
