import { Deselection } from './deselection';


export class PlanningDeselection {
    id: number;
    user: number;
    period: number;
    date: string;
    day = false;
    evening = false;
    night = false;

    load(deselection: Deselection, user: number) {
        this.id = deselection.id;
        this.user = user;
        this.period = deselection.period;
        this.date = deselection.date;
        this.day = deselection.day;
        this.evening = deselection.evening;
        this.night = deselection.night;
    }
}
