import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/core';


@Component({
  selector: 'app-logout-all',
  template: ''
})
export class LogoutAllComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
      this.authService.logoutAll();
      this.router.navigate(['login']);
  }

}
