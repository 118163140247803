import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    AdminGuard,
    HolidayListResolverService,
    PeriodResolverService,
    PeriodListAllResolverService,
    PlanningNoShiftsListResolverService,
    PlanningNoWishesListResolverService,
    PlanningShiftStatsResolverService,
    PlanningStatsResolverService,
    PlanningUserDeselectionResolverService,
    PlanningUserWishResolverService,
    PlanningWishListResolverService,
    SettingsResolverService,
    UserResolverService,
} from '@app/core';
import { DeselectionCheckComponent } from './deselection-check/deselection-check.component';
import { NoShiftsListComponent } from './no-shifts-list/no-shifts-list.component';
import { NoSubmissionWarningComponent } from './no-submission-warning/no-submission-warning.component';
import { NoWishesListComponent } from './no-wishes-list/no-wishes-list.component';
import { PlanningPeriodComponent } from './planning-period/planning-period.component';
import { ShiftStatsComponent } from './shift-stats/shift-stats.component';
import { ShiftPeriodListComponent } from './shift-period-list/shift-period-list.component';
import { WishCheckComponent } from './wish-check/wish-check.component';
import { WishCheckListComponent } from './wish-check-list/wish-check-list.component';
import { WishPeriodListComponent } from './wish-period-list/wish-period-list.component';


const routes: Routes = [
    {
        path: 'planning',
        canActivate: [AdminGuard],
        children: [
            {
                path: '',
                canActivateChild: [AdminGuard],
                children: [
                    {
                        path: 'wish-period-list',
                        component: WishPeriodListComponent,
                        resolve: {
                            periods: PeriodListAllResolverService,
                        }
                    },
                    {
                        path: 'shift-period-list',
                        component: ShiftPeriodListComponent,
                        resolve: {
                            periods: PeriodListAllResolverService,
                        }
                    },
                    {
                        path: ':period',
                        component: PlanningPeriodComponent,
                        resolve: {
                            period: PeriodResolverService,
                            stats: PlanningStatsResolverService,
                        }
                    },
                    {
                        path: ':period/wish-check-list',
                        component: WishCheckListComponent,
                        resolve: {
                            period: PeriodResolverService,
                            wishes: PlanningWishListResolverService,
                        }
                    },
                    {
                        path: ':period/no-shifts-list',
                        component: NoShiftsListComponent,
                        resolve: {
                            period: PeriodResolverService,
                            users: PlanningNoShiftsListResolverService,
                        }
                    },
                    {
                        path: ':period/warning',
                        component: NoSubmissionWarningComponent,
                        resolve: {
                            period: PeriodResolverService,
                        }
                    },
                    {
                        path: ':period/no-wishes-list',
                        component: NoWishesListComponent,
                        resolve: {
                            period: PeriodResolverService,
                            users: PlanningNoWishesListResolverService,
                        }
                    },
                    {
                        path: ':period/wish-check/:user',
                        component: WishCheckComponent,
                        resolve: {
                            period: PeriodResolverService,
                            wish: PlanningUserWishResolverService,
                            user: UserResolverService,
                        }
                    },
                    {
                        path: ':period/deselection-check/:user',
                        component: DeselectionCheckComponent,
                        resolve: {
                            deselections: PlanningUserDeselectionResolverService,
                            holidays: HolidayListResolverService,
                            period: PeriodResolverService,
                            settings: SettingsResolverService,
                            user: UserResolverService,
                        }
                    },
                    {
                        path: ':period/shift-stats',
                        component: ShiftStatsComponent,
                        resolve: {
                            period: PeriodResolverService,
                            stats: PlanningShiftStatsResolverService
                        }
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class PlanningRoutingModule { }
