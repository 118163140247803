import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DistrictService, ToastService } from '@app/core';
import { District } from '@app/shared';


@Component({
    selector: 'app-district',
    templateUrl: './district.component.html',
    styleUrls: ['./district.component.css']
})
export class DistrictComponent implements OnInit {

    district: District;
    errorUniqueTitle = false;
    private _mode: 'create' | 'update';

    constructor(
        private districtService: DistrictService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService
    ) { }

    ngOnInit() {
        this.route.data.subscribe(
            (data: { district: District }) => {
                this._mode = !!data.district.id ? 'update' : 'create';
                this.district = data.district;
            }
        );
    }

    get mode() {
        return this._mode;
    }

    get isCreateMode() {
        return this._mode === 'create';
    }

    get isEditMode() {
        return this._mode === 'update';
    }

    save() {
        if (this._mode === 'create') {
            this.create();
        } else if (this._mode === 'update') {
            this.update();
        }
    }

    activate() {
        this.districtService.activate(this.district).subscribe(
            district => this.success(district, 'Distrikt aktiveret'),
            error => {
                console.error('Error during district activation', error);
                this.toast.error('Fejl: Distrikt ikke aktiveret');
            }
        );
    }

    deactivate() {
        this.districtService.deactivate(this.district).subscribe(
            district => this.success(district, 'Distrikt deaktiveret'),
            error => {
                console.error('Error during district deactivation', error);
                this.toast.error('Fejl: Distrikt ikke deaktiveret');
            }
        );
    }

    private create() {
        this.districtService.create(this.district).subscribe(
            district => this.success(district),
            error => this.fail(error),
            () => this.router.navigate(['/admin/district-list'])
        );
    }

    private update() {
        this.districtService.update(this.district).subscribe(
            district => this.success(district),
            error => this.fail(error)
        );
    }

    private success(district: District, toastMessage = 'Distrikt gemt') {
        this.district = district;
        this.errorUniqueTitle = false;
        this.toast.success(toastMessage);
    }

    private fail(error: any) {
        const msg = error.error;
        if (msg && msg.name) {
            if (msg.name[0] === 'district with this name already exists.') {
                this.errorUniqueTitle = true;
            }
        }
        console.error('Error during district save', error);
        this.toast.error('Fejl: Opdatering ikke gemt');
    }

}
