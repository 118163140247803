import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { DistrictGroupService } from '../services/district-group.service';
import { DistrictGroup } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class DistrictGroupListResolverService implements Resolve<DistrictGroup[]> {

    constructor(private districtGroupService: DistrictGroupService) { }

    resolve() {
        const districtGroups: DistrictGroup[] = [];
        return new Promise<DistrictGroup[]>((resolve, reject) => {
            this.districtGroupService.list().subscribe(
                districtGroup => districtGroups.push(districtGroup),
                error => reject(error),
                () => resolve(districtGroups)
            );
        });
    }
}
