import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { HolidayService } from '../services/holiday.service';
import { Holiday } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class HolidayListResolverService implements Resolve<Holiday[]> {

    constructor(private holidayService: HolidayService) { }

    resolve() {
        const holidays: Holiday[] = [];
        return new Promise<Holiday[]>((resolve, reject) => {
            this.holidayService.list().subscribe(
                holiday => holidays.push(holiday),
                error => reject(error),
                () => resolve(holidays)
            );
        });
    }
}
