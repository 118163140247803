import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ScheduleService } from '@app/core';
import { Schedule } from '@app/shared';


@Component({
    selector: 'app-schedule-list',
    templateUrl: './schedule-list.component.html',
    styleUrls: ['./schedule-list.component.css']
})
export class ScheduleListComponent implements OnInit {

    private _loading = false;
    private _publishedSchedules: Schedule[] = [];
    private _unpublishedSchedules: Schedule[] = [];

    constructor(
        private scheduleService: ScheduleService,
        private router: Router
    ) { }

    get publishedSchedules() {
        return this._publishedSchedules;
    }

    get unpublishedSchedules() {
        return this._unpublishedSchedules;
    }

    get isLoading() {
        return this._loading;
    }

    ngOnInit() {
        this.getSchedules();
    }

    edit(schedule: Schedule) {
        this.router.navigate(['/scheduler/schedule', schedule.id]);
    }

    stats(schedule: Schedule, event: Event) {
        event.stopPropagation();
        this.router.navigate(['/scheduler/schedule-stats', schedule.id]);
    }

    publish(schedule: Schedule, event: Event) {
        event.stopPropagation();
        this.router.navigate(['/scheduler/publish', schedule.id]);
    }

    unpublish(schedule: Schedule, event: Event) {
        event.stopPropagation();
        this.scheduleService.unpublish(schedule).subscribe(
            unpublishedSchedule => {
                this.removePublishedSchedule(unpublishedSchedule);
                this.addSchedule(unpublishedSchedule);
            },
            error => console.log('Error during unpublish', error)
        );
    }

    private getSchedules() {
        this._loading = true;
        this.scheduleService.list().subscribe(
            schedule => this.addSchedule(schedule),
            error => console.error('Error getting schedule list'),
            () => {
                this._loading = false;
            }
        );
    }

    private addSchedule(schedule: Schedule) {
        let schedules: Schedule[];
        if (schedule.is_published) {
            schedules = this._publishedSchedules;
        } else {
            schedules = this._unpublishedSchedules;
        }
        schedules.push(schedule);
    }

    private removePublishedSchedule(schedule: Schedule) {
        const index = this._publishedSchedules.findIndex(s => s.id === schedule.id);
        this._publishedSchedules.splice(index, 1);
    }

}
