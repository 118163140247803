import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { AdminRoutingModule } from './admin-routing.module';
import { DistrictListComponent } from './district-list/district-list.component';
import { DistrictComponent } from './district/district.component';
import { DistrictGroupListComponent } from './district-group-list/district-group-list.component';
import { DistrictGroupComponent } from './district-group/district-group.component';
import { HolidayListComponent } from './holiday-list/holiday-list.component';
import { HolidayComponent } from './holiday/holiday.component';
import { PageListComponent } from './page-list/page-list.component';
import { PageComponent } from './page/page.component';
import { ShifttypeListComponent } from './shifttype-list/shifttype-list.component';
import { ShifttypeTypeListComponent } from './shifttype-type-list/shifttype-type-list.component';
import { ShifttypeComponent } from './shifttype/shifttype.component';
import { ShifttypeTypeComponent } from './shifttype-type/shifttype-type.component';
import { SettingsComponent } from './settings/settings.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserComponent } from './user/user.component';
import { UserSetPasswordComponent } from './user-set-password/user-set-password.component';
import { UserDeleteComponent } from './user-delete/user-delete.component';
import { EmployeeNoTableComponent } from './employee-no-table/employee-no-table.component';
import { UserStatsComponent } from './user-stats/user-stats.component';


@NgModule({
    imports: [
        SharedModule,
        AdminRoutingModule
    ],
    exports: [
        UserListComponent
    ],
    declarations: [
        DistrictListComponent,
        DistrictComponent,
        DistrictGroupListComponent,
        DistrictGroupComponent,
        HolidayListComponent,
        HolidayComponent,
        PageListComponent,
        PageComponent,
        ShifttypeListComponent,
        ShifttypeTypeListComponent,
        ShifttypeComponent,
        ShifttypeTypeComponent,
        SettingsComponent,
        UserListComponent,
        UserComponent,
        UserSetPasswordComponent,
        UserDeleteComponent,
        EmployeeNoTableComponent,
        UserStatsComponent,
    ]
})
export class AdminModule { }
