import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { DistrictGroupService, ToastService } from '@app/core';
import { District, DistrictGroup } from '@app/shared';


@Component({
    selector: 'app-district-group',
    templateUrl: './district-group.component.html',
    styleUrls: ['./district-group.component.css']
})
export class DistrictGroupComponent implements OnInit, OnDestroy {

    group: DistrictGroup;
    districts: District[] = [];
    private _mode: 'create' | 'update';
    private _routeSub: Subscription;
    private _selections = new Map<number, boolean>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private districtGroupService: DistrictGroupService,
        private toast: ToastService
    ) { }

    ngOnInit() {
        this._routeSub = this.route.data.subscribe(
            data => {
                this._mode = !!data.group.id ? 'update' : 'create';
                this.group = data.group;
                this.districts = data.districts;
                this.init();
            }
        );
    }

    ngOnDestroy() {
        if (this._routeSub) {
            this._routeSub.unsubscribe();
        }
    }

    get isCreateMode() {
        return this._mode === 'create';
    }

    get isEditMode() {
        return this._mode === 'update';
    }

    isSelected(district: District) {
        return this._selections.get(district.id);
    }

    toggleSelection(district: District) {
        this._selections.set(district.id, !this._selections.get(district.id));
    }

    save() {
        this.setDistricts();
        if (this._mode === 'create') {
            this.create();
        } else if (this._mode === 'update') {
            this.update();
        }
    }

    delete() {
        this.districtGroupService.delete(this.group).subscribe(
            _ => {},
            _ => {},
            () => this.router.navigate(['/admin/district-group-list'])
        );
    }

    private init() {
        this.districts.forEach(d => {
            let selected = false;
            if (this.group.districts) {
                selected = this.group.districts.some(i => i.id === d.id);
            }
            this._selections.set(d.id, selected);
        });
    }

    private create() {
        this.districtGroupService.create(this.group).subscribe(
            group => this.success(group),
            error => this.fail(error),
            () => this.router.navigate(['/admin/district-group-list'])
        );
    }

    private update() {
        this.districtGroupService.update(this.group).subscribe(
            group => this.success(group),
            error => this.fail(error)
        );
    }

    private success(group: DistrictGroup) {
        this.group = group;
        this.toast.success('Distriktgruppe gemt');
    }

    private fail(error: any) {
        console.error('Error during district group save', error);
        this.toast.error('Fejl: Opdatering ikke gemt');
    }

    private setDistricts() {
        this.group.districts = this.districts.filter(d => this._selections.get(d.id));
    }

}
