import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { UserService } from '@app/core';


@Component({
    selector: 'app-employee-no-table',
    templateUrl: './employee-no-table.component.html',
    styleUrls: ['./employee-no-table.component.css']
})
export class EmployeeNoTableComponent implements OnInit {

    @Output() selection = new EventEmitter<number>();
    numbers: number[] = [];
    private available: number[];
    private _loading = false;
    private _css = new Map<number, string>();

    constructor(private userService: UserService) { }

    ngOnInit() {
        this.getAvailable();
    }

    get isLoading() {
        return this._loading;
    }

    css(number: number) {
        return this._css.get(number);
    }

    select(number: number) {
        if (!this.available.includes(number)) {
            return;
        }
        this.selection.emit(number);
    }

    private getAvailable() {
        this._loading = true;
        this.userService.availableEmployeeNumbers().subscribe(
            available => {
                this.available = available;
            },
            error => console.error('Error getting available numbers', error),
            () => {
                this.generate();
                this._loading = false;
            }
        );
    }

    private generate() {
        this._css.clear();
        for (let i = 1000; i < 10000; i++) {
            this.numbers.push(i);
            this._css.set(i, this.available.includes(i) ? null : 'has-background-danger');
        }
    }

}
