import { Profile } from './profile';


export class Shift {
    id: number;
    schedule: number;
    shifttype: number;
    date: string;
    has_log: boolean;
    is_locked: boolean;
    is_for_sale: boolean;
    is_bonus: boolean = false;
    is_imposed: boolean;
    is_mandatory: boolean;
    is_temporary: boolean = false;
    is_voluntary: boolean;
    user?: number;
    employee_no?: number;
    taxi?: boolean;
    comment: string;
}
