import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PlanningService, PeriodService, ToastService } from '@app/core';
import {
    DateHelper,
    DatePick,
    Deselection,
    DeselectionState,
    Emit,
    Holiday,
    Period,
    PlanningDeselection,
    Settings,
    User,
} from '@app/shared';


@Component({
    selector: 'app-deselection-check',
    templateUrl: './deselection-check.component.html',
    styleUrls: ['./deselection-check.component.css']
})
export class DeselectionCheckComponent implements OnInit {

    settings: Settings;
    user: User;
    private _allowed_deselections: number;
    private _deselections: PlanningDeselection[];
    private _disabledSave = false;
    private _holidays: Holiday[];
    private _loading = false;
    private _months: DeselectionState[][];
    private _period: Period;
    private _remainingDeselections = 0;

    constructor(
        private periodService: PeriodService,
        private planningService: PlanningService,
        private route: ActivatedRoute,
        private toast: ToastService,
    ) { }

    ngOnInit() {
        this.route.data.subscribe(
            data => {
                this.settings = data.settings;
                this.user = data.user;
                this._deselections = data.deselections;
                this._holidays = data.holidays;
                this._period = data.period;
                this._allowed_deselections = this.settings.allowed_deselections;
                this.preparePeriod();
            }
        );
    }

    get months() {
        return this._months;
    }

    get isLoading() {
        return this._loading;
    }

    get period() {
        return this._period;
    }

    get disabledSave() {
        return this._disabledSave;
    }

    get remainingDeselections() {
        return this._remainingDeselections;
    }

    get username() {
        return [this.user.first_name, this.user.last_name, '(' + this.user.profile.employee_no + ')'].join(' ');
    }

    get listLink() {
        return '/planning/' + this.period.id + '/wish-check-list';
    }

    get wishLink() {
        return '/planning/' + this.period.id + '/wish-check/' + this.user.id;
    }

    save() {
        this._disabledSave = true;
        const states: DeselectionState[] = Array.prototype.concat(...this._months);
        const deselections = states.map(d => {
            const deselection = new PlanningDeselection();
            deselection.load(d.data, this.user.id);
            return deselection;
        });
        this.planningService.deselectionSave(this._period.id, this.user.id, deselections).subscribe(
            data => {
                this.toast.success('Fravalg gemt');
            },
            error => {
                console.error('Error saving deselections', error);
                this._disabledSave = false;
                this.toast.error('Fejl: Fravalg ikke gemt');
            },
            () => {
                this._disabledSave = false;
                this.reloadPeriod();
            }
        );
    }

    listener(event: Emit) {
        if (event.type === 'update') {
            this.calculateRemainingDeselections();
        }
    }

    private readyPeriod(deselections: Map<string, DeselectionState[]>) {
        this._months = Array.from(deselections.values());
        this.calculateRemainingDeselections();
        this._loading = false;
    }

    private getDeselections(period: Period, deselections: Map<string, DeselectionState[]>) {
        this._deselections.forEach(deselection => this.mergeDeselection(deselection, deselections));
        this.readyPeriod(deselections);
    }

    private mergeDeselection(deselection: Deselection, deselections: Map<string, DeselectionState[]>) {
        const datePick = DateHelper.getDatePick(deselection.date);
        const key = DateHelper.getDatePickKey(datePick);
        const states = deselections.get(key);
        const index = states.findIndex(s => s.data.date === deselection.date);
        states.splice(index, 1, new DeselectionState(deselection, this._holidays));
    }

    private reloadPeriod() {
        this._loading = true;
        this.planningService.deselectionByUser(this.period.id, this.user.id).subscribe(
            deselections => {
                this._deselections = deselections;
            },
            error => console.error('Error reloading deselections', error),
            () => this.preparePeriod()
        );
    }

    private preparePeriod() {
        this._loading = true;
        const months = DateHelper.getMonths(this._period);
        const deselections = this.generateMonths(this._period, months);
        this.getDeselections(this._period, deselections);
    }

    private generateMonths(period: Period, months: DatePick[]) {
        const map = new Map<string, DeselectionState[]>();
        months.forEach(month => {
            const key = DateHelper.getDatePickKey(month);
            map.set(key, this.generateMonth(period, month));
        });
        return map;
    }

    private generateMonth(period: Period, month: DatePick) {
        const days: DeselectionState[] = [];
        const startDay = this.getStartDay(period, month);
        const daysInMonth = this.getDaysInMonth(period, month) + 1;
        for (let i = startDay; i < daysInMonth; i++) {
            const deselection = new Deselection();
            deselection.period = period.id;
            deselection.date = DateHelper.getDateString(new Date(month.year, month.month, i));
            days.push(new DeselectionState(deselection, this._holidays));
        }
        return days;
    }

    private getStartDay(period: Period, month: DatePick) {
        const start = DateHelper.getDatePick(period.start);
        if (month.month === start.month && month.year === start.year) {
            return start.day;
        }
        return 1;
    }

    private getDaysInMonth(period: Period, month: DatePick) {
        const end = DateHelper.getDatePick(period.end);
        if (month.month === end.month && month.year === end.year) {
            return end.day;
        }
        return DateHelper.getDaysInMonth(month);
    }

    private calculateRemainingDeselections() {
        const count = this.countDeselections();
        this._remainingDeselections = this._allowed_deselections - count;
    }

    private countDeselections() {
        let count = 0;
        this._months.forEach(month => {
            month.forEach(d => count += Number(d.data.day) + Number(d.data.evening) + Number(d.data.night));
        });
        return count;
    }

}
