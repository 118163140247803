export class Prepare {
    private _states: { [type: string]: boolean } = {};
    private _loading = false;
    private _trigger: () => void;

    constructor(states?: string[], trigger?: () => void) {
        if (states) { this.addStates(states); }
        if (trigger) { this.setTrigger(trigger); }
    }

    get isReady() {
        return this._loading;
    }

    addState(state: string) {
        this._states[state] = false;
    }

    addStates(states: string[]) {
        states.forEach(t => this.addState(t));
    }

    setTrigger(fn: () => void) {
        this._trigger = fn;
    }

    finish(state: string) {
        this._states[state] = true;
        const prepared = Object.values(this._states).every(b => !!b);
        if (prepared) {
            this._loading = false;
            if (this._trigger) {
                this._trigger();
            }
        }
    }
}
