import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Period } from '@app/shared';


@Component({
    selector: 'app-wish-period-list',
    templateUrl: './wish-period-list.component.html',
    styleUrls: ['./wish-period-list.component.css']
})
export class WishPeriodListComponent implements OnInit {

    periods: Period[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit() {
        this.route.data.subscribe(
            data => {
                this.periods = data.periods;
            }
        );
    }

    listPeriod(period: Period) {
        this.router.navigate(['planning', period.id]);
    }

}
