import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ScheduleService } from '@app/core';
import { Period, Schedule } from '@app/shared';


@Component({
    selector: 'app-schedule-create',
    templateUrl: './schedule-create.component.html',
    styleUrls: ['./schedule-create.component.css']
})
export class ScheduleCreateComponent implements OnDestroy, OnInit {

    schedule: Schedule = new Schedule();
    periods: Period[];
    lockError = false;
    private _subscriptions = new Subscription();

    constructor(
        private scheduleService: ScheduleService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(data => {
            this.periods = data.periods;
        }));
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    save() {
        this.schedule.is_published = false;
        this.schedule.published = null;
        this.lockError = false;
        this.scheduleService.create(this.schedule).subscribe(
            schedule => this.success(schedule),
            error => this.fail(error),
            () => this.router.navigate(['/scheduler/schedule/', this.schedule.id])
        );
    }

    private success(schedule: Schedule) {
        this.schedule = schedule;
    }

    private fail(error: any) {
        console.error('Error during schedule creation', error);
        const msg = error.error;
        if (msg && msg.non_field_errors) {
            if (msg.non_field_errors[0] === 'Period has to be locked to create schedule.') {
                this.lockError = true;
            }
        }
    }

}
