import { Component, OnInit } from '@angular/core';

import { UserService } from '@app/core';
import { UserStats } from '@app/shared';


@Component({
    selector: 'app-user-stats',
    templateUrl: './user-stats.component.html',
    styleUrls: ['./user-stats.component.css']
})
export class UserStatsComponent implements OnInit {

    stats: UserStats;

    drive_choice_type = {
        1: 'Kører selv',
        2: 'Altid taxa',
        3: 'Taxa nat'
    };

    constructor(
        private userService: UserService
    ) { }

    ngOnInit() {
        this.getStats();
    }

    private getStats() {
        this.userService.stats().subscribe(
            stats => this.stats = stats
        );
    }

}
