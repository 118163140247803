import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import {
    District,
    DistrictGroup,
    Schedule,
    Shift,
    ShiftComment,
    ShiftTeamStats,
    Shifttype,
    ShifttypeType,
    User
} from '@app/shared';


@Injectable({
  providedIn: 'root'
})
export class ShiftService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    list(schedule?: Schedule, district?: District, districtGroup?: DistrictGroup, shifttype?: ShifttypeType) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/shift/';  // TODO: Never used?
        if (schedule && (district || districtGroup) && shifttype) {
            url = '/api/scheduling/schedule/' + schedule.id + '/shift/';
            const qs: string[] = [];
            if (district) {
                qs.push('district=' + district.id);
            } else if (districtGroup) {
                qs.push('districtgroup=' + districtGroup.id);
            }
            // -1 is used for all shifttypes in UI
            if (shifttype && shifttype.id !== -1) {
                qs.push('shifttype=' + shifttype.id);
            }
            if (qs.length) {
                url += '?' + qs.join('&');
            }
        }
        return this.http.get<Shift[]>(url, { headers }).pipe(
                switchMap(response => response)
            );
    }

    myshifts(type: string) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/myshifts/';
        const qs: string[] = [];
        if (type === 'upcoming') {
            qs.push('type=' + type);
        } else {
            qs.push('type=year');
            qs.push('year=' + type);
        }
        url += '?' + qs.join('&');
        return this.http.get<Shift[]>(
            url,
            { headers }
        ).pipe(
            switchMap(response => response)
        );
    }

    forSaleList(district?: District, districtGroup?: DistrictGroup) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/shiftsforsale/';
        const qs: string[] = [];
        if (district) {
            qs.push('district=' + district.id);
        } else if (districtGroup) {
            qs.push('districtgroup=' + districtGroup.id);
        }
        if (qs.length) {
            url += '?' + qs.join('&');
        }
        return this.http.get<Shift[]>(
            url,
            { headers }
        ).pipe(
            switchMap(response => response)
        );
    }

    warningList(district?: District, districtGroup?: DistrictGroup) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/shiftswarning/';
        const qs: string[] = [];
        if (district) {
            qs.push('district=' + district.id);
        } else if (districtGroup) {
            qs.push('districtgroup=' + districtGroup.id);
        }
        if (qs.length) {
            url += '?' + qs.join('&');
        }
        return this.http.get<Shift[]>(
            url,
            { headers }
        ).pipe(
            switchMap(response => response)
        );
    }

    schedule(district: District, type: ShifttypeType, month: number, year: number) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/shiftschedule/';
        url += '?district=' + district.id;
        url += '&type=' + type.id + '&month=' + month + '&year=' + year;
        return this.http.get<Shift[]>(url, { headers }).pipe(
            switchMap(response => response)
        );
    }

    team(date: string, shifttypeType: number) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/shiftteam/';
        const qs: string[] = [];
        qs.push('date=' + date);
        if (shifttypeType) {
            qs.push('type=' + shifttypeType);
        }
        if (qs.length) {
            url += '?' + qs.join('&');
        }
        return this.http.get<Shift[]>(url, { headers }).pipe(
            switchMap(response => response)
        );
    }

    teamStats(date: string) {
        const headers = this.authService.authHeaders();
        const url = '/api/scheduling/shiftteam/stats/' + date + '/';
        return this.http.get<ShiftTeamStats>(url, { headers });
    }

    get(id: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<Shift>(
            '/api/scheduling/shift/' + id + '/',
            { headers }
        );
    }

    create(shift: Shift) {
        const headers = this.authService.authHeaders();
        return this.http.post<Shift>(
            '/api/scheduling/shift/',
            shift,
            { headers }
        );
    }

    update(shift: Shift) {
        const headers = this.authService.authHeaders();
        return this.http.put<Shift>(
            '/api/scheduling/shift/' + shift.id + '/',
            shift,
            { headers }
        );
    }

    lock(shift: Shift) {
        const headers = this.authService.authHeaders();
        return this.http.put<Shift>(
            '/api/scheduling/shift/' + shift.id + '/lock/',
            null,
            { headers }
        );
    }

    lockShifttype(schedule: Schedule, shifttype: Shifttype) {
        const headers = this.authService.authHeaders();
        return this.http.put<Shift[]>(
            '/api/scheduling/schedule/' + schedule.id + '/lock_shifttype/' + shifttype.id + '/',
            null,
            { headers }
        );
    }

    forSale(shift: Shift) {
        const headers = this.authService.authHeaders();
        return this.http.put<Shift>(
            '/api/scheduling/shift/' + shift.id + '/for_sale/',
            null,
            { headers }
        );
    }

    notForSale(shift: Shift) {
        const headers = this.authService.authHeaders();
        return this.http.put<Shift>(
            '/api/scheduling/shift/' + shift.id + '/not_for_sale/',
            null,
            { headers }
        );
    }

    available(shift: Shift) {
        const headers = this.authService.authHeaders();
        return this.http.put<Shift>(
            '/api/scheduling/shift/' + shift.id + '/available/',
            null,
            { headers }
        );
    }

    mandatory(shift: Shift) {
        const headers = this.authService.authHeaders();
        return this.http.put<Shift>(
            '/api/scheduling/shift/' + shift.id + '/mandatory/',
            null,
            { headers }
        );
    }

    notMandatory(shift: Shift) {
        const headers = this.authService.authHeaders();
        return this.http.put<Shift>(
            '/api/scheduling/shift/' + shift.id + '/not_mandatory/',
            null,
            { headers }
        );
    }

    conflictCheck(shift: Shift, user: User) {
        const headers = this.authService.authHeaders();
        return this.http.get(
            '/api/scheduling/shift/' + shift.id + '/check_conflict/?user=' + user.id,
            { headers }
        );
    }

    updateComment(shift: Shift, comment: string) {
        const updateComment: ShiftComment = {
            comment: comment
        };
        const headers = this.authService.authHeaders();
        return this.http.put<ShiftComment>(
            '/api/scheduling/shift/' + shift.id + '/updatecomment/',
            updateComment,
            { headers }
        );
    }
}
