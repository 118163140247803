import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth.service';
import { Profile, SetPassword } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    get() {
        const headers = this.authService.authHeaders();
        return this.http.get<Profile>('/api/user/me/', { headers });
    }

    update(profile: Profile) {
        const headers = this.authService.authHeaders();
        return this.http.put<Profile>('/api/user/me/', profile, { headers });
    }

    setPassword(setPassword: SetPassword) {
        const headers = this.authService.authHeaders();
        return this.http.post(
            '/api/user/set_password/', setPassword, { headers });
    }
}
