import { UIState } from '../generics';
import { Shift, ShiftHistory, User } from '../models';

import { ShiftHistoryService } from '@app/core';


export class HistoryHandler {
    private history = new Map<number, ShiftHistory[]>();
    private show = false;
    private timer: NodeJS.Timer;
    private tooltips = new Map<number, string>();
    private users = new Map<number, User>();

    constructor(
        private service: ShiftHistoryService,
        public enabled = false,
        public showUser = false,
        public planning = false
    ) { }

    mouseEnter(state: UIState<Shift>) {
        if (this.enabled && state.data && state.data.has_log) {
            this.timer = setTimeout(_ => this.getTooltip(state), 400);
        }
    }

    mouseLeave(shift: UIState<Shift>) {
        clearTimeout(this.timer);
        this.show = false;
        shift.removeClass('tooltip is-tooltip-bottom');
    }

    clear(shift: Shift) {
        this.history.delete(shift.id);
    }

    get(key: number) {
        return this.history.get(key);
    }

    has(key: number) {
        return this.history.has(key);
    }

    tooltip(state: UIState<Shift>) {
        if (this.enabled && state.data) {
            return this.tooltips.get(state.data.id);
        }
    }

    private getTooltip(state: UIState<Shift>) {
        if (this.history.has(state.data.id)) {
            this.showShiftTooltip(state);
            return;
        }

        this.service.list(state.data, this.planning).subscribe(
            history => {
                this.addHistory(state.data, history);
                if (this.showUser) {
                    this.addUser(history.user);
                }
            },
            error => console.log('Error getting shift history', error),
            () => {
                this.setTooltip(state.data);
                this.showShiftTooltip(state);
            }
        );
    }

    private addHistory(shift: Shift, history: ShiftHistory) {
        if (this.history.has(shift.id)) {
            this.history.get(shift.id).push(history);
        } else {
            this.history.set(shift.id, [history]);
        }
    }

    private addUser(user: User) {
        if (user && !this.users.has(user.id)) {
            this.users.set(user.id, user);
        }
    }

    private showShiftTooltip(shift: UIState<Shift>) {
        const history = this.history.get(shift.data.id);
        const user = this.users.get(shift.data.user);
        if ((history && history.length > 1) || (this.showUser && user)) {
            this.show = true;
            shift.addClass('tooltip is-tooltip-bottom');
            if (this.showUser) {
                shift.addClass('is-tooltip-multiline');
            }
        }
    }

    private setTooltip(shift: Shift) {
        if (!this.tooltips.has(shift.id)) {
            const history = this.history.get(shift.id);
            const user = this.users.get(shift.user);
            if (!history && (this.showUser && !user)) {
                return;
            }
            const tooltip: string[] = [];
            if (this.showUser && user) {
                if (shift.is_for_sale) {
                    tooltip.push('TIL SALG');
                }
                tooltip.push(['Læge:', user.first_name, user.last_name, '(' + user.profile.employee_no + ')'].join(' '));
                if (shift.taxi) {
                    tooltip.push(['TAXA:', user.profile.address + ',', user.profile.zipcode].join(' '));
                }
                tooltip.push([user.email, '-', user.profile.phone_mobile].join(' '));
            }
            // If history only has one row it's the same as the employee shown
            if (history && history.length > 1) {
                tooltip.push(history.map(s => this.getTooltipText(s)).join(' > '));
            }
            const message = tooltip.join('\n');
            if (message.length > 0) {
                this.tooltips.set(shift.id, message);
            }
        }
    }

    private getTooltipText(history: ShiftHistory) {
        if (history.is_locked) {
            return 'lukket';
        } else if (history.is_for_sale) {
            return 'til salg';
        } else if (!history.user) {
            return 'ledig';
        } else {
            return history.user.profile.employee_no;
        }
    }
}
