export class Settings {
    allowed_deselections: number;
    comment_length: number;
    night_visitation_min: number;
    night_visitation_max: number;
    night_drive_min: number;
    night_drive_max: number;
    evening_visitation_min: number;
    evening_visitation_max: number;
    evening_drive_min: number;
    evening_drive_max: number;
    bonus_night_min: number;
    bonus_night_max: number;
    bonus_accumulated_min: number;
    bonus_accumulated_max: number;
    shift_available_warning: number;
    deselection_text: string;
}
