import { Deselection } from './deselection';
import { ExtendedProfile } from './extended-profile';
import { Shift } from './shift';
import { Wish } from './wish';


export class User {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    last_login: string;
    is_staff: boolean;
    is_active: boolean;
    profile?: ExtendedProfile;
    deselections?: Deselection[];
    wish?: Wish;
    shifts: Shift[];
}
