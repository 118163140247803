import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { DeselectionService } from '../../services/deselection.service';
import { Period } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class DeselectionPeriodsResolverService implements Resolve<Period[]> {

    constructor(
        private deselectionService: DeselectionService
    ) { }

    resolve() {
        const periods: Period[] = [];
        return new Promise<Period[]>((resolve, reject) => {
            this.deselectionService.periods().subscribe(
                period => periods.push(period),
                error => reject(error),
                () => resolve(periods)
            );
        });
    }

}
