import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { User } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class UserResolverService implements Resolve<User> {

    constructor(
        private userService: UserService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
        const id = route.paramMap.get('id') || route.paramMap.get('user');

        if (id === null) {
            return of(new User());
        }

        return this.userService.get(+id).pipe(
            map(user => {
                if (user) {
                    return user;
                }
                this.router.navigate(['../user-list']);
                return null;
            })
        );
    }
}
