import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { HolidayService, ToastService } from '@app/core';
import { DateHelper, DatePick, Holiday } from '@app/shared';


@Component({
    selector: 'app-holiday',
    templateUrl: './holiday.component.html',
    styleUrls: ['./holiday.component.css']
})
export class HolidayComponent implements OnInit, OnDestroy {

    date: DatePick;
    holiday: Holiday;
    private _mode: 'create' | 'update';
    private _subscriptions = new Subscription();

    constructor(
        private holidayService: HolidayService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(data => {
                this._mode = !!data.holiday.id ? 'update' : 'create';
                this.holiday = data.holiday;
                this.prepareDates();
            }));
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    get isCreateMode() {
        return this._mode === 'create';
    }

    get isEditMode() {
        return this._mode === 'update';
    }

    delete() {
        this.holidayService.delete(this.holiday).subscribe(
            _ => {},
            _ => {},
            () => this.router.navigate(['/admin/holiday-list'])
        );
    }

    save() {
        this.setDates();
        if (this._mode === 'create') {
            this.create();
        } else if (this._mode === 'update') {
            this.update();
        }
    }

    private setDates() {
        this.holiday.date = this.getDateFromPick(this.date);
    }

    private prepareDates() {
        if (this.holiday) {
            this.date = DateHelper.getDatePick(this.holiday.date);
        }
    }

    private getDateFromPick(pick: DatePick) {
        return DateHelper.getDateString(new Date(pick.year, pick.month, pick.day));
    }

    private create() {
        this.holidayService.create(this.holiday).subscribe(
            holiday => this.success(holiday),
            error => this.fail(error),
            () => this.router.navigate(['/admin/holiday-list'])
        );
    }

    private update() {
        this.holidayService.update(this.holiday).subscribe(
            holiday => this.success(holiday),
            error => this.fail(error)
        );
    }

    private success(holiday: Holiday) {
        this.holiday = holiday;
        this.toast.success('Helligdag gemt');
    }

    private fail(error: any) {
        console.error('Error during holiday save', error);
        this.toast.error('Fejl: Opdatering ikke gemt');
    }

}
