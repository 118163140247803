import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { District, Settings, Shifttype, ShifttypeType } from '@app/shared';


@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit, OnDestroy {

    date: string;
    day: number;
    days: number[];
    districts: District[];
    expandedList = false;
    initType: number;
    month: number;
    year: number;
    years: number[];
    shifttypes: Shifttype[];
    shifttypeTypes: ShifttypeType[];
    selectedType: ShifttypeType;
    settings: Settings;
    private _routeSub: Subscription;

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        this._routeSub = this.route.data.subscribe(
            data => {
                this.districts = data.districts;
                this.settings = data.settings;
                this.shifttypes = data.shifttypes;
                this.shifttypeTypes = data.shifttypeTypes;

                this.initDates();
                this.setTypeSelections();
                this.initSelection();
                this.setToday();
                this.update();
            },
            error => console.error(error)
        );
    }

    ngOnDestroy() {
        this._routeSub.unsubscribe();
    }

    toggleListMode() {
        this.expandedList = !this.expandedList;
    }

    update() {
        const date = new Date(this.year, this.month - 1, this.day);
        this.date = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(
            d => d < 10 ? '0' + d : d
        ).join('-');
    }

    selectType(type: ShifttypeType) {
        this.selectedType = type;
        this.initType = type.id;
    }

    private initDates() {
        this.days = this.getDays();
        this.years = this.getYears();
    }

    private setTypeSelections() {
        this.shifttypeTypes.unshift({ id: 0, name: 'Alle' });
        this.shifttypeTypes.push({ id: 1000, name: 'Taxa'});
    }

    private initSelection() {
        if (this.shifttypeTypes.length) {
            this.selectedType = this.shifttypeTypes[0];
            this.initType = this.shifttypeTypes[0].id;
        }
    }

    private getDays() {
        return [...Array.from(Array(31).keys()).map(d => d + 1)];
    }

    private getYears() {
        const currentYear = new Date().getFullYear();
        const years: number[] = [];
        for (let i = 2020; i < currentYear + 2; i++) {
            years.push(i);
        }
        return years;
    }

    private setToday() {
        const date = new Date();
        this.day = date.getDate();
        this.month = date.getMonth() + 1;
        this.year = date.getFullYear();
    }

}
