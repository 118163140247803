import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import {
    AuthService,
    ShiftService,
    ShiftHistoryService,
    ToastService,
    UserService
} from '@app/core';
import {
    DateHelper,
    District,
    Shift,
    ShiftHistory,
    Shifttype,
    User
} from '@app/shared';


@Component({
    selector: 'app-shift',
    templateUrl: './shift.component.html',
    styleUrls: ['./shift.component.css']
})
export class ShiftComponent implements OnInit, OnDestroy {

    conflictChecked = false;
    district: District;
    prevLinkTitle: string;
    selectedUser: User;
    selectedUserConflict = false;
    searchUsers: User[] = [];
    shifttype: Shifttype;
    shift: Shift;
    history: ShiftHistory[];
    query = new FormControl();
    user: User;
    private _districts: District[];
    private _formDisabled = false;
    private _isAdminUser = false;
    private _loading = false;
    private _searching = false;
    private _searchMinLength = 3;
    private _shifttypes: Shifttype[];
    private _subscriptions = new Subscription();
    private prev = 'schedule';
    private prevUserId: string;

    constructor(
        private authService: AuthService,
        private shiftHistoryService: ShiftHistoryService,
        private shiftService: ShiftService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(
            data => {
                this.shift = data.shift;
                this.history = data.history;
                this._districts = data.districts;
                this._shifttypes = data.shifttypes;

                this._isAdminUser = this.authService.user.is_staff;

                this.init();
            }
        ));
        this._subscriptions.add(this.route.queryParamMap.subscribe(params => {
            if (params.has('prev')) {
                this.prev = params.get('prev');
                if (this.prev === 'user') {
                    this.prevUserId = params.get('user');
                }
            }
            this.setupLink();
        }));
        this.subscribe();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    get canLock() {
        return !this.shift.is_locked;
    }

    get formDisabled() {
        return this._formDisabled;
    }

    get isAdmin() {
        return this._isAdminUser;
    }

    get isLoading() {
        return this._loading;
    }

    get isSearching() {
        return this._searching;
    }

    get shiftTime() {
        const shifttype = this.shifttype;
        const date = DateHelper.getDateFromString(this.shift.date);
        if (DateHelper.isWeekend(date) && this.hasWeekendTime(shifttype)) {
            return [this.getTimeHours(shifttype.weekend_start),
            this.getTimeHours(shifttype.weekend_end)].join('-');
        }
        return [this.getTimeHours(shifttype.start),
        this.getTimeHours(shifttype.end)].join('-');
    }

    get title() {
        return 'Vagt (' + this.shift.id + ')';
    }

    goBack() {
        const params = this.route.snapshot.queryParamMap;
        if (this.prev === 'schedule') {
            const date = DateHelper.getDateFromString(this.shift.date);
            this.router.navigate(['/shift/schedule'], {
                queryParams: {
                    district: this.district.id,
                    type: this.shifttype.type,
                    month: date.getMonth() + 1,
                    year: date.getFullYear()
                }
            });
        } else if (this.prev === 'user') {
            this.router.navigate(['shift', 'user', this.prevUserId], {
                queryParams: {
                    month: params.has('month') ? params.get('month') : null,
                    year: params.has('year') ? params.get('year') : null
                }
            });
        } else if (this.prev === 'for-sale') {
            this.router.navigate(['shift', 'for-sale'], {
                queryParams: {
                    district: params.has('district') ? params.get('district') : null,
                    group: params.has('group') ? params.get('group') : null
                }
            });
        } else if (this.prev === 'available') {
            this.router.navigate(['shift', 'available'], {
                queryParams: {
                    district: params.has('district') ? params.get('district') : null,
                    group: params.has('group') ? params.get('group') : null
                }
            });
        } else if (this.prev === 'warning') {
            this.router.navigate(['shift', 'warning'], {
                queryParams: {
                    district: params.has('district') ? params.get('district') : null,
                    group: params.has('group') ? params.get('group') : null
                }
            });
        }
    }

    save() {
        if (this.selectedUser && this.conflictChecked && this.selectedUser.id !== this.shift.user) {
            this._formDisabled = true;
            this.shift.user = this.selectedUser.id;
            this.shift.employee_no = this.selectedUser.profile.employee_no;
            this.shift.is_for_sale = false;
            this.shift.is_locked = false;
            this.shift.is_bonus = false;
            this.shift.is_mandatory = false;
            this.shift.is_temporary = false;
            this.shiftService.update(this.shift).subscribe(
                shift => {
                    this.shift = shift;
                    this.refreshHistory();
                    this.init();
                    this.toast.success('Vagt opdateret');
                },
                error => {
                    console.error('Error updating shift', error);
                    if (this.isConflictError(error)) {
                        this.toast.error('Fejl: ' + error.error.non_field_errors[0]);
                        this.conflictChecked = true;
                        this.selectedUserConflict = true;
                    } else {
                        this.toast.error('Fejl: Opdatering ikke gemt');
                    }
                },
                () => this._formDisabled = false
            );
        }
    }

    saveComment() {
        const comment = this.shift.comment;
        this.shiftService.updateComment(
            this.shift, comment).subscribe(
                response => {
                    this.shift.comment = response.comment;
                },
                error => {
                    console.error('Error updating comment', error);
                    this.toast.error('Fejl: Kommentar ikke gemt');
                }
            );
    }

    lock() {
        this.shiftService.lock(this.shift).subscribe(
            shift => {
                this.shift = shift;
                this.refreshHistory();
                this.init();
                this.toast.success('Vagt lukket');
            },
            error => {
                console.error('Error locking shift', error);
                this.toast.error('Fejl: Vagt ikke lukket');
            }
        );
    }

    forSale() {
        this.shiftService.forSale(this.shift).subscribe(
            shift => {
                this.shift = shift;
                this.refreshHistory();
                this.init();
                this.toast.success('Vagt sat til salg');
            },
            error => {
                console.error('Error setting shift for sale', error);
                this.toast.error('Fejl: Vagt ikke sat til salg');
            }
        );
    }

    notForSale() {
        this.shiftService.notForSale(this.shift).subscribe(
            shift => {
                this.shift = shift;
                this.refreshHistory();
                this.init();
                this.toast.success('Vagt ikke længere til salg');
            },
            error => {
                console.error('Error removing shift for sale', error);
                this.toast.error('Fejl: Til salg ikke fjernet fra vagt');
            }
        );
    }

    available() {
        this.shiftService.available(this.shift).subscribe(
            shift => {
                this.shift = shift;
                this.refreshHistory();
                this.init();
                this.toast.success('Vagt er sat som ledig');
            },
            error => {
                console.error('Error setting shift as available', error);
                this.toast.error('Fejl: Vagt ikke sat som ledig');
            }
        );
    }

    mandatory() {
        this.shiftService.mandatory(this.shift).subscribe(
            shift => {
                this.shift = shift;
                this.refreshHistory();
                this.init();
                this.toast.success('Vagt er sat som obligatorisk');
            },
            error => {
                console.error('Error setting shift as available', error);
                this.toast.error('Fejl: Vagt ikke sat som obligatorisk');
            }
        );
    }

    notMandatory() {
        this.shiftService.notMandatory(this.shift).subscribe(
            shift => {
                this.shift = shift;
                this.refreshHistory();
                this.init();
                this.toast.success('Vagt ikke længere obligatorisk');
            },
            error => {
                console.error('Error setting shift as available', error);
                this.toast.error('Fejl: Vagt stadig obligatorisk');
            }
        );
    }

    selectUser(user: User) {
        this.query.setValue('');
        this.selectedUser = user;
        this.searchUsers.length = 0;
        this.shiftService.conflictCheck(this.shift, this.selectedUser).subscribe(
            () => this.selectedUserConflict = false,
            () => this.selectedUserConflict = true,
            () => this.conflictChecked = true
        );
    }

    private init() {
        if (this.shift) {
            this.shifttype = this._shifttypes.find(s => s.id === this.shift.shifttype);
            this.district = this._districts.find(d => d.id === this.shifttype.district);
            this.selectedUser = null;
            this.searchUsers.length = 0;
            this.query.setValue('');
            this.getUser();
        }
    }

    private lookupUser(query: string) {
        const users: User[] = [];
        return new Promise<User[]>((resolve, reject) => {
            this.userService.search(query).subscribe(
                user => users.push(user),
                error => reject(error),
                () => resolve(users)
            );
        });
    }

    private getUser() {
        if (this.shift.user) {
            this._loading = true;
            this.userService.get(this.shift.user).subscribe(
                user => this.user = user,
                error => console.error('User failed loading', error),
                () => this._loading = false
            );
        }
    }

    private isValidQuery(query: string) {
        return query.length === 4 && !isNaN(+query);
    }

    private refreshHistory() {
        this.shiftHistoryService.listArray(this.shift).subscribe(
            history => this.history = history
        );
    }

    private hasWeekendTime(shifttype: Shifttype) {
        return !!shifttype.weekend_start && !!shifttype.weekend_end;
    }

    private getTimeHours(time: string) {
        return time.substr(0, 2);
    }

    private isValid(query: string) {
        return query.length >= this._searchMinLength;
    }

    private search(query: string) {
        if (!this.isValid(query)) {
            return;
        }
        this._formDisabled = false;
        this.conflictChecked = false;
        this._searching = true;
        this.getUsers(query).then(users => {
            if (users.length === 1) {
                this.selectUser(users[0]);
            } else {
                this.searchUsers = users;
            }
            this._searching = false;
        });
    }

    private getUsers(query: string) {
        const users: User[] = [];
        return new Promise<User[]>((resolve, reject) => {
            this.userService.search(query).subscribe(
                user => {
                    if (!!user.profile.employee_no) {
                        users.push(user);
                    }
                },
                error => reject(error),
                () => resolve(users)
            );
        });
    }

    private subscribe() {
        this._subscriptions.add(this.query.valueChanges.pipe(
            tap(() => this.searchUsers.length = 0),
            debounceTime(400),
            distinctUntilChanged()
        ).subscribe(query => this.search(query)));
    }

    private setupLink() {
        let title = 'Tilbage til ';
        if (this.prev === 'schedule') {
            title += 'vagtskema';
        } else if (this.prev === 'user') {
            title += 'bruger';
        } else if (this.prev === 'for-sale') {
            title += 'vagter til salg';
        } else if (this.prev === 'available') {
            title += 'ledige vagter';
        } else if (this.prev === 'warning') {
            title += 'akutvagter';
        }
        this.prevLinkTitle = title;
    }

    private isConflictError(error) {
        const err = error.error;
        return typeof err === 'object'
            && err.hasOwnProperty('non_field_errors')
            && Array.isArray(err.non_field_errors)
            && err.non_field_errors.length === 1
            && err.non_field_errors[0] === 'Konflikt med anden vagt';
    }

}
