import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Period, Profile } from '@app/shared';


@Component({
    selector: 'app-no-wishes-list',
    templateUrl: './no-wishes-list.component.html',
    styleUrls: ['./no-wishes-list.component.css']
})
export class NoWishesListComponent implements OnInit {

    period: Period;
    users: Profile[] = [];

    constructor(
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.route.data.subscribe(
            data => {
                this.period = data.period;
                this.users = data.users;
            }
        );
    }

    get wishListLink() {
        return '/planning/' + this.period.id + '/wish-check-list';
    }

    get noShiftsListLink() {
        return '/planning/' + this.period.id + '/no-shifts-list';
    }

    get periodOverviewLink() {
        return '/planning/' + this.period.id;
    }

    get warningLink() {
        return '/planning/' + this.period.id + '/warning';
    }

}
