import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, ProfileService, ToastService } from '@app/core';
import { SetPassword } from '@app/shared';


@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {

    setPassword = new SetPassword();
    errorCurrent = false;
    errorNew = false;
    errorRetype = false;
    errorCurrentMessage: string;
    errorNewMessage: string;
    errorRetypeMessage: string;

    private passwordMessages = {
        'This password is too short. It must contain at least 8 characters.': 'Adgangskode skal være mindst 8 tegn',
        'This password is entirely numeric.': 'Adgangskode skal indeholde andet end tal',
        'This password is too common.': 'Denne adgangskode er for almindelig',
        'The password is too similar to the email address.': 'Adgangskoden ligner for meget email adressen',
        'The password is too similar to the first name.': 'Adgangskoden ligner for meget fornavn',
        'The password is too similar to the last name.': 'Adgangskoden ligner for meget efternavn',
        'Invalid password.': 'Forkert adgangskode',
        'The two password fields didn\'t match.': 'Adgangskoder skal være ens'
    };

    constructor(
        private authService: AuthService,
        private profileService: ProfileService,
        private router: Router,
        private toast: ToastService
    ) { }

    ngOnInit() {
    }

    get isFirstLogin() {
        return this.authService.isFirstLogin;
    }

    save() {
        this.profileService.setPassword(this.setPassword).subscribe(
            () => this.success(),
            error => this.fail(error)
        );
    }

    private success() {
        this.errorCurrent = false;
        this.errorNew = false;
        this.errorRetype = false;
        this.setPassword = new SetPassword();
        this.toast.success('Adgangskode opdateret');
        this.updateAndRedirect();
    }

    private updateAndRedirect() {
        this.authService.updateProfile().subscribe(
            () => {
                this.router.navigate(['/dashboard']);
            }
        );
    }

    private fail(error: any) {
        const msg = error.error;
        if (msg && msg.current_password) {
            this.errorCurrent = true;
            this.errorCurrentMessage = this.passwordMessages[msg.current_password[0]];
        }
        if (msg && msg.new_password) {
            this.errorNew = true;
            this.errorNewMessage = this.passwordMessages[msg.new_password[0]];
        }
        if (msg && msg.non_field_errors) {
            this.errorNew = true;
            this.errorRetype = true;
            this.errorNewMessage = this.passwordMessages[msg.non_field_errors[0]];
            this.errorRetypeMessage = this.passwordMessages[msg.non_field_errors[0]];
        }
        console.error('Error during set password', error);
        this.toast.error('Fejl: Adgangskode ikke opdateret');
    }

}
