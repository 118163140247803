import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DeselectionState } from '../../classes';
import { DateCheck, Emit } from '../../interfaces';


@Component({
    selector: 'app-deselection-month',
    templateUrl: './deselection-month.component.html',
    styleUrls: ['./deselection-month.component.css']
})
export class DeselectionMonthComponent implements OnInit {

    @Input() deselections: DeselectionState[];
    @Input() locked = false;
    @Input() remaining: number;
    @Output() event = new EventEmitter<Emit>();
    private _ready = false;
    private _all: DateCheck[];

    constructor() { }

    ngOnInit() {
        this.prepare();
        this._ready = true;
    }

    get allDays() {
        return this._all;
    }

    get isReady() {
        return this._ready;
    }

    update(event: Event, state: DeselectionState, key: string) {
        const deselection = state.data;
        if (this.remaining < 1 && !deselection[key]) {
            event.preventDefault();
            return;
        }
        deselection[key] = !deselection[key];
        const dateCheck = this._all.find(d => d.date === deselection.date);
        dateCheck.checked = this.hasAllChecked(state);
        this.event.emit({ type: 'update' });
    }

    updateAll(dateCheck: DateCheck, event: Event) {
        const state = this.deselections.find(d => d.data.date === dateCheck.date);
        if (!dateCheck.checked) {
            if (this.remaining < this.numberOfChecksLeft(state)) {
                event.preventDefault();
                return;
            }
        }
        if (state.isFullDay) {
            state.data.day = !dateCheck.checked;
        }
        state.data.evening = !dateCheck.checked;
        dateCheck.checked = !dateCheck.checked;
        this.event.emit({ type: 'update' });
    }

    private prepare() {
        this._all = [];
        this.deselections.forEach(d => this._all.push(this.getDateCheck(d)));
    }

    private getDateCheck(state: DeselectionState) {
        return {
            date: state.data.date,
            checked: this.hasAllChecked(state)
        };
    }

    private hasAllChecked(state: DeselectionState) {
        const deselection = state.data;
        if (state.isFullDay) {
            return deselection.day && deselection.evening;
        } else {
            return deselection.evening;
        }
    }

    private numberOfChecksLeft(state: DeselectionState) {
        let total = 1;
        let checked = Number(state.data.evening);
        if (state.isFullDay) {
            total++;
            checked += Number(state.data.day);
        }
        return total - checked;
    }

}
