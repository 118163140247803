import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { PlanningService } from '../services/planning.service';
import { PlanningShiftStats } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class PlanningShiftStatsResolverService {

    constructor(private planningService: PlanningService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const period = route.paramMap.get('period');
        const stats: PlanningShiftStats[] = [];
        return new Promise<PlanningShiftStats[]>((resolve, reject) => {
            this.planningService.shiftList(+period).subscribe(
                wish => stats.push(wish),
                error => reject(error),
                () => resolve(stats)
            );
        });
    }
}
