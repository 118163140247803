import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
    ShifttypeService,
    ToastService
} from '@app/core';
import { District, Shifttype, ShifttypeType } from '@app/shared';


@Component({
    selector: 'app-shifttype',
    templateUrl: './shifttype.component.html',
    styleUrls: ['./shifttype.component.css']
})
export class ShifttypeComponent implements OnDestroy, OnInit {

    shifttype: Shifttype;
    errorUniqueTitle = false;
    private _daytypes = [
        { key: 'fullweek', value: 'Alle dage'},
        { key: 'weekend', value: 'Lør/Søn/Helligdag'},
        { key: 'allday', value: 'Hele dagen' }
    ];
    private _timeperiods = [
        { key: 'day', value: 'Dag' },
        { key: 'evening', value: 'Aften' },
        { key: 'night', value: 'Nat' }
    ];
    private _districts: District[] = [];
    private _mode: 'create' | 'update';
    private _subscriptions = new Subscription();
    private _types: ShifttypeType[] = [];

    constructor(
        private shifttypeService: ShifttypeService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(data => {
            this._mode = !!data.shifttype.id ? 'update' : 'create';
            this._districts = data.districts;
            this.shifttype = data.shifttype;
            this._types = data.types;
        }));
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    get daytypes() {
        return this._daytypes;
    }

    get districts() {
        return this._districts;
    }

    get timeperiods() {
        return this._timeperiods;
    }

    get types() {
        return this._types;
    }

    get mode() {
        return this._mode;
    }

    get isCreateMode() {
        return this._mode === 'create';
    }

    get isEditMode() {
        return this._mode === 'update';
    }

    save() {
        if (this._mode === 'create') {
            this.create();
        } else if (this._mode === 'update') {
            this.update();
        }
    }

    activate() {
        this.shifttypeService.activate(this.shifttype).subscribe(
            shifttype => this.success(shifttype, 'Vagttype aktiveret'),
            error => {
                console.error('Error during shifttype activation', error);
                this.toast.error('Fejl: Vagttype ikke aktiveret');
            }
        );
    }

    deactivate() {
        this.shifttypeService.deactivate(this.shifttype).subscribe(
            shifttype => this.success(shifttype, 'Vagttype deaktiveret'),
            error => {
                console.error('Error during shifttype deactivation', error);
                this.toast.error('Fejl: Vagttype ikke deaktiveret');
            }
        );
    }

    private create() {
        this.shifttype.is_active = true;
        this.shifttypeService.create(this.shifttype).subscribe(
            shifttype => this.success(shifttype),
            error => this.fail(error),
            () => this.router.navigate(['/admin/shifttype-list'])
        );
    }

    private update() {
        this.shifttypeService.update(this.shifttype).subscribe(
            shifttype => this.success(shifttype),
            error => this.fail(error)
        );
    }

    private success(shifttype: Shifttype, toastMessage = 'Vagttype gemt') {
        this.shifttype = shifttype;
        this.errorUniqueTitle = false;
        this.toast.success(toastMessage);
    }

    private fail(error: any) {
        const msg = error.error;
        if (msg && msg.name) {
            if (msg.name[0] === 'shifttype with this name already exists.') {
                this.errorUniqueTitle = true;
            }
        }
        console.error('Error during shifttype save', error);
        this.toast.error('Fejl: Opdatering ikke gemt');
    }

}
