import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HotkeyModule } from 'angular2-hotkeys';

import { CoreModule } from './core';
import { SharedModule } from './shared';
import { AuthModule } from './auth';
import { AdminModule } from './admin';
import { DashboardModule } from './dashboard';
import { OnboardingModule } from './onboarding';
import { PageModule } from './page';
import { PlanningModule } from './planning';
import { ProfileModule } from './profile';
import { SchedulerModule } from './scheduler';
import { ShiftModule } from './shift';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HotkeyModule.forRoot(),
        CoreModule,
        SharedModule,
        AdminModule,
        AuthModule,
        DashboardModule,
        OnboardingModule,
        PageModule,
        PlanningModule,
        ProfileModule,
        SchedulerModule,
        ShiftModule,
        AppRoutingModule  // Important: Load as the last routing
    ],
    providers: [CookieService, { provide: LOCALE_ID, useValue: 'da' }],
    bootstrap: [AppComponent]
})
export class AppModule { }
