import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { PageService } from '../services/page.service';
import { Page } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class PageListResolverService implements Resolve<Page[]> {

    constructor(private pageService: PageService) { }

    resolve() {
        const pages: Page[] = [];
        return new Promise<Page[]>((resolve, reject) => {
            this.pageService.list().subscribe(
                page => pages.push(page),
                error => reject(error),
                () => resolve(pages)
            );
        });
    }
}
