import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ScheduleService } from '@app/core';
import { Schedule } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ScheduleResolverService implements Resolve<Schedule> {

    constructor(
        private scheduleService: ScheduleService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Schedule> {
        const id = route.paramMap.get('id');

        if (id === null) {
            return of(new Schedule());
        }

        return this.scheduleService.get(+id).pipe(
            map(schedule => {
                if (schedule) {
                    return schedule;
                }
                this.router.navigate(['../schedule-list']);
                return null;
            })
        );
    }
}
