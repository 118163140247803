import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { SettingsService, ToastService } from '@app/core';
import { Settings } from '@app/shared';


@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: false,
        height: '8rem',
        minHeight: '5rem',
        placeholder: 'Indsæt tekst',
        translate: 'no'
    };
    settings: Settings;

    constructor(
        private settingsService: SettingsService,
        private route: ActivatedRoute,
        private toast: ToastService
    ) { }

    ngOnInit() {
        this.route.data.subscribe(
            (data: { settings: Settings }) => {
                this.settings = data.settings;
            }
        );
    }

    save() {
        this.settingsService.update(this.settings).subscribe(
            settings => this.success(settings),
            error => this.fail(error)
        );
    }

    private success(settings: Settings) {
        this.settings = settings;
        this.toast.success('Indstillinger gemt');
    }

    private fail(error: any) {
        console.error('Error during settings save', error);
        this.toast.error('Fejl: Opdatering ikke gemt');
    }

}
