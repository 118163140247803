import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Schedule, ScheduleStats, ScheduleValidation } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ScheduleService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    list() {
        const headers = this.authService.authHeaders();
        return this.http.get<Schedule[]>(
                '/api/scheduling/schedule/',
                { headers }
            ).pipe(
                switchMap(response => response)
            );
    }

    get(id: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<Schedule>(
            '/api/scheduling/schedule/' + id + '/',
            { headers }
        );
    }

    create(schedule: Schedule) {
        const headers = this.authService.authHeaders();
        return this.http.post<Schedule>(
            '/api/scheduling/schedule/',
            schedule,
            { headers }
        );
    }

    // TODO: Ever used?
    update(schedule: Schedule) {
        const headers = this.authService.authHeaders();
        return this.http.put<Schedule>(
            '/api/scheduling/schedule/' + schedule.id + '/',
            schedule,
            { headers }
        );
    }

    validate(schedule: Schedule) {
        const headers = this.authService.authHeaders();
        return this.http.get<ScheduleValidation>(
            '/api/scheduling/schedule/' + schedule.id + '/validate/',
            { headers }
        );
    }

    publish(schedule: Schedule) {
        const headers = this.authService.authHeaders();
        return this.http.put<Schedule>(
            '/api/scheduling/schedule/' + schedule.id + '/publish/',
            null,
            { headers }
        );
    }

    unpublish(schedule: Schedule) {
        const headers = this.authService.authHeaders();
        return this.http.put<Schedule>(
            '/api/scheduling/schedule/' + schedule.id + '/unpublish/',
            null,
            { headers }
        );
    }

    stats(id: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<ScheduleStats>(
            '/api/scheduling/schedule/' + id + '/stats/',
            { headers }
        );
    }
}
