import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    ActivePeriodsResolverService,
    AuthGuard,
    DeselectionListResolverService,
    DeselectionPeriodsResolverService,
    HolidayListResolverService,
    LoggedInGuard,
    PeriodResolverService,
    SettingsResolverService,
    WishResolverService,
    WishListResolverService,
} from '@app/core';
import { DeselectionComponent } from './deselection/deselection.component';
import { DeselectionListComponent } from './deselection-list/deselection-list.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileResolverService } from './profile/profile-resolver.service';
import { SetPasswordComponent } from './set-password/set-password.component';
import { WishComponent } from './wish/wish.component';
import { WishListComponent } from './wish-list/wish-list.component';


const routes: Routes = [
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
        resolve: {
            profile: ProfileResolverService
        }
    },
    {
        path: 'deselection-list',
        component: DeselectionListComponent,
        canActivate: [AuthGuard],
        resolve: {
            activePeriods: ActivePeriodsResolverService,
            lockedPeriods: DeselectionPeriodsResolverService
        }
    },
    {
        path: 'deselection/:period',
        component: DeselectionComponent,
        canActivate: [AuthGuard],
        resolve: {
            deselections: DeselectionListResolverService,
            holidays: HolidayListResolverService,
            period: PeriodResolverService,
            settings: SettingsResolverService
        }
    },
    {
        path: 'set-password',
        component: SetPasswordComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'wish-list',
        component: WishListComponent,
        canActivate: [AuthGuard],
        resolve: {
            activePeriods: ActivePeriodsResolverService,
            wishes: WishListResolverService,
        }
    },
    {
        path: 'wish/:period',
        component: WishComponent,
        canActivate: [AuthGuard],
        resolve: {
            period: PeriodResolverService,
            settings: SettingsResolverService,
            wish: WishResolverService
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfileRoutingModule { }
