import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService, UserService } from '@app/core';
import {
    DateHelper,
    District,
    Selection,
    Settings,
    Shift,
    ShiftMonth,
    Shifttype,
    ShifttypeType,
    User
} from '@app/shared';


@Component({
    selector: 'app-user-shift-list',
    templateUrl: './user-shift-list.component.html',
    styleUrls: ['./user-shift-list.component.css'],
    providers: [DatePipe]
})
export class UserShiftListComponent implements OnDestroy, OnInit {

    hasShifts = false;
    monthSelections: Selection[] = [];
    selected: string;
    shifts: Shift[];
    title = '';
    private dateLimit = new Date();
    private districts: District[];
    private isAdminUser = false;
    private loading = false;
    private months: ShiftMonth[];
    private settings: Settings;
    private shifttypes: Shifttype[];
    private shifttypeTypes: ShifttypeType[];
    private subscriptions = new Subscription();
    private user: User;

    private cssMap = new Map<number, string[]>();
    private districtsMap = new Map<number, District>();
    private shifttypesMap = new Map<number, Shifttype>();
    private shifttypeTypesMap = new Map<number, ShifttypeType>();

    constructor(
        private authService: AuthService,
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.subscriptions.add(this.route.data.subscribe(
            data => {
                this.districts = data.districts;
                this.months = data.months;
                this.settings = data.settings;
                this.shifts = data.shifts;
                this.shifttypes = data.shifttypes;
                this.shifttypeTypes = data.shifttypeTypes;
                this.user = data.user;

                this.dateLimit.setDate(this.dateLimit.getDate() + this.settings.shift_available_warning);
                this.isAdminUser = this.authService.user.is_staff;

                if (this.user.last_name[this.user.last_name.length - 1] === 's') {
                    this.title = this.user.first_name + ' ' + this.user.last_name + '\'s vagter';
                } else {
                    this.title = this.user.first_name + ' ' + this.user.last_name + 's vagter';
                }

                this.setMonthSelections();
                this.initSelection();

                this.buildDistrictMap();
                this.buildShifttypeMap();
                this.buildShifttypeTypeMap();

                this.getShifts();
            }
        ));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getShiftCss(shift: Shift) {
        return this.cssMap.get(shift.id);
    }

    district(shifttypeId: number) {
        return this.getDistrict(shifttypeId).name;
    }

    shifttypeTitle(id: number) {
        return this.getShifttype(id).name;
    }

    shifttypeTypeTitle(id: number) {
        const type = this.getShifttype(id).type;
        return this.getShifttypeType(type).name;
    }

    shiftTime(shift: Shift) {
        const shifttype = this.getShifttype(shift.shifttype);
        const date = DateHelper.getDateFromString(shift.date);
        if (DateHelper.isWeekend(date) && this.hasWeekendTime(shifttype)) {
            return [this.getTimeHours(shifttype.weekend_start),
            this.getTimeHours(shifttype.weekend_end)].join('-');
        }
        return [this.getTimeHours(shifttype.start),
        this.getTimeHours(shifttype.end)].join('-');
    }

    goToShift(shift: Shift) {
        if (this.isAdmin) {
            const split = this.selected.split('-');
            this.router.navigate(
                ['shift', shift.id],
                { queryParams: {
                    'prev': 'user',
                    'user': this.user.id,
                    'month': split[0],
                    'year': split[1]
                }
            });
        }
    }

    selectMonth(selection: Selection) {
        this.selected = selection.id;
        this.updateNavigation();
        this.getShifts();
    }

    get isAdmin() {
        return this.isAdminUser;
    }

    get isLoading() {
        return this.loading;
    }

    private hasWeekendTime(shifttype: Shifttype) {
        return !!shifttype.weekend_start && !!shifttype.weekend_end;
    }

    private getTimeHours(time: string) {
        return time.substr(0, 2);
    }

    private getDistrict(shifttypeId: number) {
        return this.districtsMap.get(this.getShifttype(shifttypeId).district);
    }

    private getShifttype(id: number) {
        return this.shifttypesMap.get(id);
    }

    private getShifttypeType(id: number) {
        return this.shifttypeTypesMap.get(id);
    }

    private buildDistrictMap() {
        this.districts.forEach(d => this.districtsMap.set(d.id, d));
    }

    private buildShifttypeMap() {
        this.shifttypes.forEach(s => this.shifttypesMap.set(s.id, s));
    }

    private buildShifttypeTypeMap() {
        this.shifttypeTypes.forEach(s => this.shifttypeTypesMap.set(s.id, s));
    }

    private buildCssMap() {
        this.shifts.forEach(s => this.cssMap.set(s.id, this.cssState(s)));
    }

    private hasDateLimit(date: string) {
        return new Date(date).getTime() < this.dateLimit.getTime();
    }

    private cssState(shift: Shift) {
        if (shift.is_for_sale) {
            if (this.hasDateLimit(shift.date)) {
                return ['has-background-lightred'];
            } else {
                return ['has-background-success'];
            }
        }
        return [];
    }

    private setMonthSelections() {
        const months = this.months.map(m => {
            const month = m.month < 10 ? '0' + m.month : m.month;
            return {
                id: `month-${m.month}-${m.year}`,
                name: this.datePipe.transform(`${m.year}-${month}-01`, 'MMMM yyyy')
            };
        });
        const options = [
            {
                id: 'upcoming',
                name: 'Kommende'
            }, {
                id: 'all',
                name: 'Alle'
            }
        ];
        this.monthSelections = options.concat(months);
    }

    private getShifts() {
        this.loading = true;
        const shifts: Shift[] = [];
        this.getShiftsMethod().subscribe(
            shift => shifts.push(shift),
            error => console.log('Error updating user shift list', error),
            () => {
                this.shifts = shifts;
                this.hasShifts = shifts.length > 0;
                this.buildCssMap();
                this.loading = false;
            }
        );
    }

    private getShiftsMethod() {
        const split = this.selected.split('-');
        if (split[0] === 'all') {
            return this.userService.shifts(this.user.id);
        } else if (split[0] === 'month') {
            return this.userService.shiftsByMonth(this.user.id, +split[1], +split[2]);
        } else {
            return this.userService.upcomingShifts(this.user.id);
        }
    }

    private initSelection() {
        this.subscriptions.add(this.route.queryParamMap.subscribe(params => {
            if (!params.has('list')) {
                this.selected = 'upcoming';
                return;
            }
            const list = params.get('list');
            if (list === 'all') {
                this.selected = 'all';
            } else if (list === 'month' && params.has('month') && params.has('year')) {
                this.selected = 'month-' + params.get('month') + '-' + params.get('year');
            } else {
                this.selected = 'upcoming';
            }
        }));
    }

    private updateNavigation() {
        let queryParams = {};
        if (this.selected) {
            const split = this.selected.split('-');
            queryParams = { list: split[0], month: null, year: null };
            if (split[0] === 'month') {
                queryParams['month'] = split[1];
                queryParams['year'] = split[2];
            }
        }
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
            skipLocationChange: false
        });
    }

}
