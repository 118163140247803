import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
    AuthService,
    ProfileService,
    ToastService,
    UserService
} from '@app/core';
import { SetPassword, User } from '@app/shared';


@Component({
    selector: 'app-user-set-password',
    templateUrl: './user-set-password.component.html',
    styleUrls: ['./user-set-password.component.css']
})
export class UserSetPasswordComponent implements OnInit {

    setPassword = new SetPassword();
    errorNew = false;
    errorRetype = false;
    errorNewMessage: string;
    errorRetypeMessage: string;
    user: User;
    private _subscriptions = new Subscription();

    private passwordMessages = {
        'This password is too short. It must contain at least 8 characters.': 'Adgangskode skal være mindst 8 tegn',
        'This password is entirely numeric.': 'Adgangskode skal indeholde andet end tal',
        'This password is too common.': 'Denne adgangskode er for almindelig',
        'The password is too similar to the email address.': 'Adgangskoden ligner for meget email adressen',
        'The password is too similar to the first name.': 'Adgangskoden ligner for meget fornavn',
        'The password is too similar to the last name.': 'Adgangskoden ligner for meget efternavn',
        'Invalid password.': 'Forkert adgangskode',
        'The two password fields didn\'t match.': 'Adgangskoder skal være ens'
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService,
        private userService: UserService,
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(
            data => {
                this.user = data.user;
            }
        ));
    }

    save() {
        this.userService.setPassword(this.user.id, this.setPassword).subscribe(
            () => this.success(),
            error => this.fail(error)
        );
    }

    private success() {
        this.errorNew = false;
        this.errorRetype = false;
        this.setPassword = new SetPassword();
        this.toast.success('Adgangskode opdateret');
        this.router.navigate(['admin', 'user', this.user.id]);
    }

    private fail(error: any) {
        const msg = error.error;
        if (msg && msg.new_password) {
            this.errorNew = true;
            this.errorNewMessage = this.passwordMessages[msg.new_password[0]];
        }
        if (msg && msg.non_field_errors) {
            this.errorNew = true;
            this.errorRetype = true;
            this.errorNewMessage = this.passwordMessages[msg.non_field_errors[0]];
            this.errorRetypeMessage = this.passwordMessages[msg.non_field_errors[0]];
        }
        console.error('Error during set password', error);
        this.toast.error('Fejl: Adgangskode ikke opdateret');
    }

}
