import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { ShifttypeService } from '../services/shifttype.service';
import { Shifttype } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ShifttypesResolverService implements Resolve<Shifttype[]> {

    constructor(private shifttypeService: ShifttypeService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const shifttypes: Shifttype[] = [];
        return new Promise<Shifttype[]>((resolve, reject) => {
            this.shifttypeService.list('name', null, route.data.shifttypeActive)
                .subscribe(
                    shifttype => shifttypes.push(shifttype),
                    error => reject(error),
                    () => resolve(this.localeOrdering(shifttypes))
                );
        });
    }

    private localeOrdering(shifttypes: Shifttype[]) {
        return shifttypes.sort((a, b) => a.type - b.type || a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'}));
    }
}
