import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { PageRoutingModule } from './page-routing.module';
import { PageComponent } from './page/page.component';

@NgModule({
    imports: [
        SharedModule,
        PageRoutingModule
    ],
    declarations: [PageComponent]
})
export class PageModule { }
