import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { LogoutAllComponent } from './logout-all/logout-all.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';


@NgModule({
    imports: [
        SharedModule,
        AuthRoutingModule
    ],
    declarations: [LoginComponent, LogoutComponent, LogoutAllComponent, PasswordResetComponent]
})
export class AuthModule { }
