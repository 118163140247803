import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {

    @Input() blurOnChange = false;
    @Input() items: any[];
    @Input() name: string;
    @Input() key: string;
    @Input() selectedValue = 0;
    @Input() value: string;
    @Output() event = new EventEmitter<any>();
    @ViewChild('dropdown') private dropdown: ElementRef;

    constructor() { }

    ngOnInit() {
    }

    itemKey(item: any) {
        return item[this.key];
    }

    itemValue(item: any) {
        return item[this.value];
    }

    change(key: any) {
        this.event.emit(this.getItem(key));
        if (this.blurOnChange) {
            this.dropdown.nativeElement.blur();
        }
    }

    private getItem(key: any) {
        // Cast to int if key is that type
        if (typeof this.items[0][this.key] === 'number') {
            key = +key;
        }
        return this.items.find(item => item[this.key] === key);
    }

}
