import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { DeselectionComponent } from './deselection/deselection.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { WishComponent } from './wish/wish.component';
import { WishListComponent } from './wish-list/wish-list.component';
import { DeselectionListComponent } from './deselection-list/deselection-list.component';


@NgModule({
    imports: [
        SharedModule,
        ProfileRoutingModule
    ],
    declarations: [
        ProfileComponent,
        DeselectionComponent,
        SetPasswordComponent,
        WishComponent,
        WishListComponent,
        DeselectionListComponent
    ]
})
export class ProfileModule { }
