import { Injectable } from '@angular/core';

import { toast } from 'bulma-toast';


@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor() { }

    success(message: string) {
        this.toast(message, 'is-success');
    }

    error(message: string) {
        this.toast(message, 'is-danger');
    }

    private toast(message: string, type: string) {
        toast({ message: message, type: type, position: 'top-center' });
    }

}
