import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { PlanningService } from '../services/planning.service';
import { Profile } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class PlanningNoWishesListResolverService {

    constructor(private planningService: PlanningService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const period = route.paramMap.get('period');
        const users: Profile[] = [];
        return new Promise<Profile[]>((resolve, reject) => {
            this.planningService.noWishesList(+period).subscribe(
                user => users.push(user),
                error => reject(error),
                () => resolve(users)
            );
        });
    }
}
