import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    AuthGuard,
    DistrictListResolverService,
    DistrictGroupListResolverService,
    HolidayListResolverService,
    ShiftResolverService,
    ShiftHistoryResolverService,
    ShifttypesResolverService,
    ShifttypeTypesResolverService,
    SettingsResolverService,
    UserResolverService,
    UserShiftListResolverService,
    UserShiftMonthListResolverService,
} from '@app/core';
import { ForSaleComponent } from './for-sale/for-sale.component';
import { MyShiftsComponent } from './my-shifts/my-shifts.component';
import { ShiftComponent } from './shift/shift.component';
import { ShiftScheduleComponent } from './shift-schedule/shift-schedule.component';
import { TeamComponent } from './team/team.component';
import { WarningComponent } from './warning/warning.component';
import { UserShiftListComponent } from './user-shift-list/user-shift-list.component';
import { UserListComponent } from '../admin';


const routes: Routes = [
    {
        path: 'shift',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                canActivateChild: [AuthGuard],
                children: [
                    {
                        component: UserListComponent,
                        path: 'phone-list',
                        data: {
                            title: 'Telefonliste',
                            type: 'phone'
                        }
                    },
                    {
                        component: ForSaleComponent,
                        path: 'for-sale',
                        resolve: {
                            districts: DistrictListResolverService,
                            districtGroups: DistrictGroupListResolverService,
                            settings: SettingsResolverService,
                            shifttypes: ShifttypesResolverService,
                            shifttypeTypes: ShifttypeTypesResolverService
                        }
                    },
                    {
                        component: WarningComponent,
                        path: 'warning',
                        resolve: {
                            districts: DistrictListResolverService,
                            districtGroups: DistrictGroupListResolverService,
                            settings: SettingsResolverService,
                            shifttypes: ShifttypesResolverService,
                            shifttypeTypes: ShifttypeTypesResolverService
                        }
                    },
                    {
                        component: MyShiftsComponent,
                        path: 'my',
                        resolve: {
                            districts: DistrictListResolverService,
                            settings: SettingsResolverService,
                            shifttypes: ShifttypesResolverService,
                            shifttypeTypes: ShifttypeTypesResolverService
                        }
                    },
                    {
                        component: UserShiftListComponent,
                        path: 'user/:id',
                        resolve: {
                            districts: DistrictListResolverService,
                            months: UserShiftMonthListResolverService,
                            settings: SettingsResolverService,
                            shifttypes: ShifttypesResolverService,
                            shifttypeTypes: ShifttypeTypesResolverService,
                            user: UserResolverService
                        }
                    },
                    {
                        component: TeamComponent,
                        path: 'team',
                        resolve: {
                            settings: SettingsResolverService,
                            districts: DistrictListResolverService,
                            shifttypes: ShifttypesResolverService,
                            shifttypeTypes: ShifttypeTypesResolverService
                        }
                    },
                    {
                        component: ShiftScheduleComponent,
                        path: 'schedule',
                        resolve: {
                            districts: DistrictListResolverService,
                            holidays: HolidayListResolverService,
                            settings: SettingsResolverService,
                            shifttypes: ShifttypesResolverService,
                            shifttypeTypes: ShifttypeTypesResolverService
                        }
                    },
                    {
                        component: ShiftComponent,
                        path: ':id',
                        resolve: {
                            districts: DistrictListResolverService,
                            history: ShiftHistoryResolverService,
                            shift: ShiftResolverService,
                            shifttypes: ShifttypesResolverService
                        }
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class ShiftRoutingModule { }
