import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { DashboardService } from './services/dashboard.service';
import { DeselectionService } from './services/deselection.service';
import { DistrictService } from './services/district.service';
import { DistrictGroupService } from './services/district-group.service';
import { HolidayService } from './services/holiday.service';
import { PeriodService } from './services/period.service';
import { PlanningService } from './services/planning.service';
import { ProfileService } from './services/profile.service';
import { ScheduleService } from './services/schedule.service';
import { SettingsService } from './services/settings.service';
import { ShiftService } from './services/shift.service';
import { ShiftHistoryService } from './services/shift-history.service';
import { ShifttypeService } from './services/shifttype.service';
import { ShifttypeConstraintService } from './services/shifttype-constraint.service';
import { ShifttypeTypeService } from './services/shifttype-type.service';
import { ToastService } from './services/toast.service';
import { UserService } from './services/user.service';
import { WishService } from './services/wish.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [PageNotFoundComponent],
    providers: [
        AdminGuard,
        AuthGuard,
        AuthService,
        DashboardService,
        DeselectionService,
        DistrictService,
        DistrictGroupService,
        HolidayService,
        PeriodService,
        PlanningService,
        ProfileService,
        ScheduleService,
        SettingsService,
        ShiftService,
        ShiftHistoryService,
        ShifttypeService,
        ShifttypeConstraintService,
        ShifttypeTypeService,
        ToastService,
        UserService,
        WishService
    ]
})
export class CoreModule { }
