import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Settings } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    get() {
        const headers = this.authService.authHeaders();
        return this.http.get<Settings>('/api/scheduling/settings/', { headers });
    }

    update(settings: Settings) {
        const headers = this.authService.authHeaders();
        return this.http.put<Settings>(
            '/api/scheduling/settings/',
            settings,
            { headers }
        );
    }
}
