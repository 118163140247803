import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { DistrictService } from '../services/district.service';
import { District } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class DistrictListResolverService implements Resolve<District[]> {

    constructor(private districtService: DistrictService) { }

    resolve() {
        const districts: District[] = [];
        return new Promise<District[]>((resolve, reject) => {
            this.districtService.list().subscribe(
                district => districts.push(district),
                error => reject(error),
                () => resolve(districts)
            );
        });
    }
}
