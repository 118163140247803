export class ExtendedProfile {
    district: number;
    employee_no: number;
    special_no: string;
    address: string;
    zipcode: string;
    phone_work: string;
    phone_private: string;
    phone_mobile: string;
    user_type: number;
    doctor_type: number;
    drive_choice: number;
    first_login: boolean;
}
