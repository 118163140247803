import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Page } from '@app/shared';


@Component({
    selector: 'app-page-list',
    templateUrl: './page-list.component.html',
    styleUrls: ['./page-list.component.css']
})
export class PageListComponent implements OnInit, OnDestroy {

    pages: Page[] = [];
    private _routeSub: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this._routeSub = this.route.data.subscribe(
            data => {
                this.pages = data.pages;
            },
            error => console.error(error)
        );
    }

    ngOnDestroy() {
        if (this._routeSub) {
            this._routeSub.unsubscribe();
        }
    }

    editPage(page: Page) {
        this.router.navigate(['/admin/page', page.id]);
    }

}
