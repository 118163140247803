import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Period } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class PeriodService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    list(unscheduled?: boolean) {
        const headers = this.authService.authHeaders();
        let url = '/api/scheduling/period/';
        if (unscheduled) {
            url += '?unscheduled=true';
        }
        return this.http.get<Period[]>(url, { headers })
            .pipe(
                switchMap(response => response)
            );
    }

    all() {
        const headers = this.authService.authHeaders();
        return this.http.get<Period[]>('/api/scheduling/period/all/', {headers})
            .pipe(
                switchMap(response => response)
            );
    }

    active() {
        const headers = this.authService.authHeaders();
        return this.http.get<Period[]>(
            '/api/scheduling/period/active/',
            { headers })
            .pipe(
                switchMap(response => response)
            );
    }

    get(id: number) {
        const headers = this.authService.authHeaders();
        return this.http.get<Period>(
            '/api/scheduling/period/' + id + '/',
            { headers }
        );
    }

    create(period: Period) {
        const headers = this.authService.authHeaders();
        return this.http.post<Period>(
            '/api/scheduling/period/',
            period,
            { headers }
        );
    }

    update(period: Period) {
        const headers = this.authService.authHeaders();
        return this.http.put<Period>(
            '/api/scheduling/period/' + period.id + '/',
            period,
            { headers }
        );
    }

    delete(period: Period) {
        const headers = this.authService.authHeaders();
        return this.http.delete(
            '/api/scheduling/period/' + period.id + '/',
            { headers }
        );
    }

}
