import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HolidayService } from '../services/holiday.service';
import { Holiday } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class HolidayResolverService {

    constructor(
        private holidayService: HolidayService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const id = route.paramMap.get('id');

        if (id === null) {
            return of(new Holiday());
        }

        return this.holidayService.get(+id).pipe(
            map(holiday => {
                if (holiday) {
                    return holiday;
                }
                this.router.navigate(['/admin/holiday-list']);
                return null;
            })
        );
    }
}
