import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ShifttypeService } from '@app/core';
import { Shifttype } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ShifttypeResolverService {

    constructor(
        private shifttypeService: ShifttypeService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Shifttype> {
        const id = route.paramMap.get('id');

        if (id === null) {
            return of(new Shifttype());
        }

        return this.shifttypeService.get(+id).pipe(
            map(shifttype => {
                if (shifttype) {
                    return shifttype;
                }
                this.router.navigate(['/admin/shifttype-list']);
                return null;
            })
        );
    }
}
