import { DatePick } from '../interfaces';
import { Period } from '../models';

export class DateHelper {

    static getDaysInMonth(datePick: DatePick) {
        return new Date(datePick.year, datePick.month + 1, 0).getDate();
    }

    static getDatePick(initialDate?: string): DatePick {
        let date: Date;
        if (initialDate) {
            date = this.getDateFromString(initialDate);
        } else {
            date = new Date();
        }
        return {
            day: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear()
        };
    }

    static getDatePickKey(datePick: DatePick) {
        return datePick.year + '-' + datePick.month;
    }

    static getDateFromString(dateString: string) {
        const split = dateString.split('-');
        return new Date(+split[0], +split[1] - 1, +split[2]);
    }

    static getTimeFromString(dateString: string) {
        return this.getDateFromString(dateString).getTime();
    }

    static getDateString(date: Date) {
        return [
            date.getFullYear(),
            this.pad(date.getMonth() + 1, 2),
            this.pad(date.getDate(), 2)
        ].join('-');
    }

    static getWeek(dateString: string, dowOffset = 1) {
        const date = this.getDateFromString(dateString);
        const newYear = new Date(date.getFullYear(), 0, 1);
        // the day of week the year begins on
        let day = newYear.getDay() - dowOffset;
        day = (day >= 0 ? day : day + 7);
        const daynum = Math.floor((date.getTime() - newYear.getTime() -
        (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
        let weeknum;
        // if the year starts before the middle of a week
        if (day < 4) {
            weeknum = Math.floor((daynum + day - 1) / 7) + 1;
            if (weeknum > 52) {
                const nYear = new Date(date.getFullYear() + 1, 0, 1);
                let nday = nYear.getDay() - dowOffset;
                nday = nday >= 0 ? nday : nday + 7;
                // if the next year starts before the middle of the week, it is week #1 of that year
                weeknum = nday < 4 ? 1 : 53;
            }
        } else {
            weeknum = Math.floor((daynum + day - 1) / 7);
        }
        return weeknum;
    }

    static getMonths(period: Period) {
        const months: DatePick[] = [];
        const start = this.getDatePick(period.start);
        const end = this.getDatePick(period.end);
        let month = start.month;
        let year = start.year;
        while (month < end.month || year < end.year) {
            months.push({ day: 1, month: month, year: year });
            month++;
            if (month > 11) {
                month = 0;
                year++;
            }
        }
        months.push({ day: 1, month: end.month, year: end.year });
        return months;
    }

    static isWeekend(date: Date) {
        return [0, 6].includes(date.getDay());
    }

    static includeDay(date: Date, dayType: 'fullweek' | 'weekend' | 'allday') {
        // 0 is sunday
        const valid = { fullweek: [0, 1, 2, 3, 4, 5, 6], weekend: [0, 6] };
        return dayType === 'allday' || valid[dayType].indexOf(date.getDay()) > -1;
    }

    private static pad(num: number, size: number) {
        let s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }

}
