import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { PlanningService } from '@app/core';
import { Period, PlanningWish } from '@app/shared';


@Component({
    selector: 'app-wish-check-list',
    templateUrl: './wish-check-list.component.html',
    styleUrls: ['./wish-check-list.component.css']
})
export class WishCheckListComponent implements OnDestroy, OnInit {

    period: Period;
    query = new FormControl();
    private _loading = false;
    private _subscriptions = new Subscription();
    private _wishes: PlanningWish[] = [];

    constructor(
        private planningService: PlanningService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(
            data => {
                this.period = data.period;
                this._wishes = data.wishes;
            }
        ));
        this.subscribe();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    get isLoading() {
        return this._loading;
    }

    get wishes() {
        return this._wishes;
    }

    get noShiftsListLink() {
        return '/planning/' + this.period.id + '/no-shifts-list';
    }

    get noWishesListLink() {
        return '/planning/' + this.period.id + '/no-wishes-list';
    }

    get periodOverviewLink() {
        return '/planning/' + this.period.id;
    }

    editWish(wish: PlanningWish) {
        this.router.navigate(['planning', this.period.id, 'wish-check', wish.user]);
    }

    private addWish(wish: PlanningWish) {
        this.wishes.push(wish);
    }

    private getWishes() {
        this._loading = true;
        this.clear();
        this.planningService.wishList(this.period.id).subscribe(
            wish => this.addWish(wish),
            error => console.error('Error getting wish check list', error),
            () => {
                this._loading = false;
            }
        );
    }

    private search(query: string) {
        this._loading = true;
        this.clear();
        this.planningService.wishList(this.period.id, query).subscribe(
            wish => this.addWish(wish),
            error => console.log('Error searching wish check list', error),
            () => {
                this._loading = false;
            }
        );
    }

    private subscribe() {
        this._subscriptions.add(this.query.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        ).subscribe(query => {
            if (query) {
                this.search(query);
            } else {
                this.getWishes();
            }
        }));
    }

    private clear() {
        this._wishes.length = 0;
    }

}
