import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Period, Wish } from '@app/shared';


@Component({
    selector: 'app-wish-list',
    templateUrl: './wish-list.component.html',
    styleUrls: ['./wish-list.component.css']
})
export class WishListComponent implements OnDestroy, OnInit {

    activePeriods: Period[];
    lockedPeriods: Period[];
    private submitted = new Map<number, boolean>();
    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.subscriptions.add(this.route.data.subscribe(
            data => {
                this.activePeriods = data.activePeriods || [];
                this.prepare(data.wishes || []);
            }
        ));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    goToWish(period: Period) {
        this.router.navigate(['wish', period.id]);
    }

    isSubmitted(period: Period) {
        return this.submitted.get(period.id);
    }

    private prepare(wishes: Wish[]) {
        this.lockedPeriods = wishes.filter(
            w => w.period_object.is_locked
        ).map(w => w.period_object);

        this.activePeriods.forEach(p => {
            this.submitted.set(p.id, wishes.some(w => w.period === p.id));
        });
    }

}
