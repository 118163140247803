import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { UserService } from '@app/core';
import { Emit, Period, User } from '@app/shared';


@Component({
    selector: 'app-user-search',
    templateUrl: './user-search.component.html',
    styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnDestroy, OnInit {

    @Output() event = new EventEmitter<Emit>();
    @Input() excludedUserIds: number[];
    @Input() period: Period;
    query = new FormControl();
    users: User[] = [];
    private _loading = false;
    private _minLength = 3;
    private _searchSub: Subscription;

    constructor(private userService: UserService) { }

    ngOnInit() {
        this.subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    get isLoading() {
        return this._loading;
    }

    selectUser(user: User) {
        this.event.emit({ type: 'add-user', data: user });
        this.users.length = 0;
        this.query.setValue('');
    }

    private isValid(query: string) {
        return query.length >= this._minLength;
    }

    private search(query: string) {
        if (!this.isValid(query)) {
            return;
        }
        this._loading = true;
        this.getUsers(query).then(users => {
            this.users = this.excludeUsers(users);
            this._loading = false;
        });
    }

    private getUsers(query: string) {
        const users: User[] = [];
        return new Promise<User[]>((resolve, reject) => {
            this.userService.search(query, this.period.id).subscribe(
                user => {
                    users.push(user);
                },
                error => reject(error),
                () => resolve(users)
            );
        });
    }

    private excludeUsers(users: User[]) {
        return users.filter(u => this.excludedUserIds.indexOf(u.id) === -1);
    }

    private subscribe() {
        this._searchSub = this.query.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        ).subscribe(query => this.search(query));
    }

    private unsubscribe() {
        if (this._searchSub) {
            this._searchSub.unsubscribe();
        }
    }

}
