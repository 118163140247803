import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Holiday } from '@app/shared';


@Component({
    selector: 'app-holiday-list',
    templateUrl: './holiday-list.component.html',
    styleUrls: ['./holiday-list.component.css']
})
export class HolidayListComponent implements OnInit, OnDestroy {

    private _holidays: Holiday[] = [];
    private _subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) { }

    get holidays() {
        return this._holidays;
    }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(data => {
                this._holidays = data.holidays;
            },
            error => console.error(error)
        ));
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    editHoliday(holiday: Holiday) {
        this.router.navigate(['/admin/holiday', holiday.id]);
    }

}
