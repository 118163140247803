import { DateHelper } from './date-helper';
import { ShiftConflict } from '../interfaces';
import { Shift, ShifttypeConstraint } from '../models';


export class ConflictHandler {

    private _hasConflict = false;
    private _conflicts = new Map<number, Map<number, string[]>>();
    private _shiftConflicts = new Map<Number, boolean>();
    private _possibleConflicts = new Map<string, number[]>();

    private _sameday = ['time_overlap_sameday', 'day_after_night_sameday', 'visit_after_visit_sameday'];
    private _nextday = ['time_overlap_nextday', 'day_after_night_nextday', 'visit_after_visit_nextday'];

    constructor(
        private constraints: Map<number, Map<number, string[]>>,
        private shifts: Shift[]
    ) {
        if (shifts) {
            shifts.forEach(s => this.addShift(s));
        }
    }

    addShift(shift: Shift) {
        if (!this._shiftConflicts.has(shift.id)) {
            this._shiftConflicts.set(shift.id, false);
        }
        this.checkForConflicts(shift);
    }

    removeShift(shift: Shift) {
        this._shiftConflicts.set(shift.id, false);
        this.removeConflictFromMap(shift);
    }

    hasConflict() {
        return this._hasConflict;
    }

    getConlicts(shift: Shift) {
        return this._conflicts.get(shift.id);
    }

    hasShiftConflict(shift: Shift) {
        return this._shiftConflicts.get(shift.id);
    }

    hasPossibleConflict(shift: Shift) {
        const conflicts = this._possibleConflicts.get(shift.date);
        return conflicts && conflicts.includes(shift.shifttype);
    }

    private checkForConflicts(shift: Shift) {
        const shift_date = DateHelper.getTimeFromString(shift.date);
        this.shifts.forEach(s => {
            if (s.id === shift.id) {
                return;
            }
            const s_date = DateHelper.getTimeFromString(s.date);
            if (shift_date === s_date) {
                this.check(shift, s, this._sameday);
                this.check(s, shift, this._sameday);
            } else {
                const diff = (s_date - shift_date) / 1000;
                if (diff === 86400) {
                    this.check(shift, s, this._nextday);
                } else if (diff === -86400) {
                    this.check(s, shift, this._nextday);
                }
            }
        });
    }

    private check(s1: Shift, s2: Shift, possible: string[]) {
        const c1 = this.constraints.get(s1.shifttype);
        if (c1) {
            const c1_constraints = c1.get(s2.shifttype) || [];
            const conflicts = c1_constraints.filter(c => possible.includes(c));
            if (conflicts.length) {
                conflicts.forEach(conflict => this.addConflict(s1, s2, conflict));
            }
        }
    }

    private addConflict(s1: Shift, s2: Shift, conflict: string) {
        this._hasConflict = true;
        this._shiftConflicts.set(s1.id, true);
        this._shiftConflicts.set(s2.id, true);
        this.addConflictToMap(s1, s2, conflict);
        this.addConflictToMap(s2, s1, conflict);
    }

    private addConflictToMap(s1: Shift, s2: Shift, conflict: string) {
        let map = this._conflicts.get(s1.id);
        if (!map) {
            map = new Map<number, string[]>();
            this._conflicts.set(s1.id, map);
        }
        const conflicts = map.get(s2.id) || [];
        if (!conflicts.includes(conflict)) {
            conflicts.push(conflict);
            map.set(s2.id, conflicts);
        }
    }

    private removeConflictFromMap(shift: Shift) {
        this._conflicts.delete(shift.id);
        const keys = this._conflicts.keys();
        let result = keys.next();
        while (!result.done) {
            const conflicts = this._conflicts.get(result.value);
            conflicts.delete(shift.id);
            if (conflicts.size === 0) {
                this._conflicts.delete(result.value);
                this._shiftConflicts.set(result.value, false);
            }
            result = keys.next();
        }
        if (this._conflicts.size === 0) {
            this._hasConflict = false;
        }
    }

    updatePossibleConflicts() {
        const dates = new Map<string, number[]>();
        this.shifts.forEach(s => {
            // Get all shifttypes that conflict for this shift's shifttype
            const shifttypeConstraints = this.constraints.get(s.shifttype);
            shifttypeConstraints.forEach((conflicts, shifttype) => {
                conflicts.forEach(c => {
                    const conflictDate = this.reverseDate(s.date, c, 1);
                    const shifttypes = dates.get(conflictDate) || [];
                    if (!shifttypes.includes(shifttype)) {
                        shifttypes.push(shifttype);
                        dates.set(conflictDate, shifttypes);
                    }
                });
            });

            // Get all shifttypes where this shift's shifttype is in conflict
            this.constraints.forEach((constraints, shifttype) => {
                if (shifttype !== s.shifttype) {
                    const conflicts = constraints.get(s.shifttype);
                    if (conflicts) {
                        conflicts.forEach(c => {
                            const conflictDate = this.reverseDate(s.date, c, -1);
                            const shifttypes = dates.get(conflictDate) || [];
                            if (!shifttypes.includes(shifttype)) {
                                shifttypes.push(shifttype);
                                dates.set(conflictDate, shifttypes);
                            }
                        });
                    }
                }
            });
        });

        this._possibleConflicts = dates;
    }

    reverseDate(date: string, constraint, nextdayAdjustment) {
        if (this._nextday.includes(constraint)) {
            let timestamp = DateHelper.getTimeFromString(date);
            timestamp += nextdayAdjustment * 86400;
            return DateHelper.getDateString(new Date(timestamp));
        }
        return date;
    }

}
