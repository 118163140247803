import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserService, ToastService } from '@app/core';
import { Shift, User } from '@app/shared';


@Component({
    selector: 'app-user-delete',
    templateUrl: './user-delete.component.html',
    styleUrls: ['./user-delete.component.css']
})
export class UserDeleteComponent implements OnDestroy, OnInit {

    shifts: Shift[];
    user: User;
    private _subscriptions = new Subscription();

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(
            data => {
                this.user = data.user;
                this.shifts = data.shifts;
            }
        ));
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    cancel() {
        this.router.navigate(['/admin/user/', this.user.id]);
    }

    delete() {
        this.userService.delete(this.user).subscribe(
            () => {
                this.toast.success('Bruger slettet');
                this.router.navigate(['/admin/user-list']);
            },
            error => {
                console.error('Error during user delete', error);
                this.toast.error('Fejl: Bruger ikke slettet');
            }
        );
    }

}
