import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { UserService, ToastService } from '@app/core';
import { District, PasswordReset, User } from '@app/shared';


@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})
export class UserComponent implements OnDestroy, OnInit {

    user: User;
    districts: District[];
    employeeNo = new FormControl();
    employeeNoLoading = false;
    employeeNoAvailable = false;
    employeeNoAvailableError = false;
    employeeNoDigitsError = false;
    errorEmail = false;
    errorEmailMessage: string;
    private _mode: 'create' | 'update';
    private _loading = false;
    private _originalEmployeeNo: number;
    private _subscriptions = new Subscription();
    private _validEmployeeNo = false;
    private _showScreen: 'userForm' | 'employeeNo' = 'userForm';

    private emailMessages = {
        'Enter a valid email address.': 'Email er ikke valid',
        'Email has to be unique': 'Email er allerede i brug'
    };

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastService
    ) { }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(
            (data: { user: User, districts: District[] }) => {
                this._mode = !!data.user.id ? 'update' : 'create';
                this.user = data.user;
                if (this.user.profile && !!this.user.profile.employee_no) {
                    this._originalEmployeeNo = this.user.profile.employee_no;
                    this.employeeNo.setValue(this.user.profile.employee_no);
                }
                this.districts = data.districts;
                this.checkEmployeeNo();
                this.setupEmployeeNo();
            }
        ));
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    get mode() {
        return this._mode;
    }

    get isCreateMode() {
        return this._mode === 'create';
    }

    get isEditMode() {
        return this._mode === 'update';
    }

    get isDoctor() {
        return this.user.profile.user_type === 1;
    }

    get isLoading() {
        return this._loading;
    }

    get isEmployeeNoScreen() {
        return this._showScreen === 'employeeNo';
    }

    get isUserFormScreen() {
        return this._showScreen === 'userForm';
    }

    selectNumber(number: number) {
        this.employeeNo.setValue(number);
        this.showUserForm();
    }

    save() {
        if (this.mode === 'update') {
            if (!this.isValidSave()) {
                return;
            }

            if (this.employeeNo.value) {
                this.user.profile.employee_no = +this.employeeNo.value;
            } else {
                this.user.profile.employee_no = null;
            }
        }

        if (this._mode === 'create') {
            this.create();
        } else if (this._mode === 'update') {
            if (this.user.profile.user_type !== 1) {
                this.user.profile.doctor_type = null;
            }
            this.update();
        }
    }

    showEmployeeNo() {
        this._showScreen = 'employeeNo';
    }

    showUserForm() {
        this._showScreen = 'userForm';
    }

    activate() {
        this.user.is_active = true;
        this.update();
    }

    deactivate() {
        this.user.profile.employee_no = null;
        this.user.is_active = false;
        this.update();
    }

    delete() {
        this.router.navigate(['/admin/user-delete/', this.user.id]);
    }

    passwordReset() {
        const passwordReset = new PasswordReset(this.user);
        this.userService.passwordReset(passwordReset).subscribe(
            () => this.toast.success('Nulstilling sendt til bruger'),
            () => this.toast.error('Fejl: Nulstilling ikke sendt')
        );
    }

    setPassword() {
        this.router.navigate(['admin', 'user-set-password', this.user.id]);
    }

    goToShifts() {
        this.router.navigate(['shift/user', this.user.id]);
    }

    private checkEmployeeNo() {
        if (this.user.is_active) {
            this.employeeNo.enable();
        } else {
            this.employeeNo.disable();
        }
    }

    private create() {
        this.user.is_active = true;
        this.userService.create(this.user).subscribe(
            user => this.success(user),
            error => this.fail(error),
            () => this.router.navigate(['/admin/user/', this.user.id])
        );
    }

    private update() {
        this.userService.update(this.user).subscribe(
            user => this.success(user),
            error => this.fail(error),
            () => this.router.navigate(['/admin/user-list'])
        );
    }

    private success(user: User) {
        this.user = user;
        this.checkEmployeeNo();
        this.employeeNoAvailable = false;
        this.employeeNoAvailableError = false;
        this.errorEmail = false;
        if (user.profile) {
            this.employeeNo.setValue(this.user.profile.employee_no || '');
            this._originalEmployeeNo = user.profile.employee_no;
        }
        this.toast.success('Bruger gemt');
    }

    private fail(error: any) {
        this.handleErrors(error.error);
        console.error('Error during user save', error);
        this.toast.error('Fejl: Opdatering ikke gemt');
    }

    private handleErrors(msg: any) {
        if (!msg) {
            return;
        }
        if (msg.employee_no) {
            if (msg.employee_no[0] === 'profile with this employee no already exists.') {
                this.employeeNoAvailableError = true;
            }
        }
        if (msg.email) {
            this.errorEmail = true;
            this.errorEmailMessage = this.emailMessages[msg.email[0]];
        }
        if (msg.non_field_errors) {
            if (msg.non_field_errors.includes('Email has to be unique')) {
                this.errorEmail = true;
                this.errorEmailMessage = this.emailMessages['Email has to be unique'];
            }
        }
    }

    private setupEmployeeNo() {
        this._subscriptions.add(this.employeeNo.valueChanges
            .pipe(
                map(_ => {
                    this.employeeNoAvailable = false;
                    this.employeeNoAvailableError = false;
                    this.employeeNoDigitsError = false;
                    this._validEmployeeNo = false;
                    return _;
                }),
                debounceTime(400),
                distinctUntilChanged(),
                map(_ => {
                    this.employeeNoDigitsError = !this.isValidLookup(_) && _ !== '';
                    return _;
                }),
                filter(_ => this.isValidLookup(_)  && +_ !== this._originalEmployeeNo)
            ).subscribe((employeeNo: string) => this.lookup(+employeeNo)));
    }

    private isValidLookup(employeeNo: string) {
        return !!employeeNo && ('' + employeeNo).length === 4 && !isNaN(+employeeNo);
    }

    private isValidSave() {
        return this.user.profile.user_type !== 1 ||
            this.employeeNo.value === '' || this.employeeNo.value === null ||
            (this.isValidLookup(this.employeeNo.value) && this._validEmployeeNo) ||
            +this.employeeNo.value === this._originalEmployeeNo;
    }

    private lookup(employeeNo: number) {
        this.employeeNoLoading = true;
        this.userService.lookupEmployeeNo(employeeNo).subscribe(
            () => {
                this.employeeNoAvailable = true;
                this.employeeNoAvailableError = false;
                this.employeeNoDigitsError = false;
                this.employeeNoLoading = false;
                this._validEmployeeNo = true;
            },
            () => {
                this.employeeNoAvailable = false;
                this.employeeNoAvailableError = true;
                this.employeeNoDigitsError = false;
                this.employeeNoLoading = false;
            },
        );
    }

}
