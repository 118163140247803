import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { ShifttypeConstraintService } from '../services/shifttype-constraint.service';
import { ShifttypeConstraint } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class ShifttypeConstraintListResolverService implements Resolve<ShifttypeConstraint[]> {

    constructor(private shifttypeConstraintService: ShifttypeConstraintService) { }

    resolve() {
        const constraints: ShifttypeConstraint[] = [];
        return new Promise<ShifttypeConstraint[]>((resolve, reject) => {
            this.shifttypeConstraintService.list().subscribe(
                constraint => constraints.push(constraint),
                error => reject(error),
                () => resolve(constraints)
            );
        });
    }
}
