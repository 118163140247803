import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {
    ShifttypeService,
} from '@app/core';
import { District, Shifttype, ShifttypeType } from '@app/shared';


@Component({
    selector: 'app-shifttype-list',
    templateUrl: './shifttype-list.component.html',
    styleUrls: ['./shifttype-list.component.css']
})
export class ShifttypeListComponent implements OnDestroy, OnInit {

    ordering = 'name';
    orderAsc = true;
    query = new FormControl();
    private _showActive = true;
    private _districts: District[] = [];
    private _loading = false;
    private _shifttypes: Shifttype[] = [];
    private _subscriptions = new Subscription();
    private _types: ShifttypeType[] = [];

    constructor(
        private shifttypeService: ShifttypeService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    get shifttypes() {
        return this._shifttypes;
    }

    get isLoading() {
        return this._loading;
    }

    get showActive() {
        return this._showActive;
    }

    ngOnInit() {
        this._subscriptions.add(this.route.data.subscribe(
            data => {
                this._districts = data.districts;
                this._types = data.types;
            },
            error => console.error(error)
        ));
        this.subscribe();
        this.getShifttypes();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    editShifttype(shifttype: Shifttype) {
        this.router.navigate(['/admin/shifttype', shifttype.id]);
    }

    updateOrdering(ordering: string) {
        if (ordering === this.ordering) {
            this.orderAsc = !this.orderAsc;
        } else {
            this.orderAsc = true;
        }
        this.ordering = ordering;
        this.updateList();
    }

    toggleActive() {
        this._showActive = !this._showActive;
        this.updateList();
    }

    private getShifttypes() {
        this._loading = true;
        this.clear();
        this.shifttypeService.list(this.getOrdering(), null, this._showActive).subscribe(
            shifttype => this.addShifttype(shifttype),
            error => console.error('Error getting shifttype list'),
            () => {
                this.applyLocaleOrdering();
                this._loading = false;
            }
        );
    }

    private addDistrict(district: District) {
        this._districts.push(district);
    }

    private addShifttype(shifttype: Shifttype) {
        shifttype.district_object = this.findDistrict(shifttype.district);
        shifttype.type_object = this.findType(shifttype.type);
        shifttype.time = this.getTimeTitle(shifttype);
        shifttype.timeperiod_title = this.getTimeperiodTitle(shifttype.timeperiod);
        shifttype.daytype_title = this.getDaytypeTitle(shifttype.daytype);
        this._shifttypes.push(shifttype);
    }

    private addType(type: ShifttypeType) {
        this._types.push(type);
    }

    private findDistrict(id: number) {
        return this._districts.find(d => d.id === id);
    }

    private findType(id: number) {
        return this._types.find(t => t.id === id);
    }

    private getTimeTitle(shifttype: Shifttype) {
        let title = this.getTime(shifttype.start) + '-' + this.getTime(shifttype.end);
        if (shifttype.weekend_start && shifttype.weekend_end) {
            title += ' (' + this.getTime(shifttype.weekend_start) + '-' + this.getTime(shifttype.weekend_end) + ')';
        }
        return title;
    }

    private getTime(time: string) {
        return time.substr(0, 2);
    }

    private getDaytypeTitle(daytype: string) {
        if (daytype === 'fullweek') {
            return 'Alle dage';
        } else if (daytype === 'weekend') {
            return 'Lør/Søn/Helligdag';
        } else if (daytype === 'allday') {
            return 'Hele dagen';
        }
    }

    private getTimeperiodTitle(timeperiod: string) {
        if (timeperiod === 'day') {
            return 'Dag';
        } else if (timeperiod === 'evening') {
            return 'Aften';
        } else if (timeperiod === 'night') {
            return 'Nat';
        }
    }

    private subscribe() {
        this._subscriptions.add(this.query.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        ).subscribe(query => {
            if (query) {
                this.search(query);
            } else {
                this.getShifttypes();
            }
        }));
    }

    private search(query: string) {
        this._loading = true;
        this.clear();
        this.shifttypeService.list(this.getOrdering(), query, this._showActive).subscribe(
            shifttype => this.addShifttype(shifttype),
            error => console.error('Error searching for shifttype'),
            () => {
                this.applyLocaleOrdering();
                this._loading = false;
            }
        );
    }

    private clear() {
        this._shifttypes.length = 0;
    }

    private getOrdering() {
        return this.orderAsc ? this.ordering : '-' + this.ordering;
    }

    private updateList() {
        if (this.query.value) {
            this.search(this.query.value);
        } else {
            this.getShifttypes();
        }
    }

    private applyLocaleOrdering() {
        if (this.ordering !== 'name') {
            return;
        }
        this._shifttypes.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'}));
        if (!this.orderAsc) {
            this._shifttypes.reverse();
        }
    }

}
