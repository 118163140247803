export class ShiftCount {
    night_visitation = 0;
    night_drive = 0;
    evening_visitation = 0;
    evening_drive = 0;

    get evening_total() {
        return this.evening_visitation + this.evening_drive;
    }

    get night_total() {
        return this.night_visitation + this.night_drive;
    }

}
