import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { PeriodService } from '../services/period.service';
import { Period } from '@app/shared';


@Injectable({
    providedIn: 'root'
})
export class PeriodListAllResolverService implements Resolve<Period[]> {

    constructor(private periodService: PeriodService) { }

    resolve() {
        const periods: Period[] = [];
        return new Promise<Period[]>((resolve, reject) => {
            this.periodService.all().subscribe(
                period => periods.push(period),
                error => reject(error),
                () => resolve(periods)
            );
        });
    }

}
