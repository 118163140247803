import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PeriodService } from '@app/core';
import { Period } from '@app/shared';


@Component({
    selector: 'app-period-list',
    templateUrl: './period-list.component.html',
    styleUrls: ['./period-list.component.css']
})
export class PeriodListComponent implements OnInit {

    private _loading = false;
    private _periods: Period[] = [];

    constructor(
        private periodService: PeriodService,
        private router: Router
    ) { }

    get periods() {
        return this._periods;
    }

    get isLoading() {
        return this._loading;
    }

    ngOnInit() {
        this.getPeriods();
    }

    editPeriod(period: Period) {
        this.router.navigate(['/scheduler/period', period.id]);
    }

    private getPeriods() {
        this._loading = true;
        this.periodService.list().subscribe(
            period => this.addPeriod(period),
            error => console.error('Error getting period list'),
            () => {
                this._loading = false;
            }
        );
    }

    private addPeriod(period: Period) {
        this._periods.push(period);
    }

}
