import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    AdminGuard,
    HolidayListResolverService,
    PeriodResolverService,
    ShifttypesResolverService,
    ShifttypeConstraintListResolverService,
    UnscheduledPeriodsResolverService,
} from '@app/core';
import { PeriodListComponent } from './period-list/period-list.component';
import { PeriodComponent } from './period/period.component';
import { PublishComponent } from './publish/publish.component';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { ScheduleCreateComponent } from './schedule-create/schedule-create.component';
import { ScheduleResolverService } from './scheduler/schedule-resolver.service';
import { ScheduleStatsComponent } from './schedule-stats/schedule-stats.component';
import { ScheduleStatsResolverService } from './schedule-stats/schedule-stats-resolver.service';


const routes: Routes = [
    {
        path: 'scheduler',
        canActivate: [AdminGuard],
        children: [
            {
                path: '',
                canActivateChild: [AdminGuard],
                children: [
                    {
                        path: 'period-list',
                        component: PeriodListComponent
                    },
                    {
                        path: 'period/:id',
                        component: PeriodComponent,
                        resolve: {
                            period: PeriodResolverService
                        }
                    },
                    {
                        path: 'period',
                        component: PeriodComponent,
                        resolve: {
                            period: PeriodResolverService
                        }
                    },
                    {
                        path: 'publish/:id',
                        component: PublishComponent,
                        resolve: {
                            schedule: ScheduleResolverService
                        }
                    },
                    {
                        path: 'schedule-list',
                        component: ScheduleListComponent
                    },
                    {
                        path: 'schedule-create',
                        component: ScheduleCreateComponent,
                        resolve: {
                            periods: UnscheduledPeriodsResolverService
                        }
                    },
                    {
                        path: 'schedule/:id',
                        component: SchedulerComponent,
                        resolve: {
                            constraints: ShifttypeConstraintListResolverService,
                            holidays: HolidayListResolverService,
                            schedule: ScheduleResolverService,
                            shifttypes: ShifttypesResolverService
                        },
                        data: {
                            shifttypeActive: true
                        }
                    },
                    {
                        path: 'schedule-stats/:id',
                        component: ScheduleStatsComponent,
                        resolve: {
                            schedule: ScheduleResolverService,
                            stats: ScheduleStatsResolverService,
                        }
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ScheduleResolverService]
})
export class SchedulerRoutingModule { }
